import { savePhoto } from '@kn/common/lib/api/savePhoto'

declare global {
  interface Window {
    callBackFunctionForScanner(): any
  }
  interface External {
    /**
     * スキャナー画像読み取り機能を呼び出す
     * スキャンされた画像はアップロードされた後SEND_SCANNER_IMAGE_CALLBACK_FUNCTIONに
     * 指定しているcallBackFunctionForScannerが実行される
     * @param size サイズ
     *     1: DSC
     *     2: はがき
     *     3: DSCW
     *     4: L
     *     5: 2L
     *     6: 6P（縦のみ
     * @param productTypeId 商材種別ID
     * @param editStartedAt 編集開始日
     */
    ReadScanner(size: number, productTypeId: string, editStartedAt: string): any
    /**
     * スキャナー読み込み(GetImageIdsString)の結果を取得する
     * @return スキャン結果
     */
    GetImageIdsString(): any
  }
}

/**
 * スキャナー画像読み取り後のcallbackをセットする
 */
export const setCallback = (callback: () => any) => {
  window.callBackFunctionForScanner = callback
}

/**
 * スキャナー画像読み取り機能を呼び出す
 */
export const readScanner = (
  size: number,
  productTypeId: string,
  editStartedAt: string
) => {
  window.external.ReadScanner(size, productTypeId, editStartedAt)
}

/**
 * スキャナーで読み取んだ画像を取得する
 */
export const getPhotos = () => {
  // TODO: 返されるIDの頭文字が小文字に修正されたら対応
  const rawPhotos = JSON.parse(window.external.GetImageIdsString()).photos
  return rawPhotos.map((photo: any) => ({
    ...photo,
    id: photo.Id
  }))
}

/**
 * モック用file読み込み
 */
const readFile = () => {
  return new Promise<File>(resolve => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'image/*'
    input.onchange = e => {
      if (input.files && input.files.length) {
        resolve(input.files[0])
      }
    }
    input.click()
  })
}

/**
 * モック用fileをapiにpostしてcallbackを実行
 */
const readScannerMock = async (
  size: number,
  productTypeId: string,
  editStartedAt: string
) => {
  await alert(`
    スキャナ簡易モック\n
    size: ${size}\n
    productTypeId: ${productTypeId}\n
    editStartedAt: ${editStartedAt}
  `)
  const file: File = await readFile()
  const blob = URL.createObjectURL(file)
  if (file) {
    const resp = await savePhoto({
      file,
      product_type_id: productTypeId,
      edit_started_at: editStartedAt
    })
    try {
      window.external.GetImageIdsString = () => ({
        photos: resp.photo ? [resp.photo] : []
      })
    } catch (e) {
      // IEではモックを使用しない
    }
    window.k_nenga_native.callBackFunctionForScanner()
  }
}
/**
 * スキャナー画像読み取り機能の簡易モックを表示する
 */
export const mock = () => {
  if (!window.external) {
    ;(window.external as any) = {}
  }
  try {
    if (!window.external.ReadScanner) {
      window.external.ReadScanner = readScannerMock
    }
    if (!window.external.GetImageIdsString) {
      window.external.GetImageIdsString = () => ({
        photos: []
      })
    }
  } catch (e) {
    // IE環境ではwindow.externalに代入ができないが、
    // 店頭端末等があるためモックは使用しない
  }
}
