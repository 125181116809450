import { call, put, takeLeading } from 'redux-saga/effects'

import {
  ActionTypes as AlertActionTypes,
  SetInitFailureAlert
} from '@/store/modules/alert/actions'
import {
  ActionTypes,
  LoadFixedPhrasesFailed,
  LoadFixedPhrasesRequested,
  LoadFixedPhrasesSucceeded
} from './actions'

import {
  fetchMessageTemplates,
  FetchMessageTemplatesResponse
} from '@kn/common/lib/api/fetchMessageTemplates'

import { FixedPhrase } from '@/types/FixedPhrase'

import { sendApiError } from '@/utils/sentry'

/**
 * 商品種別に対応する定型文をAPIから取得
 */
function* loadFixedPhrases(action: LoadFixedPhrasesRequested) {
  try {
    const resp: FetchMessageTemplatesResponse = yield call(
      fetchMessageTemplates,
      {
        product_type_id: action.payload.productTypeId
      }
    )

    yield put<LoadFixedPhrasesSucceeded>({
      type: ActionTypes.LoadFixedPhrasesSucceeded,
      payload: resp.message_templates.map(m => FixedPhrase.fromRaw(m))
    })
  } catch (e) {
    yield put<LoadFixedPhrasesFailed>({
      type: ActionTypes.LoadFixedPhrasesFailed
    })
    yield put<SetInitFailureAlert>({
      type: AlertActionTypes.SetInitFailureAlert
    })
    sendApiError(e)
  }
}

/**
 * 定型文ストア上の非同期処理を行うアクションを監視する
 */
export function* sagas() {
  yield takeLeading(ActionTypes.LoadFixedPhrasesRequested, loadFixedPhrases)
}
