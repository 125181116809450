import { Action } from 'redux'

import { ApiError } from '@/types/Error/ApiError'
import Order from '@/types/Order'
import Customer from '@/types/Order/Customer'
import Estimate from '@/types/Order/Estimate'

export enum ActionTypes {
  PatchOrder = 'order/PATCH_ORDER',
  PatchCustomer = 'order/PATCH_CUSTOMER',
  SaveOrderRequested = 'order/SAVE_ORDER_REQUESTED',
  SaveOrderSucceeded = 'order/SAVE_ORDER_SUCCEEDED',
  SaveOrderFailed = 'order/SAVE_ORDER_FAILED',
  CompleteOrderRequested = 'order/COMPLETE_ORDER_REQUESTED',
  CompleteOrderSucceeded = 'order/COMPLETE_ORDER_SUCCEEDED',
  CompleteOrderFailed = 'order/COMPLETE_ORDER_FAILED',
  PrintReceiptRequested = 'order/PRINT_RECEIPT_REQUESTED',
  PrintReceiptSucceeded = 'order/PRINT_RECEIPT_SUCCEEDED',
  PrintReceiptFailed = 'order/PRINT_RECEIPT_FAILED'
}

export type Actions =
  | PatchOrder
  | PatchCustomer
  | SaveOrderRequested
  | SaveOrderSucceeded
  | SaveOrderFailed
  | CompleteOrderRequested
  | CompleteOrderSucceeded
  | CompleteOrderFailed
  | PrintReceiptRequested
  | PrintReceiptSucceeded
  | PrintReceiptFailed

export interface PatchOrder extends Action {
  type: ActionTypes.PatchOrder
  payload: Partial<Order>
}

export interface PatchCustomer extends Action {
  type: ActionTypes.PatchCustomer
  payload: Partial<Customer>
}

/**
 * 注文情報を登録
 */
export interface SaveOrderRequested extends Action {
  type: ActionTypes.SaveOrderRequested
}
export interface SaveOrderSucceeded extends Action {
  type: ActionTypes.SaveOrderSucceeded
  payload: {
    orderId: string
    estimate: Estimate
  }
}
export interface SaveOrderFailed extends Action {
  type: ActionTypes.SaveOrderFailed
  payload: ApiError
}

/**
 * 受注完了させる
 */
export interface CompleteOrderRequested extends Action {
  type: ActionTypes.CompleteOrderRequested
}
export interface CompleteOrderSucceeded extends Action {
  type: ActionTypes.CompleteOrderSucceeded
  payload: { orderNumber: string }
}
export interface CompleteOrderFailed extends Action {
  type: ActionTypes.CompleteOrderFailed
  payload: ApiError
}

/** レシート印刷させる */
export interface PrintReceiptRequested extends Action {
  type: ActionTypes.PrintReceiptRequested
}
export interface PrintReceiptSucceeded extends Action {
  type: ActionTypes.PrintReceiptSucceeded
}
export interface PrintReceiptFailed extends Action {
  type: ActionTypes.PrintReceiptFailed
}
