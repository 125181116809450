import { SenderHeidenDataRequest } from '@kn/common/lib/api/common/types/request/senderHeidenData'

export const initialSenderParams: SenderHeidenDataRequest = {
  company: null,
  address1_1: null,
  address1_2: null,
  address2_1: null,
  address2_2: null,
  address3_1: null,
  department: null,
  position_1: null,
  position_2: null,
  position_3: null,
  position_4: null,
  postcode_1: null,
  postcode_2: null,
  last_name_1: null,
  last_name_2: null,
  last_name_3: null,
  last_name_4: null,
  last_name_5: null,
  last_name_6: null,
  mail_url1_1: null,
  mail_url2_1: null,
  company_type: null,
  first_name_1: null,
  first_name_2: null,
  first_name_3: null,
  first_name_4: null,
  first_name_5: null,
  first_name_6: null,
  telephone1_1: null,
  telephone1_2: null,
  telephone2_1: null,
  telephone2_2: null,
  telephone3_1: null,
  address_prefix_1: null,
  address_prefix_2: null,
  last_name_read_1: null,
  last_name_read_2: null,
  last_name_read_3: null,
  last_name_read_4: null,
  last_name_read_5: null,
  last_name_read_6: null,
  first_name_read_1: null,
  first_name_read_2: null,
  first_name_read_3: null,
  first_name_read_4: null,
  first_name_read_5: null,
  first_name_read_6: null,
  address_addition_1: null,
  mail_url_prefix1_1: null,
  mail_url_prefix2_1: null,
  name_parentheses_1: null,
  name_parentheses_2: null,
  name_parentheses_3: null,
  name_parentheses_4: null,
  name_parentheses_5: null,
  name_parentheses_6: null,
  telephone_prefix1_1: null,
  telephone_prefix1_2: null,
  telephone_prefix2_1: null,
  telephone_prefix2_2: null,
  telephone_prefix3_1: null
}
