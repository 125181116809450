import { Reducer } from 'redux'

import {
  Actions as TemplateDetailActions,
  ActionTypes as TemplateDetailActionTypes
} from '@/store/modules/templateDetail/actions'
import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

import Message from '@/types/Message'

const reducer: Reducer<State, Actions | TemplateDetailActions> = (
  state = initialState,
  action
) => {
  const initMessage: Message = {
    textFormType: 'message1',
    text: '',
    layout: {
      alignment: 'start',
      isVertical: false
    },
    style: {
      color: '#000000',
      hasBorder: false,
      fontId: '8ad99a13-681d-4ddc-a930-66eff7dbc90c'
    },
    image: ''
  }

  switch (action.type) {
    case TemplateDetailActionTypes.LoadTemplateDetailSucceeded:
      return {
        ...state,
        items: action.payload.response.messages.map((message, index) => {
          return {
            ...initMessage,
            text: message.text || '',
            textFormType: `message${index + 1}`,
            style: {
              ...initMessage.style,
              fontId: message.fontId,
              color: message.colorCode,
              hasBorder: message.borderFlag
            },
            layout: {
              ...initMessage.layout,
              alignment: message.alignment,
              isVertical: message.direction === 'vertical'
            }
          }
        })
      }
    case ActionTypes.Clear:
      return {
        ...state,
        items: state.items.map((message, index) => {
          const defaultMessage = action.payload.message
          if (message.textFormType !== defaultMessage.textFormType) {
            return message
          }
          // TODO: APIのレスポンス仕様（デフォルト値）が定まり次第調整
          return {
            ...initMessage,
            style: {
              ...initMessage.style,
              color: defaultMessage.colorCode,
              hasBorder: defaultMessage.borderFlag,
              fontId: message.style.fontId
            },
            layout: {
              ...initMessage.layout,
              alignment: defaultMessage.alignment,
              isVertical: defaultMessage.direction === 'vertical'
            }
          }
        })
      }

    case ActionTypes.Set:
      return {
        ...state,
        items: state.items.map((message, index) => {
          if (index !== action.payload.slot) {
            return message
          }
          return { ...message, text: action.payload.text }
        })
      }
    case ActionTypes.SetDirection:
      return {
        ...state,
        items: state.items.map((message, index) => {
          if (index !== action.payload.slot) {
            return message
          }
          return {
            ...message,
            layout: {
              ...message.layout,
              isVertical: action.payload.direction === 'vertical'
            }
          }
        })
      }
    case ActionTypes.SetAlignment:
      return {
        ...state,
        items: state.items.map((message, index) => {
          if (index !== action.payload.slot) {
            return message
          }
          return {
            ...message,
            layout: {
              ...message.layout,
              alignment: action.payload.alignment
            }
          }
        })
      }
    case ActionTypes.PatchStyle:
      return {
        ...state,
        items: state.items.map((message, index) => {
          if (index !== action.payload.slot) {
            // フォントIDの変更は全てのあいさつ文・コメントに適用する
            if (action.payload.style.fontId) {
              return {
                ...message,
                style: {
                  ...message.style,
                  fontId: action.payload.style.fontId
                }
              }
            }

            return message
          }
          return {
            ...message,
            style: {
              ...message.style,
              ...action.payload.style
            }
          }
        })
      }
    case ActionTypes.SetImage:
      return {
        ...state,
        isLoading: false,
        items: state.items.map((message, index) => {
          if (index !== action.payload.slot) {
            return message
          }
          return { ...message, image: action.payload.path }
        })
      }
    case ActionTypes.SetFont:
      return {
        ...state,
        isLoading: false,
        items: state.items.map(message => {
          return {
            ...message,
            style: {
              ...message.style,
              ...action.payload
            }
          }
        })
      }
    case ActionTypes.FetchImageFailed:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.SetEditingMode:
      return {
        ...state,
        editingSlot: action.payload
      }
    case ActionTypes.LoadStart:
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.LoadEnd:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.ClearError:
      return {
        ...state,
        errors: state.errors.filter(error => error.slot !== action.payload.slot)
      }
    case ActionTypes.SetError:
      const filterdErrors = state.errors.filter(
        error => error.slot !== action.payload.slot
      )

      return {
        ...state,
        errors: [
          ...filterdErrors,
          {
            status: action.payload.status,
            errorCode: action.payload.errorCode,
            slot: action.payload.slot
          }
        ]
      }
    default:
      return state
  }
}

export default reducer
