import { ApiError } from '@/types/Error/ApiError'
import Margin from '@/types/Margin'
import Photo from '@/types/Preview/Photo'
import Text from '@/types/Preview/Text'
import Rect from '@/types/Rect'
import Size from '@/types/Size'

export interface State {
  /** 仕上げタイプ */
  finished: 'print' | 'photo'
  /** 写真枠のレイアウト */
  photos: Photo[]
  /** 差出人画像のレイアウト */
  sender: Text
  /** あいさつ文・コメント画像のレイアウト */
  messages: Text[]
  /** ブルーマージン */
  blueMargin: Margin
  /** テンプレートサイズ */
  templateSize: Size
  /** ローディング */
  isLoading: boolean
  /** プレビュー画像 */
  image: string
  /** エラー */
  errors: {
    saveDesign: ApiError | null
    fetchPreviewImage: ApiError | null
  }
  initPreviewLayout: {
    vector: [number, number]
    scale: number
  }
  textSizeAlert: boolean
}

export const initialState: State = {
  finished: 'print',
  photos: [],
  sender: {
    isEditing: false,
    textFormType: '',
    image: '',
    frameX: 0,
    frameY: 0,
    frameWidth: 0,
    frameHeight: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    scale: 1,
    rotate: 0
  },
  messages: [],
  blueMargin: {
    top: 60,
    right: 60,
    bottom: 60,
    left: 60
  },
  templateSize: {
    width: 0,
    height: 0
  },
  isLoading: false,
  image: '',
  errors: {
    saveDesign: null,
    fetchPreviewImage: null
  },
  initPreviewLayout: {
    vector: [0, 0],
    scale: 1
  },
  textSizeAlert: false
}
