import { AffineTransform, Point2D } from '@/types/Position'

/**
 * 平行移動
 * X軸方向にx、Y軸方向にyだけ移動するアフィン変換
 * @param x X座標の移動距離
 * @param y Y座標の移動距離
 */
export const translate = (x: number, y: number): AffineTransform => [
  [1, 0, x],
  [0, 1, y],
  [0, 0, 1]
]

/**
 * 拡大縮小
 * X軸方向の拡大率をs、Y軸方向の拡大率をsとすると拡大縮小のアフィン変換
 * @param s 拡大率
 */
export const scale = (s: number): AffineTransform => [
  [s, 0, 0],
  [0, s, 0],
  [0, 0, 1]
]

/**
 * 回転
 * 原点を中心に反時計回りにθ°回転する時のアフィン変換
 * @param degree 角度
 */
export const rotate = (degree: number): AffineTransform => {
  const clippedDegree =
    degree < 0 ? 360 - (Math.abs(degree) % 360) : degree % 360

  const radian = clippedDegree * (Math.PI / 180)

  const cos = Math.cos(radian)
  const sin = Math.sin(radian)

  return [[cos, -sin, 0], [sin, cos, 0], [0, 0, 1]]
}

/**
 * ２次元座標系における合成変換
 * 2つのアフィン変換結果を行列計算して１つにする。
 * @param a
 * @param b
 *
 * |a1 b1 tx1|   |a2 b2 tx2|
 * |c1 d1 ty1| * |c2 d2 ty2|
 * | 0  0  1 |   | 0  0  1 |
 */
export const combineAffineTransform = (
  left: AffineTransform,
  right: AffineTransform
): AffineTransform => {
  const [[a1, b1, tx1], [c1, d1, ty1]] = left

  const [[a2, b2, tx2], [c2, d2, ty2]] = right

  return [
    [a1 * a2 + b1 * c2, a1 * b2 + b1 * d2, a1 * tx2 + b1 * ty2 + tx1],
    [c1 * a2 + d1 * c2, c1 * b2 + d1 * d2, c1 * tx2 + d1 * ty2 + ty1],
    [0, 0, 1]
  ]
}
/**
 * ２次元座標系における合成変換
 * アフィン変換結果と現在の座標を行列計算して座標を割り出す
 */
export const combineAffineTransforms = (
  ...transforms: AffineTransform[]
): AffineTransform => transforms.reduce((a, b) => combineAffineTransform(a, b))

/**
 * 2次元座標に対してアフィン変換を適用する
 * @param m 変換行列
 * @param position 座標
 */
export const applyAffineTransform = (
  m: AffineTransform,
  position: Point2D
): Point2D => {
  const [[a, b, tx], [c, d, ty]] = m

  const [x, y] = position

  return [a * x + b * y + tx, c * x + d * y + ty]
}

/**
 * 変換行列をMatrixCSSの値へと変換する。
 * @param m 変換行列
 */
export const affineToCSSMatrix = (m: AffineTransform): string => {
  return `${m[0][0]},${m[1][0]},${m[0][1]},${m[1][1]},${m[0][2]},${m[1][2]}`
}
