import * as React from 'react'
import styled from 'styled-components'

interface Props {
  /** クリックイベント */
  onClick?: (ev: React.MouseEvent<HTMLElement>) => any
  /** 表示・非表示 */
  visible: boolean
  /** クラス名 */
  className?: string
}

export const Overlay: React.SFC<Props> = ({
  visible,
  onClick,
  className,
  children
}) => (
  <StyledOverlay className={className} visible={visible} onClick={onClick}>
    {children}
  </StyledOverlay>
)

export default Overlay
interface OverlayProps {
  visible: boolean
}

const StyledOverlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8);
  pointer-events: ${(p: OverlayProps) => (p.visible ? 'auto' : 'none')};
  opacity: ${(p: OverlayProps) => (p.visible ? 1 : 0)};
  transition: 0.2s linear opacity;
`
