import { CustomerResponse } from '@kn/common/lib/api/common/types/response/customer'

/**
 * ログインユーザー情報
 */
interface User {
  /**
   * 会員ID
   */
  id: string

  /**
   * キタムラ会員ID
   */
  memberId: string

  /**
   * キタムラ会員フラグ
   */
  isMemberFlag: boolean

  /**
   * メールアドレス 会員識別情報
   */
  email: string

  /**
   * 会員氏名１
   */
  lastName: string

  /**
   * 会員氏名２
   */
  firstName: string

  /**
   * 会員氏名カナ１
   */
  lastNameRead: string

  /**
   * 会員氏名カナ２
   */
  firstNameRead: string

  /**
   * 郵便番号 ハイフンを除く郵便番号形式
   */
  postcode: string

  /**
   * 住所1
   */
  address1: string

  /**
   * 住所2
   */
  address2: string

  /**
   * 住所3
   */
  address3: string

  /**
   * 住所4
   */
  address4: string

  /**
   * 住所5
   */
  address5: string

  /**
   * 電話番号 ハイフンを除く電話番号形式
   */
  phoneNumber: string

  /**
   * 認証用トークン
   */
  token: string

  /**
   * 認証用トークン有効期限 YYYY-MM-DD HH:II:SS
   */
  tokenExpirationAt: string

  /**
   * 作成日時
   */
  createdAt: string

  /**
   * 更新日時
   */
  updatedAt: string

  /**
   * 削除日時
   */
  deletedAt: string
}

/**
 * APIレスポンスのログインユーザー情報をフロント側で使う構造に変換する
 * @param raw APIレスポンスのログインユーザー情報
 */
const User = {
  fromRaw(raw: RawUser): User {
    return {
      id: raw.id,
      memberId: raw.member_id,
      isMemberFlag: raw.is_member_flag,
      email: raw.email,
      lastName: raw.last_name,
      firstName: raw.first_name,
      lastNameRead: raw.last_name_read,
      firstNameRead: raw.first_name_read,
      postcode: raw.postcode,
      address1: raw.address_1,
      address2: raw.address_2,
      address3: raw.address_3,
      address4: raw.address_4,
      address5: raw.address_5,
      phoneNumber: raw.phone_number,
      token: raw.token,
      tokenExpirationAt: raw.token_expiration_at,
      createdAt: raw.created_at,
      updatedAt: raw.updated_at,
      deletedAt: raw.deleted_at
    }
  }
}

type RawUser = CustomerResponse

export default User
