import { push } from 'connected-react-router'
import { call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { RootState } from '@/store'

import {
  compositionsHeidens,
  CompositionsParams
} from '@kn/common/lib/api/compositions'
import {
  getFileEditingPostcard,
  GetFileEditingPostcardParams
} from '@kn/common/lib/api/getFileEditingPostcard'
import {
  updateDesign,
  UpdateDesignParams
} from '@kn/common/lib/api/updateDesign'

import {
  ActionTypes,
  ClearPreviewImage,
  FetchPreviewImageFailed,
  FetchPreviewImageSucceeded,
  SaveDesignFailed,
  SaveDesignSucceeded
} from './actions'

import { getLayoutJson } from '@/store/selectors/getLayoutJson'

import * as error from '@/utils/error'
import { sendApiError } from '@/utils/sentry'

function* saveDesign() {
  const rootState = yield select((state: RootState) => state)
  const {
    config: { token, postcardEditingRecordId }
  } = rootState

  const compositionsParams = {
    ...getLayoutJson(rootState),
    token
  }

  const updateDesignParams: UpdateDesignParams = {
    ...compositionsParams,
    design_id: postcardEditingRecordId
  }

  try {
    // 合成API通信
    yield call(compositionsHeidens, compositionsParams)

    // デザイン更新API通信
    yield call(updateDesign, updateDesignParams)

    yield put<SaveDesignSucceeded>({
      type: ActionTypes.SaveDesignSucceeded
    })

    yield fork(fetchPreviewImage)
  } catch (e) {
    yield put<SaveDesignFailed>({
      type: ActionTypes.SaveDesignFailed,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e)
      }
    })
    sendApiError(e)
    yield put(push('/edit/confirm/'))
  }
}

// プレビュー画像取得
function* fetchPreviewImage() {
  const {
    config: { token, postcardEditingRecordId },
    preview: { image }
  } = yield select((state: RootState) => state)
  if (image) {
    URL.revokeObjectURL(image)
  }
  yield put<ClearPreviewImage>({
    type: ActionTypes.ClearPreviewImage
  })

  try {
    const newImage = yield call(getFileEditingPostcard, {
      token,
      postcard_editing_record_id: postcardEditingRecordId,
      type: '0' // プレビュー画像指定
    })

    yield put<FetchPreviewImageSucceeded>({
      type: ActionTypes.FetchPreviewImageSucceeded,
      payload: { image: URL.createObjectURL(newImage) }
    })
  } catch (e) {
    yield put<FetchPreviewImageFailed>({
      type: ActionTypes.FetchPreviewImageFailed,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e)
      }
    })
    sendApiError(e)
  }
}

export function* sagas() {
  yield takeLatest(ActionTypes.SaveDesignRequested, saveDesign)
  yield takeLatest(ActionTypes.FetchPreviewImageRequested, fetchPreviewImage)
}
