import * as React from 'react'
import styled from 'styled-components'

interface Props {
  /** クラス名 */
  className?: string
}

export const SectionTitle: React.SFC<Props> = ({ children, className }) => (
  <Title className={className}>{children}</Title>
)

export default SectionTitle

const Title = styled.div`
  color: #6f6f6f;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  user-select: none;
`
