import { Action } from 'redux'

import PostcardAddress from '@/types/PostcardAddresses/PostcardAddress'

import { State } from './state'

import { PostcardAddressesError } from '@/types/Error/PostcardAddressesError'
import Config from '@/types/PostcardAddresses/Config'

export enum ActionTypes {
  LoadConfigRequested = 'postcardAddresses/LOAD_CONFIG_REQUESTED',
  LoadConfigSucceeded = 'postcardAddresses/LOAD_CONFIG_SUCCEEDED',
  LoadSigninRequested = 'postcardAddresses/LOAD_SIGNIN_REQUESTED',
  LoadSigninSucceeded = 'postcardAddresses/LOAD_SIGNIN_SUCCEEDED',
  LoadSigninFailed = 'postcardAddresses/LOAD_SIGNIN_FAILED',
  LoadPostcardAddressesRequested = 'postcardAddresses/LOAD_POSTCARD_ADDRESSES_REQUESTED',
  LoadPostcardAddressesSucceeded = 'postcardAddresses/LOAD_POSTCARD_ADDRESSES_SUCCEEDED',
  LoadEnd = 'postcardAddresses/LOAD_END',
  UpdateConfigRequested = 'postcardAddresses/UPDATE_CONFIG_REQUESTED',
  UpdateConfigSucceeded = 'postcardAddresses/UPDATE_CONFIG_SUCCEEDED',
  UpdateInitialConfig = 'postcardAddresses/UpdateInitialConfig',
  SetInitialConfig = 'postcardAddresses/SetInitialConfig',
  UpdateOnlyTargetPostcardAddressRequested = 'postcardAddresses/UPDATE_ONLY_TARGET_POSTCARD_ADDRESS_REQUESTED',
  UpdateOnlyTargetPostcardAddressSucceeded = 'postcardAddresses/UPDATE_ONLY_TARGET_POSTCARD_ADDRESS_SUCCEEDED',
  UpdateTargetPostcardAddressRequested = 'postcardAddresses/UPDATE_TARGET_POSTCARD_ADDRESS_REQUESTED',
  UpdateTargetPostcardAddressSucceeded = 'postcardAddresses/UPDATE_TARGET_POSTCARD_ADDRESS_SUCCEEDED',
  DeleteTargetPostcardAddressRequested = 'postcardAddresses/DELETE_TARGET_POSTCARD_ADDRESS_REQUESTED',
  DeleteTargetPostcardAddressSucceeded = 'postcardAddresses/DELETE_TARGET_POSTCARD_ADDRESS_SUCCEEDED',
  ChangeSortType = 'postcardAddresses/CHANGE_SORT_TYPE',
  SetConstants = 'postcardAddresses/SET_CONSTANTS',
  SetError = 'postcardAddresses/SET_ERROR',
  ClearError = 'postcardAddresses/CLEAR_ERROR'
}

export type Actions =
  | LoadSigninRequested
  | LoadSigninSucceeded
  | LoadSigninFailed
  | LoadEnd
  | LoadConfigRequested
  | LoadConfigSucceeded
  | LoadPostcardAddressesRequested
  | LoadPostcardAddressesSucceeded
  | UpdateConfigRequested
  | UpdateConfigSucceeded
  | UpdateInitialConfig
  | SetInitialConfig
  | UpdateOnlyTargetPostcardAddressRequested
  | UpdateOnlyTargetPostcardAddressSucceeded
  | UpdateTargetPostcardAddressRequested
  | UpdateTargetPostcardAddressSucceeded
  | DeleteTargetPostcardAddressRequested
  | DeleteTargetPostcardAddressSucceeded
  | ChangeSortType
  | SetConstants
  | SetError
  | ClearError

/**
 * ログインして宛名一覧に遷移
 */
export interface LoadSigninRequested extends Action {
  type: ActionTypes.LoadSigninRequested
  payload: {
    email: string
    password: string
  }
}
export interface LoadSigninSucceeded extends Action {
  type: ActionTypes.LoadSigninSucceeded
  payload: PostcardAddress[]
}
export interface LoadSigninFailed extends Action {
  type: ActionTypes.LoadSigninFailed
}

/**
 * 宛名一覧を読み込む
 */
export interface LoadPostcardAddressesRequested extends Action {
  type: ActionTypes.LoadPostcardAddressesRequested
}
export interface LoadPostcardAddressesSucceeded extends Action {
  type: ActionTypes.LoadPostcardAddressesSucceeded
  payload: PostcardAddress[]
}
export interface LoadEnd extends Action {
  type: ActionTypes.LoadEnd
}

/**
 * 宛名印刷するかの切り替え
 */
export interface UpdateOnlyTargetPostcardAddressRequested extends Action {
  type: ActionTypes.UpdateOnlyTargetPostcardAddressRequested
  payload: {
    postcardAddressIds: string[]
    printingFlag: boolean
  }
}
export interface UpdateOnlyTargetPostcardAddressSucceeded extends Action {
  type: ActionTypes.UpdateOnlyTargetPostcardAddressSucceeded
  payload: {
    postcardAddressIds: string[]
    printingFlag: boolean
  }
}

/**
 * 宛名印刷するかの切り替えと宛名リストの更新
 */
export interface UpdateTargetPostcardAddressRequested extends Action {
  type: ActionTypes.UpdateTargetPostcardAddressRequested
  payload: {
    postcardAddressIds: string[]
    printingFlag: boolean
  }
}
export interface UpdateTargetPostcardAddressSucceeded extends Action {
  type: ActionTypes.UpdateTargetPostcardAddressSucceeded
  payload: PostcardAddress[]
}

/**
 * 宛名削除
 */
export interface DeleteTargetPostcardAddressRequested extends Action {
  type: ActionTypes.DeleteTargetPostcardAddressRequested
  payload: {
    postcardAddressIds: string[]
  }
}
export interface DeleteTargetPostcardAddressSucceeded extends Action {
  type: ActionTypes.DeleteTargetPostcardAddressSucceeded
  payload: PostcardAddress[]
}

/**
 * 宛名一覧の並び順変更
 */
export interface ChangeSortType extends Action {
  type: ActionTypes.ChangeSortType
  payload: {
    sortType: 'added' | 'alphabetical'
  }
}

/**
 * 宛名の設定を読み込み、設定がなければ初期設定を登録する
 */
export interface UpdateInitialConfig extends Action {
  type: ActionTypes.UpdateInitialConfig
}

/**
 * 宛名の初期設定を登録する
 */
export interface SetInitialConfig extends Action {
  type: ActionTypes.SetInitialConfig
}

/**
 * 宛名の設定を読み込む
 */
export interface LoadConfigRequested extends Action {
  type: ActionTypes.LoadConfigRequested
}
export interface LoadConfigSucceeded extends Action {
  type: ActionTypes.LoadConfigSucceeded
  payload: Config
}

/**
 * 宛名の設定を更新する
 */
export interface UpdateConfigRequested extends Action {
  type: ActionTypes.UpdateConfigRequested
  payload: {
    fontId: string
    layoutType: number
  }
}
export interface UpdateConfigSucceeded extends Action {
  type: ActionTypes.UpdateConfigSucceeded
  payload: Config
}

export interface SetConstants extends Action {
  type: ActionTypes.SetConstants
  payload: State['constants']
}

export interface SetError extends Action {
  type: ActionTypes.SetError
  payload: PostcardAddressesError
}

export interface ClearError extends Action {
  type: ActionTypes.ClearError
}
