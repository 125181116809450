export enum MemoryCardEjectResult {
  safety = 1,
  notInserted = 2,
  using = 3
}

declare global {
  interface External {
    /**
     * USBメモリを安全に取り外す機能を呼び出す
     */
    MemoryCardEject(): MemoryCardEjectResult
  }
}

export const ejectMemoryCard = () => {
  return window.external.MemoryCardEject()
}

const memoryCardEjectMock = () => {
  return MemoryCardEjectResult.safety
}

/**
 * USBメモリを安全に取り外す機能の簡易モック
 */
export const mock = () => {
  if (!window.external) {
    ;(window.external as any) = {}
  }
  try {
    if (!window.external.MemoryCardEject) {
      window.external.MemoryCardEject = memoryCardEjectMock
    }
  } catch (e) {
    // IE環境ではwindow.externalに代入ができないが、
    // 店頭端末等があるためモックは使用しない
  }
}
