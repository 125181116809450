interface Color {
  /** カラーコード */
  color: string
  /** カラーID */
  id: number
}

const Color = {
  /**
   * APIレスポンスの「メタデータ: カラー」をフロント側で使う構造に変換する
   * @param raw APIレスポンスの「メタデータ: カラー」
   */
  fromRaw(raw: RawColor): Color {
    return {
      color: raw.color_code,
      id: raw.value
    }
  }
}

export default Color

export interface RawColor {
  r: number
  g: number
  b: number
  color_code: string
  value: number
}
