export interface Prefix {
  /** 接頭辞 */
  label: string
  /** 接頭辞ID */
  id: number
}

const Prefix = {
  /**
   * APIレスポンスの「メタデータ: プレフィックス」をフロント側で使う構造に変換する
   * @param raw APIレスポンスの「メタデータ: プレフィックス」
   */
  fromRaw(raw: RawPrefix): Prefix {
    return {
      label: raw.prefix,
      id: raw.value
    }
  }
}

export default Prefix

export interface RawPrefix {
  prefix: string
  value: number
}
