import {
  ActionTypes as AlertActionTypes,
  SetInitFailureAlert
} from '@/store/modules/alert/actions'
import {
  ActionTypes,
  LoadPostcardTypesFailed,
  LoadPostcardTypesRequested,
  LoadPostcardTypesSucceeded
} from '@/store/modules/postcardTypes/actions'
import {
  fetchPostcardTypes,
  FetchPostcardTypesResponse
} from '@kn/common/lib/api/fetchPostcardTypes'
import { call, put, takeLatest } from 'redux-saga/effects'

import PostcardType from '@/types/PostcardType'

import { sendApiError } from '@/utils/sentry'

/**
 * ハガキ種別一覧をAPIから取得
 */
function* loadPostcardTypes(action: LoadPostcardTypesRequested) {
  try {
    const resp: FetchPostcardTypesResponse = yield call(fetchPostcardTypes, {
      product_type_id: action.payload
    })

    yield put<LoadPostcardTypesSucceeded>({
      type: ActionTypes.LoadPostcardTypesSucceeded,
      payload: resp.postcard_types.map(postcardType =>
        PostcardType.fromRaw(postcardType)
      )
    })
  } catch (e) {
    yield put<LoadPostcardTypesFailed>({
      type: ActionTypes.LoadPostcardTypesFailed
    })
    yield put<SetInitFailureAlert>({
      type: AlertActionTypes.SetInitFailureAlert
    })
    sendApiError(e)
  }
}

/**
 * ハガキ種別ストア上の非同期処理を行うアクションを監視する
 */
export function* sagas() {
  yield takeLatest(ActionTypes.LoadPostcardTypesRequested, loadPostcardTypes)
}
