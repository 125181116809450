import { ApiError } from '@/types/Error/ApiError'
import Upload from '@/types/Upload'

/**
 * アップロード用の設定を管理するステート
 */
export interface State extends Upload {
  isLoading: boolean
  error: ApiError | null
  uploadPhotoCount: number
}

export const initialState: State = {
  isLoading: false,
  photoGroupId: '',
  qrCode: '',
  error: null,
  uploadPhotoCount: -1
}
