import {
  fetchTemplates,
  FetchTemplatesResponse
} from '@kn/common/lib/api/fetchTemplates'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  ActionTypes,
  LoadEnd,
  LoadTemplateListFailed,
  LoadTemplateListRequested,
  LoadTemplateListSucceeded
} from './actions'

import Template from '@/types/Templates/Template'
import * as error from '@/utils/error'
import { sendApiError } from '@/utils/sentry'

/**
 * テンプレート一覧をAPIから取得
 */
function* loadTemplateList(action: LoadTemplateListRequested) {
  try {
    const resp: FetchTemplatesResponse = yield call(fetchTemplates, {
      product_type_id: action.payload.productTypeId,
      code: action.payload.code,
      postcard_design_category_id: action.payload.postcardDesignCategoryId,
      frame_count_type: action.payload.frameCountType
    })

    yield put<LoadTemplateListSucceeded>({
      type: ActionTypes.LoadTemplateListSucceeded,
      payload: resp.templates.map(template => Template.fromRaw(template))
    })
  } catch (e) {
    yield put<LoadTemplateListFailed>({
      type: ActionTypes.LoadTemplateListFailed,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e)
      }
    })
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * テンプレート一覧ストア上の非同期処理を行うアクションを監視する
 */
export function* sagas() {
  yield takeLatest(ActionTypes.LoadTemplateListRequested, loadTemplateList)
}
