import { Action } from 'redux'

export enum ActionTypes {
  SetInitFailureAlert = 'alert/SET_INIT_FAILURE_ALERT',
  ToggleEditStartFailureAlert = 'alert/TOGGLE_EDIT_START_FAILURE_ALERT'
}

export type Actions = SetInitFailureAlert | ToggleEditStartFailureAlert

/** 初期化失敗の警告 */
export interface SetInitFailureAlert extends Action {
  type: ActionTypes.SetInitFailureAlert
}

/** 編集開始失敗の警告 */
export interface ToggleEditStartFailureAlert extends Action {
  type: ActionTypes.ToggleEditStartFailureAlert
}
