import FixedPhrase from '@/types/FixedPhrase'

/**
 * APIから取得した定型文を管理するステート
 */
export interface State {
  /** ローディング中かどうか */
  isLoading: boolean
  /** APIから取得した定型文 */
  response: ReadonlyArray<FixedPhrase>
}

export const initialState: State = {
  isLoading: false,
  response: []
}
