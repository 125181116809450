import PostcardAddress from '@/types/PostcardAddresses/PostcardAddress'

/**
 * 宛名確認の状態を管理するステート
 */
export interface State {
  /** 表示しているページ */
  page: number
  /** 送信可能フラグ */
  enableSubmit: boolean
}

export const initialState: State = {
  page: 1,
  enableSubmit: false
}
