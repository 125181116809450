import { Reducer } from 'redux'

import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UpdateImageRequested:
      return {
        ...state,
        images: [
          ...state.images.filter(image => image.id !== action.payload.id),
          {
            id: action.payload.id,
            isLoading: true,
            shouldReload: false
          }
        ]
      }
    case ActionTypes.UpdateImageSucceeded:
      return {
        ...state,
        images: [
          ...state.images.filter(image => image.id !== action.payload.id),
          {
            id: action.payload.id,
            url: action.payload.url,
            isLoading: false,
            shouldReload: false
          }
        ]
      }
    case ActionTypes.UpdateImageFailed:
      return {
        ...state,
        images: [
          ...state.images.filter(image => image.id !== action.payload.id),
          {
            id: action.payload.id,
            isLoading: false,
            shouldReload: true
          }
        ]
      }
    case ActionTypes.RemoveImages:
      return {
        ...state,
        images: state.images.filter(
          stateImage =>
            !action.payload.find(image => stateImage.id === image.id)
        )
      }
    default:
      return state
  }
}

export default reducer
