import { push } from 'connected-react-router'
import { call, delay, put, select, takeLatest } from 'redux-saga/effects'

import { RootState } from '@/store'

import {
  saveOrders,
  SaveOrdersParams,
  SaveOrdersResponse
} from '@kn/common/lib/api/saveOrders'
import {
  updateOrders,
  UpdateOrdersParams
} from '@kn/common/lib/api/updateOrder'

import {
  ActionTypes,
  CompleteOrderFailed,
  CompleteOrderSucceeded,
  PrintReceiptFailed,
  PrintReceiptSucceeded,
  SaveOrderFailed,
  SaveOrderSucceeded
} from './actions'

import Customer from '@/types/Order/Customer'
import Estimate from '@/types/Order/Estimate'
import PostcardAddress from '@/types/PostcardAddresses/PostcardAddress'

import { getLayoutJson } from '@/store/selectors/getLayoutJson'

import { execPrintReport } from '@/misc/receiptPrinting'
import * as error from '@/utils/error'
import { sendApiError } from '@/utils/sentry'

function* saveOrder() {
  // 固定値
  const fixedParam = {
    local_type: 0,
    payment_type: 0,
    shipping_type: 0,
    is_added_order_flag: false,
    master_order_id: null,
    to_be_expired_at: null,
    is_security_code_set: null,
    masked_card_no: null,
    token: null,
    coupon_code: null
  }

  const {
    config,
    templateDetail: { response: templateDetail },
    postcardAddresses: { addressList: postcardAddressList },
    order: { order, customer }
  } = yield select((state: RootState) => state)

  const params: SaveOrdersParams = {
    token: config.token,
    order: {
      postcard_design_id: templateDetail.postcardDesignId,
      design_code: templateDetail.designCode,
      postcard_design_category_id: templateDetail.postcardDesignCategoryId,
      postcard_finishing_type: 0,
      product_type_id: config.productTypeId,
      postcard_editing_record_id: config.postcardEditingRecordId,
      shop_code: config.shopCode,
      orderer: {
        ...Customer.toRaw(customer),
        last_name_read: '',
        first_name_read: '',
        email: ''
      },
      postcard_type_id: order.postcardTypeId,
      postcard_count: postcardAddressList.filter(
        (address: PostcardAddress) => address.printingFlag
      ).length,
      print_count: order.printCount,
      carry_in_count: order.carryInCount,
      direct_mail_flag: order.directMailFlag,
      ...fixedParam
    }
  }

  try {
    const {
      order: { order_id: orderId, order_detail: rawEstimate }
    }: SaveOrdersResponse = yield call(saveOrders, params)

    yield put<SaveOrderSucceeded>({
      type: ActionTypes.SaveOrderSucceeded,
      payload: {
        orderId,
        estimate: Estimate.fromRaw(rawEstimate)
      }
    })
    yield put(push('/order/confirm/'))
  } catch (e) {
    yield put<SaveOrderFailed>({
      type: ActionTypes.SaveOrderFailed,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e)
      }
    })
    sendApiError(e)
  }
}

function* completeOrder() {
  const {
    config: { token },
    order: { orderId }
  } = yield select((state: RootState) => state)

  try {
    const { order_number } = yield call(updateOrders, {
      token,
      order_id: orderId
    })

    yield put<CompleteOrderSucceeded>({
      type: ActionTypes.CompleteOrderSucceeded,
      payload: {
        orderNumber: order_number
      }
    })
    yield put(push('/order/receipt/'))
  } catch (e) {
    // TODO: エラー時の処理
    yield put<CompleteOrderFailed>({
      type: ActionTypes.CompleteOrderFailed,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e)
      }
    })
    sendApiError(e)
  }
}

function* printReceipt() {
  const {
    config: { token },
    order: { orderId }
  } = yield select((state: RootState) => state)

  yield delay(1000)

  try {
    // レシート印刷を実行
    execPrintReport(token, orderId)

    yield put<PrintReceiptSucceeded>({
      type: ActionTypes.PrintReceiptSucceeded
    })
    yield put(push('/order/complete/'))
  } catch (e) {
    // TODO: エラー時の処理
    yield put<PrintReceiptFailed>({
      type: ActionTypes.PrintReceiptFailed
    })
  }
}

export function* sagas() {
  yield takeLatest(ActionTypes.SaveOrderRequested, saveOrder)
  yield takeLatest(ActionTypes.CompleteOrderRequested, completeOrder)
  yield takeLatest(ActionTypes.PrintReceiptRequested, printReceipt)
}
