import axios from 'axios';
import { apiURL } from '../common/';
/**
 * デザイン面編集時にデザインに関する情報を返す。
 */

export var getTemplateDetail = function getTemplateDetail(_ref) {
  var id = _ref.id;
  return axios.get("".concat(apiURL, "/api/v1/templates/").concat(id)).then(function (resp) {
    return resp.data;
  });
};