import { FetchFontTypesResponse } from '@kn/common/lib/api/fetchFontTypes'

/** 書体の型定義 */
interface Font {
  /** ID */
  id: string
  /** フォント名 */
  name: string
  /** ラベル */
  label: string
  /** 差出人・挨拶文で利用するフォントかどうか */
  isSenderFont: boolean
  /** 宛名で利用するフォントかどうか */
  isAddressFont: boolean
}

export const Font = {
  /**
   * APIレスポンスの「デザイン編集: 書体一覧」をフロント側で使う構造に変換する
   * @param raw APIレスポンスの「デザイン編集: 書体一覧」
   */
  fromRaw(raw: RawFont): Font {
    return {
      id: raw.id,
      name: raw.post_script_name,
      label: raw.name,
      isSenderFont: !!raw.sender_message_flag,
      isAddressFont: !!raw.postcard_address_flag
    }
  }
}

type RawFont = FetchFontTypesResponse['font_types'][0]

export default Font
