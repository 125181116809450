import React, { SFC } from 'react'

interface Props {
  /** クラス名 */
  className?: string
}

export const CloseIcon: SFC<Props> = ({ className }) => (
  <svg
    className={className}
    height="1em"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9999 16.28L21.1198 24.4C21.5998 24.84 22.3598 24.84 22.7998 24.4L24.7598 22.44C25.2398 22 25.2398 21.24 24.7598 20.76L16.6399 12.64L24.7999 4.47998C25.2799 4.03998 25.2799 3.27998 24.7999 2.79998L22.8399 0.83998C22.3999 0.35998 21.6399 0.35998 21.1599 0.83998L12.9999 9L4.83984 0.83998C4.39984 0.35998 3.63984 0.35998 3.15984 0.83998L1.19984 2.79998C0.719844 3.23998 0.719844 3.99998 1.19984 4.47998L9.35986 12.64L1.23988 20.76C0.759883 21.2 0.759883 21.96 1.23988 22.44L3.19988 24.4C3.63988 24.84 4.39988 24.84 4.87988 24.4L12.9999 16.28Z"
      fill="currentColor"
    />
  </svg>
)

export default CloseIcon
