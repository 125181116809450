import axios from 'axios';
import { apiURL } from '../common/';
/**
 * ファイルアップローダーQRコード生成APIのパラメータ
 */

/**
 * ファイルアップロードに必要なQRコードを生成する
 */

export var createQrCode = function createQrCode(params) {
  var formData = new FormData();
  formData.append('photo_group_id', params.photo_group_id);
  formData.append('product_type_id', params.product_type_id);
  return axios.post("".concat(apiURL, "/api/v1/files/create_qrcode"), formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    responseType: 'blob'
  }).then(function (resp) {
    return resp.data;
  });
};