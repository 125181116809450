import * as React from 'react'
import styled from 'styled-components'

import Button from '@/components/Buttons/Button'
import ModalFooter from '@/components/Footers/ModalFooter'
import AttentionIcon from '@/components/Icons/AttentionIcon'
import Modal from '@/components/Modals/Modal'
import SectionTitle from '@/components/Titles/SectionTitle'

export interface Props {
  /** 表示・非表示 */
  visible: boolean
  /** クラス名 */
  className?: string
  /** タイトル */
  title?: string
  /** キャンセルラベル */
  cancelLabel?: string
  /** 次へラベル */
  nextLabel?: string
  /** モーダルタイプ */
  type?: 'inline' | 'block'
  /** 強く警告するかどうか */
  isStrong?: boolean
  zIndex?: number
  /** キャンセルイベント */
  onCancel?: (ev: React.MouseEvent<HTMLElement>) => any
  /** 次へイベント */
  onNext?: (ev: React.MouseEvent<HTMLElement>) => any
  /** 閉じるイベント */
  onClose?: (ev: React.MouseEvent<HTMLElement>) => any
}

export const AlertModal: React.SFC<Props> = ({
  visible,
  className,
  title,
  cancelLabel = 'いいえ',
  nextLabel = 'はい',
  type = 'block',
  zIndex,
  onCancel,
  onNext,
  onClose,
  isStrong = false,
  children
}) => {
  return (
    <Modal
      className={className}
      visible={visible}
      onClose={onClose}
      type={type}
      zIndex={zIndex}
      footer={
        <ModalFooter
          arrangementType={type === 'block' ? 'between' : 'center'}
          leftButton={
            onCancel && (
              <CancelButton onClick={onCancel}>{cancelLabel}</CancelButton>
            )
          }
          centerButton={
            onNext && (
              <NextButton colorTheme="primary" onClick={onNext}>
                {nextLabel}
              </NextButton>
            )
          }
        />
      }
    >
      <Title isStrong={isStrong}>
        {isStrong && <StyledAttentionIcon />}
        {title}
      </Title>
      <Content>{children}</Content>
    </Modal>
  )
}

export default AlertModal

interface TitleProps {
  isStrong: boolean
}
const Title = styled(SectionTitle)`
  margin-top: 60px;
  color: ${(p: TitleProps) => (p.isStrong ? '#fb2525' : '#6f6f6f')};
`

const Content = styled.div`
  font-size: 30px;
  line-height: 1.5;
  text-align: center;
  color: #000;
  margin-top: 50px;
`

const StyledButton = styled(Button)`
  font-weight: bold;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  height: 78px;
`

const NextButton = styled(StyledButton)`
  width: 270px;
`

const CancelButton = styled(StyledButton)`
  width: 235px;
`

const StyledAttentionIcon = styled(AttentionIcon)`
  font-size: 40px;
  position: relative;
  top: 5px;
  margin-right: 5px;
`
