export interface QueryParameters {
  /** 店舗コード */
  shop_code?: number
}

/** URLのクエリパラメータを返す */
const getQueryParameters = (): QueryParameters => {
  const params = window.location.search.slice(1).split('&')
  const output: QueryParameters = {}
  for (const param of params) {
    const [key, value] = param.split('=')
    switch (key) {
      case 'shop_code':
        output[decodeURIComponent(key)] = parseInt(
          decodeURIComponent(value),
          10
        )
        break
      default:
        output[decodeURIComponent(key)] = decodeURIComponent(value)
        break
    }
  }
  return output
}

export default getQueryParameters
