import { connect, MapDispatchToProps, MapStateToProps, Omit } from 'react-redux'

import { RootState } from '@/store'

import AlertModal, { Props } from '@/components/Modals/AlertModal'

import { toggleEditStartFailureAlert } from '@/store/modules/alert'

import { push } from 'connected-react-router'

type StateProps = Pick<Props, 'visible'>
type DispatchProps = Pick<Props, 'onNext'>
type OwnProps = Omit<Props, keyof (StateProps & DispatchProps)>

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = ({
  alert
}) => {
  return {
    visible: alert.editStartFailureAlert.isVisible
  }
}

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  OwnProps
> = dispatch => {
  return {
    onNext() {
      dispatch(toggleEditStartFailureAlert())
      dispatch(push('/template/mourning/'))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertModal)
