import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { RootState } from '@/store'
import Color from '@/types/Constants/Color'
import { createMessageHeidens } from '@kn/common/lib/api/createMessageHeidens'
import { getFileEditingPostcardMessage } from '@kn/common/lib/api/getFileEditingPostcardMessage'

import * as error from '@/utils/error'

import {
  ActionTypes as PreviewActionTypes,
  InitLayout
} from '@/store/modules/preview/actions'
import {
  Actions,
  ActionTypes,
  ClearError,
  ClearRequest,
  FetchImage,
  FetchImageFailed,
  LoadEnd,
  LoadStart,
  PatchStyle,
  Set,
  SetAlignment,
  SetDirection,
  SetImage
} from './actions'

import { State } from './state'

import { getSize } from '@/utils/image'
import { sendApiError } from '@/utils/sentry'

function* fetchMessageImage(
  action: Set | SetDirection | SetAlignment | PatchStyle | FetchImage
) {
  // Note: 縦横・文字揃えが変更された時は編集情報を初期化する
  if (
    action.type === ActionTypes.SetDirection ||
    action.type === ActionTypes.SetAlignment ||
    action.type === ActionTypes.Set
  ) {
    yield put<InitLayout>({
      type: PreviewActionTypes.InitLayout,
      payload: { type: action.payload.slot === 0 ? 'message1' : 'message2' }
    })
  }
  yield put<ClearError>({
    type: ActionTypes.ClearError,
    payload: { slot: action.payload.slot }
  })
  yield put<LoadStart>({
    type: ActionTypes.LoadStart
  })

  const {
    token,
    productTypeId,
    postcardEditingRecordId,
    editStartedAt
  } = yield select((state: RootState) => state.config)

  const { items } = yield select((state: RootState) => state.messages)
  if (items.length <= 0) {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
    return
  }

  const { textFormType, style, layout, text, image } = items[
    action.payload.slot
  ]

  if (!text) {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
    return
  }

  const { fontColors } = yield select((state: RootState) => state.constants)

  const param = {
    token,
    postcard_editing_record_id: postcardEditingRecordId,
    product_type_id: productTypeId,
    edit_started_at: editStartedAt,
    text_form_type: textFormType,
    color_code: parseInt(
      fontColors.find((c: Color) => c.color === style.color).id,
      10
    ),
    border_flag: style.hasBorder,
    font_id: style.fontId,
    direction: layout.isVertical ? 0 : 1,
    alignment:
      layout.alignment === 'start' ? 0 : layout.alignment === 'center' ? 1 : 2,
    // Note: PNG指定のため 0 で固定
    output_type: 0,
    message: text
  }

  try {
    // あいさつ文・コメント組版APIへリクエスト
    yield call(createMessageHeidens, param)

    // あいさつ文・コメント画像取得APIからバイナリデータ取得
    const newImage = yield call(getFileEditingPostcardMessage, {
      token,
      postcard_editing_record_id: postcardEditingRecordId,
      no: textFormType === 'message1' ? 1 : 2
    })

    if (image) {
      URL.revokeObjectURL(image)
    }
    const path = URL.createObjectURL(newImage)

    const size = yield getSize(path)

    yield put<SetImage>({
      type: ActionTypes.SetImage,
      payload: { path, size, slot: action.payload.slot }
    })
  } catch (e) {
    yield put<Actions>({
      type: ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e),
        slot: action.payload.slot
      }
    })
    yield put<FetchImageFailed>({
      type: ActionTypes.FetchImageFailed
    })
    sendApiError(e)
  }
}

/**
 * あいさつ文・コメント情報を初期化
 * Note: 取得しているテンプレート詳細のあいさつ文・コメント情報をセットし初期化とする。
 */
function* clear(action: ClearRequest) {
  const { templateDetail }: RootState = yield select()
  const targetMessage = templateDetail.response.messages.find(message => {
    return message.textFormType === `message${action.payload.slot + 1}`
  })

  if (!targetMessage) {
    return
  }

  yield put<Actions>({
    type: ActionTypes.Clear,
    payload: { message: targetMessage }
  })
}

export function* sagas() {
  yield takeLatest(ActionTypes.Set, fetchMessageImage)
  yield takeLatest(ActionTypes.SetDirection, fetchMessageImage)
  yield takeLatest(ActionTypes.SetAlignment, fetchMessageImage)
  yield takeLatest(ActionTypes.PatchStyle, fetchMessageImage)
  yield takeLatest(ActionTypes.FetchImage, fetchMessageImage)

  yield takeEvery(ActionTypes.ClearRequest, clear)
}
