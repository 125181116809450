interface Layout {
  /** レイアウトタイプ */
  layoutType: string
  /** レイアウト名 */
  label: string
  /** レイアウトID */
  id: number
}

export const Layout = {
  /**
   * APIレスポンスの「メタデータ: レイアウトタイプ」をフロント側で使う構造に変換する
   * @param raw APIレスポンスの「メタデータ: レイアウトタイプ」
   */
  fromRaw(raw: RawLayout): Layout {
    return {
      layoutType: raw.layout_type,
      label: raw.name,
      id: raw.value
    }
  }
}

export default Layout

export interface RawLayout {
  layout_type: string
  name: string
  value: number
}
