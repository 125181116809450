import { ApiError } from '@/types/Error/ApiError'
import Order from '@/types/Order'
import Customer from '@/types/Order/Customer'
import Estimate from '@/types/Order/Estimate'

/**
 * 注文情報を管理するステート
 */
export interface State {
  /** 注文詳細情報 */
  order: Order
  /** 注文者情報 */
  customer: Customer
  /** 見積もり情報 */
  estimate: Estimate
  /** 注文ID */
  orderId: string
  /** レシート印刷済みかどうか */
  isPrinted: boolean
  isLoading: boolean
  errors: {
    saveOrder: ApiError | null
    completeOrder: ApiError | null
  }
}

export const initialState: State = {
  order: {
    postcardTypeId: '',
    printCount: 0,
    carryInCount: 0,
    directMailFlag: true
  },
  customer: {
    lastName: '',
    firstName: '',
    phoneNumber: '',
    postcode: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    address5: ''
  },
  estimate: {
    postcardFinishingType: '',
    postcardType: '',
    addressPrintingCount: '',
    printCount: '',
    printPrice: '',
    carryInCount: '',
    carryInPostcardPrice: '',
    totalPrice: '',
    addressPrintingPrice: '',
    postcardPrice: '',
    addPrintingPrice: ''
  },
  orderId: '',
  isPrinted: false,
  isLoading: false,
  errors: {
    saveOrder: null,
    completeOrder: null
  }
}
