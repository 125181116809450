import axios from 'axios';
import { apiURL } from '../common/';
/**
 * 編集中データ宛名組版PNG参照APIのパラメータ
 */

/**
 * 編集中データ宛名組版PNGファイルを参照する。
 */

export var getFileEditingPostcardAddress = function getFileEditingPostcardAddress(_ref) {
  var token = _ref.token,
      postcard_editing_record_id = _ref.postcard_editing_record_id,
      postcard_address_id = _ref.postcard_address_id;
  return axios.get("".concat(apiURL, "/api/v1/files/design/").concat(postcard_editing_record_id, "/type/postcard_address/").concat(postcard_address_id), {
    responseType: 'blob',
    headers: {
      Authorization: token
    }
  }).then(function (resp) {
    return resp.data;
  });
};