import { Action } from 'redux'

import FixedPhrase from '@/types/FixedPhrase'

export enum ActionTypes {
  LoadFixedPhrasesRequested = 'detail/LOAD_FIXED_PHRASES_REQUESTED',
  LoadFixedPhrasesSucceeded = 'detail/LOAD_FIXED_PHRASES_SUCCEEDED',
  LoadFixedPhrasesFailed = 'detail/LOAD_FIXED_PHRASES_FAILED',
  LoadEnd = 'detail/LOAD_END'
}

export type Actions =
  | LoadFixedPhrasesRequested
  | LoadFixedPhrasesSucceeded
  | LoadFixedPhrasesFailed

/**
 * 商品種別に対応する定型文をAPIから取得
 */
export interface LoadFixedPhrasesRequested extends Action {
  type: ActionTypes.LoadFixedPhrasesRequested
  payload: { productTypeId: string }
}
export interface LoadFixedPhrasesSucceeded extends Action {
  type: ActionTypes.LoadFixedPhrasesSucceeded
  payload: ReadonlyArray<FixedPhrase>
}
export interface LoadFixedPhrasesFailed extends Action {
  type: ActionTypes.LoadFixedPhrasesFailed
}
