interface LayoutType {
  /** レイアウトタイプ */
  layoutType: string
  /** レイアウト名 */
  label: string
  /** レイアウトID */
  id: number
  /** 縦かどうか */
  isVertical: boolean
  /** 個人かどうか */
  isPersonal: boolean
  /** ソート番号 */
  sortNumber: number
}

const LayoutType = {
  /**
   * APIレスポンスの「メタデータ: レイアウトタイプ」をフロント側で使う構造に変換する
   * @param raw APIレスポンスの「メタデータ: レイアウトタイプ」
   */
  fromRaw(raw: RawLayoutType): LayoutType {
    return {
      layoutType: raw.layout_type,
      label: raw.name,
      id: raw.value,
      isVertical: raw.is_vertical,
      isPersonal: raw.is_personal,
      sortNumber: raw.sort_number
    }
  }
}

export default LayoutType

export interface RawLayoutType {
  layout_type: string
  name: string
  value: number
  is_vertical: boolean
  is_personal: boolean
  sort_number: number
}
