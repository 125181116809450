import Photo from '@/types/Preview/Photo'
import Size from '@/types/Size'

import * as affine from './affine'

import { Point2D } from '@/types/Position'
import TransformRect from '@/types/TransformRect'
import { Vector2D } from '@/types/Vector'
/**
 * 矩形の位置・大きさ指定を目的とした値
 */
interface Transform {
  vector: Vector2D
  scale: number
}

/**
 * 矩形Aに矩形Bが内接し、中央揃えとなるレイアウト情報を返す
 * レイアウト情報のtop, left については矩形Aの左上座標を原点としたtop, left 値とする。
 */
export const getInscribedSquareLayout = (
  rectA: Size,
  rectB: Size
): Transform => {
  const scale =
    rectB.height / rectB.width > rectA.height / rectA.width
      ? rectA.height / rectB.height
      : rectA.width / rectB.width

  return {
    vector: [
      (rectA.width - rectB.width * scale) / 2,
      (rectA.height - rectB.height * scale) / 2
    ],
    scale
  }
}

/**
 * 矩形の左上を原点として、中心までの距離を示す
 *
 * @param w 矩形の幅
 * @param h 矩形の高さ
 * @param scale 矩形の拡大率
 * @param rotate 矩形の回転
 */
export const getCenterPosition = (
  w: number,
  h: number,
  scale: number,
  rotate: number
): Point2D => {
  return [
    (Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2)) / 2) *
      scale *
      Math.cos((Math.atan2(h, w) / (Math.PI / 180) + rotate) * (Math.PI / 180)),
    (Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2)) / 2) *
      scale *
      Math.sin((Math.atan2(h, w) / (Math.PI / 180) + rotate) * (Math.PI / 180))
  ]
}

/**
 * 矩形の座標・拡大率・角度を元にマトリックス値を返す
 *
 * @param rect 矩形の配置情報
 */
export const getMatrix = (rect: TransformRect, templateScale?: number) => {
  const scale = templateScale ? templateScale : 1

  const [cx, cy] = getCenterPosition(
    rect.width * scale,
    rect.height * scale,
    rect.scale,
    rect.rotate
  )

  return affine.combineAffineTransforms(
    affine.translate(-cx, -cy),
    affine.translate((rect.width / 2) * scale, (rect.height / 2) * scale),
    affine.translate(rect.x * scale, rect.y * scale),
    affine.rotate(rect.rotate),
    affine.scale(rect.scale * scale)
  )
}

/**
 * 矩形の座標・拡大率・角度からマトリックス値を取得し、css: transform で利用可能な形で返す
 *
 * @param rect 矩形の配置情報
 */
export const getCssMatrix = (rect: TransformRect, templateScale?: number) => {
  const matrix = getMatrix(rect, templateScale)

  return `matrix( ${affine.affineToCSSMatrix(matrix)})`
}

/**
 * widthとheightから導き出される四角形の対角線の長さを返す
 * @param w
 * @param h
 */
export const getDiagonalLength = (w: number, h: number): number => {
  return Math.abs(Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2)))
}

/**
 * widthとheightから導き出される四角形の対角線の角度を返す
 * @param w
 * @param h
 */
export const getDiagonalDegree = (w: number, h: number): number => {
  return Math.atan2(h, w) / (Math.PI / 180)
}

/**
 * 原点(o)を基準として、線の長さ(r)と角度(degree)から座標を返す
 * @param r
 * @param degree
 */
export const getCoordinate = (r: number, degree: number): Point2D => {
  const radian = (degree * Math.PI) / 180
  return [Math.cos(radian) * r, Math.sin(radian) * r]
}

/**
 * 矩形の左上を原点として、中心までの距離を示す
 *
 * @param w 矩形の幅
 * @param h 矩形の高さ
 * @param scale 矩形の拡大率
 * @param rotate 矩形の回転
 */
export const getOriginCenterPoint = (
  w: number,
  h: number,
  scale: number,
  rotate: number
): Point2D => {
  return [
    (Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2)) / 2) *
      scale *
      Math.cos((Math.atan2(h, w) / (Math.PI / 180) + rotate) * (Math.PI / 180)),
    (Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2)) / 2) *
      scale *
      Math.sin((Math.atan2(h, w) / (Math.PI / 180) + rotate) * (Math.PI / 180))
  ]
}

/**
 * 点0を始点とするベクトルAとベクトルB間の角度を求める。
 * @param o 原点座標
 * @param a ベクトルA
 * @param b ベクトルB
 */
export const getVectorsDegree = (
  o: Point2D,
  a: Point2D,
  b: Point2D
): number => {
  return (
    Math.atan2(a[1] - o[1], a[0] - o[0]) / (Math.PI / 180) -
    Math.atan2(b[1] - o[1], b[0] - o[0]) / (Math.PI / 180)
  )
}

/**
 * 画像のサイズを拾得する
 * @param image 画像パス
 */
export const getSize = (image: string) =>
  new Promise<{ width: number; height: number }>(resolve => {
    const img = new Image()

    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }

    img.src = image
  })
