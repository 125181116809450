import { Reducer } from 'redux'

import {
  Actions as TemplateDetailActions,
  ActionTypes as TemplateDetailActionTypes
} from '@/store/modules/templateDetail/actions'
import { Actions, ActionTypes } from './actions'
import { initialState, State, StepCategory } from './state'

import { Step, StepGroup } from '@/types/Step'

const reducer: Reducer<State, Actions | TemplateDetailActions> = (
  state = initialState,
  action
) => {
  /** 表示ステップをリセット */
  const resetStep = (stepCategory: StepCategory) => {
    return state[stepCategory].map(step => {
      if (!('children' in step)) {
        return {
          ...step,
          current: false
        }
      }

      return {
        ...step,
        current: false,
        position: -1,
        children: step.children.map(c => ({ ...c, current: false }))
      }
    })
  }

  /** 表示ステップを指定 */
  const updateStep = (
    stepCategory: StepCategory,
    stepId: string
  ): Array<Step | StepGroup> => {
    const steps: Array<Step | StepGroup> = resetStep(stepCategory)

    return steps.map(step => {
      const isCurrentStep = step.id === stepId

      if (!('children' in step)) {
        return {
          ...step,
          current: isCurrentStep,
          visited: isCurrentStep || step.visited
        }
      }

      const children: Step[] = step.children.map(c => {
        return c.id === stepId ? { ...c, current: true, visited: true } : c
      })

      const position: number = step.children.findIndex(c => {
        return c.id === stepId
      })

      return {
        ...step,
        current: step.id === stepId || position >= 0,
        visited: isCurrentStep || step.visited,
        position,
        children
      }
    })
  }

  switch (action.type) {
    case TemplateDetailActionTypes.LoadTemplateDetailSucceeded:
      const template = action.payload.response

      return {
        ...state,
        edit: state.edit.map(step => {
          // テンプレートに「写真枠」が無ければ写真編集ステップを非表示設定
          if (step.id === 'photo') {
            return template.photos.length ? step : { ...step, disabled: true }
          }

          // テンプレートに「あいさつ文・コメント枠」が無ければあいさつ文・コメント編集ステップを非表示設定
          if (step.id === 'message') {
            return template.messages.length ? step : { ...step, disabled: true }
          }

          return step
        })
      }
    case ActionTypes.Jump:
      return {
        ...state,
        [action.payload.stepCategory]: updateStep(
          action.payload.stepCategory,
          action.payload.stepId
        )
      }
    case ActionTypes.Visit:
      return {
        ...state,
        [action.payload.stepCategory]: updateStep(
          action.payload.stepCategory,
          action.payload.stepId
        )
      }
    case ActionTypes.InitEditingStep:
      return {
        ...state,
        edit: initialState.edit.map(step => {
          // テンプレートに写真枠がある場合のみ写真アップロードステップを表示
          if (step.id === 'photo') {
            return action.payload.hasPhoto
              ? {
                  ...step,
                  current: true
                }
              : {
                  ...step,
                  disabled: true
                }
          }

          // テンプレートに写真枠が無ければ差出人ステップを表示
          if (step.id === 'sender') {
            return {
              ...step,
              current: !action.payload.hasPhoto
            }
          }

          // テンプレートにあいさつ文・コメント枠が無ければあいさつ文・コメントステップを非表示
          if (step.id === 'message') {
            return {
              ...step,
              disabled: !action.payload.hasMessage
            }
          }

          return step
        })
      }
    default:
      return state
  }
}

export default reducer
