import { setLocale } from 'yup'

export const init = () => {
  setLocale({
    mixed: {
      default: '値が正しくありません',
      required: '必須です'
    },
    string: {
      max: '${max}文字以内で入力してください'
    }
  })
}
