import { call, put, takeLeading } from 'redux-saga/effects'

import * as postcardAddressEdit from '@/store/modules/postcardAddressEdit'
import * as postcardAddresses from '@/store/modules/postcardAddresses'
import * as sender from '@/store/modules/sender'

import {
  ActionTypes as AlertActionTypes,
  SetInitFailureAlert
} from '@/store/modules/alert/actions'
import { Actions, ActionTypes } from './actions'

import { fetchFontTypes } from '@kn/common/lib/api/fetchFontTypes'
import { getEnums } from '@kn/common/lib/api/getEnums'

import Color from '@/types/Constants/Color'
import LayoutType, { RawLayoutType } from '@/types/Constants/LayoutType'
import Prefix from '@/types/Constants/Prefix'
import Font from '@/types/Font'
import { Layout as PostcardAddressLayout } from '@/types/PostcardAddresses/Layout'
import { Layout } from '@/types/Sender'

import { sendApiError } from '@/utils/sentry'

const wait = (ms: number) =>
  new Promise(resolve => setTimeout(() => resolve(), ms))

function* fetchConstants() {
  try {
    const { enums } = yield call(getEnums)
    const { font_types } = yield call(fetchFontTypes)

    yield put<Actions>({
      type: ActionTypes.Set,
      payload: {
        fontColors: enums.Color.map(Color.fromRaw)
      }
    })

    yield put<Actions>({
      type: ActionTypes.Set,
      payload: {
        fontTypes: font_types.map(Font.fromRaw)
      }
    })

    yield put<postcardAddresses.Actions>({
      type: postcardAddresses.ActionTypes.SetConstants,
      payload: {
        layouts: enums.LayoutTypePostcardAddress.map(
          PostcardAddressLayout.fromRaw
        )
      }
    })

    yield put<postcardAddressEdit.Actions>({
      type: postcardAddressEdit.ActionTypes.SetConstants,
      payload: {
        corporateSuffix: enums.CorporateSuffix,
        individualSuffix: enums.IndividualSuffix
      }
    })

    const layoutTypes: Layout[] = enums.LayoutTypeSender.map(
      (type: RawLayoutType) => LayoutType.fromRaw(type)
    )
    yield put<sender.Actions>({
      type: sender.ActionTypes.SetConstants,
      payload: {
        locationalIdentities: enums.PrefixTypeTelephone.map(Prefix.fromRaw),
        personalIdentities: enums.PrefixTypeMailUrl.map(Prefix.fromRaw),
        // TODO: APIチームにメタデータ構造を相談終了後調整
        layouts: {
          private: layoutTypes.filter((type: any) => type.isPersonal),
          company: layoutTypes.filter((type: any) => !type.isPersonal)
        }
      }
    })
  } catch (error) {
    yield put<Actions>({
      type: ActionTypes.LoadError,
      payload: { error }
    })
    yield put<SetInitFailureAlert>({
      type: AlertActionTypes.SetInitFailureAlert
    })
    sendApiError(error)
  }
}

export function* sagas() {
  yield takeLeading(ActionTypes.Load, fetchConstants)
}
