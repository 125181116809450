import { Action } from 'redux'

import { EditingConfig } from '@/types/Config/EditingConfig'

export enum ActionTypes {
  UpdateToken = 'config/UPDATE_TOKEN',
  UpdateProductTypeId = 'config/UPDATE_PRODUCT_TYPE_ID',
  SetShopCode = 'config/SET_SHOP_CODE',
  FetchEditingConfigFailed = 'config/FETCH_EDITING_CONFIG_FAILED',
  FetchEditingConfigRequested = 'config/FETCH_EDITING_CONFIG_REQUESTED',
  FetchEditingConfigSucceeded = 'config/FETCH_EDITING_CONFIG_SUCCEEDED',
  LoadEnd = 'config/LOAD_END'
}

export type Actions =
  | UpdateToken
  | UpdateProductTypeId
  | SetShopCode
  | FetchEditingConfigFailed
  | FetchEditingConfigRequested
  | FetchEditingConfigSucceeded
  | LoadEnd

/**
 * トークンの更新
 */
export interface UpdateToken extends Action {
  type: ActionTypes.UpdateToken
  payload: string
}

/**
 * 商材種別の更新
 */
export interface UpdateProductTypeId extends Action {
  type: ActionTypes.UpdateProductTypeId
  payload: string
}

/**
 * 店舗コードを設定
 */
export interface SetShopCode extends Action {
  type: ActionTypes.SetShopCode
  payload: { shopCode: number }
}

/**
 * デザイン編集初期データ作成APIから
 * トークン, はがき編集データID, 編集開始日を取得
 */
export interface FetchEditingConfigRequested extends Action {
  type: ActionTypes.FetchEditingConfigRequested
  payload: { productTypeId: string }
}
export interface FetchEditingConfigSucceeded extends Action {
  type: ActionTypes.FetchEditingConfigSucceeded
  payload: EditingConfig
}
export interface FetchEditingConfigFailed extends Action {
  type: ActionTypes.FetchEditingConfigFailed
}
export interface LoadEnd extends Action {
  type: ActionTypes.LoadEnd
}
