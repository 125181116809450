import { FetchPostcardAddressesResponse } from '@kn/common/lib/api/fetchPostcardAddresses'
import Joint from './Joint'

/**
 * 宛名情報
 */
interface PostcardAddress {
  /** はがき宛名ID */
  id: string

  /** 顧客ID */
  customerId: string

  /** 印刷フラグ */
  printingFlag: boolean

  /** 個人/法人 */
  destinationType: 'private' | 'company'

  /** 宛名_姓 */
  lastName: string

  /** 宛名_名 */
  firstName: string

  /** 宛名姓カナ */
  lastNameRead: string

  /** 宛名名カナ */
  firstNameRead: string

  /** 宛名_敬称 */
  suffix: number | null

  /** 会社名 */
  company: string

  /** 部署名 */
  department1: string

  /** 部署名2 */
  department2: string

  /** 役職名 */
  officialPosition: string

  /** 郵便番号 */
  postcode: string

  /** 住所1 */
  address1: string

  /** 住所2 */
  address2: string

  /** 住所3 */
  address3: string

  /** 連名 */
  joints: Joint[]
}

/**
 * APIレスポンスの宛名情報をフロント側で使う構造に変換する
 * @param raw APIレスポンスの宛名情報
 */
const PostcardAddress = {
  fromRaw(raw: RawPostcardAddress): PostcardAddress {
    const joints = [1, 2, 3, 4, 5]
      .map(num => ({
        lastName: raw[`joint_name_${num}_last_name`],
        firstName: raw[`joint_name_${num}_first_name`],
        lastNameRead: raw[`joint_name_${num}_last_name_read`],
        firstNameRead: raw[`joint_name_${num}_first_name_read`],
        suffix: raw[`joint_name_${num}_suffix`],
        department1: raw[`joint_name_${num}_department_1`],
        department2: raw[`joint_name_${num}_department_2`],
        officialPosition: raw[`joint_name_${num}_official_position`]
      }))
      .filter(
        joint =>
          // 表示項目のなにかしらがあるものだけにする
          joint.lastName ||
          joint.firstName ||
          joint.suffix ||
          joint.department1 ||
          joint.department2 ||
          joint.officialPosition
      )
    return {
      id: raw.id,
      customerId: raw.customer_id,
      printingFlag: raw.printing_flag,
      destinationType: raw.destination_type === 0 ? 'private' : 'company',
      lastName: raw.last_name,
      firstName: raw.first_name,
      lastNameRead: raw.last_name_read,
      firstNameRead: raw.first_name_read,
      suffix: raw.suffix,
      company: raw.company,
      department1: raw.department_1,
      department2: raw.department_2,
      officialPosition: raw.official_position,
      postcode: raw.postcode,
      address1: raw.address_1,
      address2: raw.address_2,
      address3: raw.address_3,
      joints
    }
  },
  toRaw(update: PostcardAddress): RawPostcardAddress {
    const jointsWithArray = [0, 1, 2, 3, 4].map(i => {
      const joint = update.joints[i]
      return {
        [`joint_name_${i + 1}_last_name`]: joint ? joint.lastName : '',
        [`joint_name_${i + 1}_first_name`]: joint ? joint.firstName : '',
        [`joint_name_${i + 1}_last_name_read`]:
          joint && update.destinationType === 'company'
            ? joint.lastNameRead
            : '',
        [`joint_name_${i + 1}_first_name_read`]:
          joint && update.destinationType === 'company'
            ? joint.firstNameRead
            : '',
        [`joint_name_${i + 1}_suffix`]: joint ? joint.suffix : null,
        [`joint_name_${i + 1}_department_1`]:
          joint && update.destinationType === 'company'
            ? joint.department1
            : '',
        [`joint_name_${i + 1}_department_2`]:
          joint && update.destinationType === 'company'
            ? joint.department2
            : '',
        [`joint_name_${i + 1}_official_position`]:
          joint && update.destinationType === 'company'
            ? joint.officialPosition
            : ''
      }
    })
    const joints = Object.assign({}, ...jointsWithArray)

    return {
      id: update.id,
      customer_id: update.customerId,
      printing_flag: update.printingFlag,
      destination_type: update.destinationType === 'private' ? 0 : 1,
      last_name: update.lastName,
      first_name: update.firstName,
      last_name_read: update.lastNameRead,
      first_name_read: update.firstNameRead,
      suffix: update.suffix,
      company: update.destinationType === 'company' ? update.company : '',
      department_1:
        update.destinationType === 'company' ? update.department1 : '',
      department_2:
        update.destinationType === 'company' ? update.department2 : '',
      official_position:
        update.destinationType === 'company' ? update.officialPosition : '',
      postcode: update.postcode,
      address_1: update.address1,
      address_2: update.address2,
      address_3: update.address3,
      ...joints
    }
  }
}

type RawPostcardAddress = FetchPostcardAddressesResponse['postcard_addresses'][0]

export default PostcardAddress
