import { FetchPostcardTypesResponse } from '@kn/common/lib/api/fetchPostcardTypes'

/**
 * ハガキ種別情報
 */
interface PostcardType {
  /** はがき種別ID */
  id: string
  /** 商材種別ID */
  productTypeId: string
  /** ソート番号 */
  sortNumber: number
  /** デフォルトフラグ */
  isDefaultFlag: boolean
  /** 名前 */
  name: string
  /** 販売開始日時 */
  sellStartedAt: string
  /** 販売終了日時 */
  sellEndedAt: string
  /** ラボコード */
  labCode: string
}

/**
 * APIレスポンスのハガキ種別情報をフロント側で使う構造に変換する
 * @param raw APIレスポンスのハガキ種別情報
 */
const PostcardType = {
  fromRaw(raw: RawPostcardType): PostcardType {
    return {
      id: raw.id,
      productTypeId: raw.product_type_id,
      sortNumber: raw.sort_number,
      isDefaultFlag: raw.is_default_flag,
      name: raw.name,
      sellStartedAt: raw.sell_started_at,
      sellEndedAt: raw.sell_ended_at,
      labCode: raw.lab_code
    }
  }
}

type RawPostcardType = FetchPostcardTypesResponse['postcard_types'][0]

export default PostcardType
