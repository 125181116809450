import { Layout } from '@/types/Sender/Layout'

/**
 * 差出人のレイアウトタイプが適正なものか判断する。
 * 適正でない場合は適正なものを返す。
 */
export const getSenderLayoutType = (
  layouts: Layout[],
  selectedLayout: Layout
): number => {
  const isValidLayout =
    layouts
      .filter(l => l.isVertical === selectedLayout.isVertical)
      .filter(fl => fl.id === selectedLayout.id).length > 0

  if (isValidLayout) {
    return selectedLayout.id
  }

  const scopeLayouts = layouts.filter(
    l => l.isVertical === selectedLayout.isVertical
  )

  if (scopeLayouts.length) {
    return scopeLayouts[0].id
  }

  return 0
}
