import { Action } from 'redux'

import { FontStyle } from '@/types/FontStyle'
import { Layout, Sender } from '@/types/Sender'
import Size from '@/types/Size'

import TemplateDetail from '@/types/TemplateDetail'
import { State } from './state'

export enum ActionTypes {
  Clear = 'sender/CLEAR',
  ClearRequest = 'sender/CLEAR_REQUEST',
  ClearError = 'sender/CLEAR_ERROR',
  Set = 'sender/SET',
  LoadStart = 'sender/LOAD_START',
  LoadEnd = 'sender/LOAD_END',
  SetLayout = 'sender/SET_LAYOUT',
  SetError = 'sender/SET_ERROR',
  SetFont = 'sender/SET_FONT',
  PatchStyle = 'sender/PATCH_STYLE',
  SetImage = 'sender/SET_IMAGE',
  SetConstants = 'sender/SET_CONSTANTS',
  FetchImage = 'sender/FETCH_IMAGE'
}

export type Actions =
  | Clear
  | ClearRequest
  | ClearError
  | Set
  | SetLayout
  | PatchStyle
  | SetError
  | SetImage
  | SetFont
  | FetchImage
  | SetConstants
  | LoadStart
  | LoadEnd

export interface Clear extends Action {
  type: ActionTypes.Clear
  payload: TemplateDetail['sender']
}

export interface ClearRequest extends Action {
  type: ActionTypes.ClearRequest
}

export interface ClearError extends Action {
  type: ActionTypes.ClearError
}

export interface Set extends Action {
  type: ActionTypes.Set
  payload: Sender
}

export interface SetLayout extends Action {
  type: ActionTypes.SetLayout
  payload: Layout
}

export interface FetchImage extends Action {
  type: ActionTypes.FetchImage
}

export interface PatchStyle extends Action {
  type: ActionTypes.PatchStyle
  payload: Partial<FontStyle>
}

export interface SetFont extends Action {
  type: ActionTypes.SetFont
  payload: { fontId: string }
}

export interface SetError extends Action {
  type: ActionTypes.SetError
  payload: {
    status: number | string
    errorCode: string | null
  }
}

export interface SetImage extends Action {
  type: ActionTypes.SetImage
  payload: {
    uri: string
    size: Size
  }
}

export interface LoadStart extends Action {
  type: ActionTypes.LoadStart
}
export interface LoadEnd extends Action {
  type: ActionTypes.LoadEnd
}

export interface SetConstants extends Action {
  type: ActionTypes.SetConstants
  payload: State['constants']
}
