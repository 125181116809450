/**
 * 注文情報取得APIレスポンスのソート番号
 */
export enum EstimateSortNumber {
  /** 注⽂合計⾦額 */
  totalPrice = 0,
  /** 印刷代 */
  printPrice = 2,
  /** はがき代 */
  postcardPrice = 4,
  /** 宛名代 */
  addressPrintingPrice = 3,
  /** 仕上げ⽅法 */
  postcardFinishingType = 8,
  /** はがき種別 */
  postcardType = 9,
  /** 印刷枚数 */
  printCount = 10,
  /** 持ち込みはがき枚数 */
  carryInCount = 11,
  /** 持ち込みはがき代 */
  carryInPostcardPrice = 12,
  /** 宛名枚数 */
  addressPrintingCount = 14,
  /** 追加印刷代 */
  addPrintingPrice = 16
}
