import axios from 'axios';
import { apiURL } from '../common/';
/**
 * テンプレートファイル参照APIのパラメータ
 */

/**
 * テンプレートファイルを参照する。
 */

export var getFileTemplate = function getFileTemplate(_ref) {
  var template_id = _ref.template_id,
      type = _ref.type;
  return axios.get("".concat(apiURL, "/api/v1/files/template/").concat(template_id, "/type/").concat(type), {
    responseType: 'blob'
  }).then(function (resp) {
    return resp.data;
  });
};