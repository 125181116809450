declare global {
  interface External {
    /**
     * レシート印刷機能を呼び出す
     * @param token トークン
     * @param orderId 受注ID
     */
    ExecPrintReport(token: string, orderId: string): any
  }
}

/**
 * レシート印刷機能を呼び出す
 */
export const execPrintReport = (token: string, orderId: string) => {
  window.external.ExecPrintReport(token, orderId)
}

/**
 * レシート印刷機能の簡易モック
 */
export const mock = () => {
  if (!window.external) {
    ;(window.external as any) = {}
  }
  if (window.external.ExecPrintReport) {
    return
  }

  try {
    window.external.ExecPrintReport = (token, orderId) => {
      console.info(`order_id: ${orderId} のレシート印刷が実行されました`)
    }
  } catch (e) {
    // IE環境ではwindow.externalに代入ができないが、
    // 店頭端末等があるためモックは使用しない
  }
}
