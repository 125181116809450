import { Reducer } from 'redux'

import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

export const reducer: Reducer<State, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.Load:
      return {
        ...state,
        isLoading: true,
        loadError: undefined
      }
    case ActionTypes.LoadError:
      return {
        ...state,
        isLoading: false,
        loadError: action.payload.error
      }
    case ActionTypes.Set:
      return {
        ...state,
        isLoading: false,
        ...action.payload
      }
    default:
      return state
  }
}

export default reducer
