/**
 * 画面共通で使用するアラートを管理するステート
 */
export interface State {
  /** 初期化失敗アラート */
  initFailureAlert: {
    isVisible: boolean
  }
  /** 編集開始失敗アラート */
  editStartFailureAlert: {
    isVisible: boolean
  }
}

export const initialState: State = {
  initFailureAlert: {
    isVisible: false
  },
  editStartFailureAlert: {
    isVisible: false
  }
}
