// カラーテーマ
export interface Theme {
  colors: {
    default: string
    primary: string
    secondary: string
    tertiary: string
    bg: {
      column: string
    }
    modal: {
      uiArea: string
    }
  }
}

export interface ThemedProps {
  theme: Theme
}

const theme: Theme = {
  colors: {
    default: '#fff',
    primary: '#FB2525',
    secondary: '#50A40E',
    tertiary: '#737373',
    bg: {
      column: '#f2f2f2'
    },
    modal: {
      uiArea: '#ccc'
    }
  }
}

export default theme
