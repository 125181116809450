import axios from 'axios';
import { apiURL } from '../common/';
/**
 * 注文確定APIのレスポンス
 */

/**
 * 注文確定処理を行う。受注テーブル．受注ステータスを受注に更新する。
 */

export var updateOrders = function updateOrders(_ref) {
  var token = _ref.token,
      order_id = _ref.order_id;
  return axios.put("".concat(apiURL, "/api/v1/orders/").concat(order_id), undefined, {
    headers: {
      Authorization: token
    }
  }).then(function (resp) {
    return resp.data;
  });
};