import { push } from 'connected-react-router'
import { call, put, select, takeEvery } from 'redux-saga/effects'

import Config from '@/types/PostcardAddresses/Config'

import * as config from '@/store/modules/config'
import * as postcardAddresses from '@/store/modules/postcardAddresses'

import * as error from '@/utils/error'

import {
  ActionTypes,
  LoadEnd,
  LoadSigninRequested,
  LoadSigninSucceeded,
  SetError
} from './actions'

import {
  savePostcardAddressHeidenConf,
  SavePostcardAddressHeidenConfResponse
} from '@kn/common/lib/api/savePostcardAddressHeidenConf'

import { RootState } from '@/store'

import { CustomerResponse } from '@kn/common/lib/api/common/types/response/customer'
import { signin } from '@kn/common/lib/api/signin'

import User from '@/types/User'

import { sendApiError } from '@/utils/sentry'

/**
 * ログインしてユーザー情報を取得
 */
function* loadSignin(action: LoadSigninRequested) {
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )
  try {
    const resp: CustomerResponse = yield call(signin, {
      email: action.payload.email,
      password: action.payload.password,
      token
    })

    yield put<LoadSigninSucceeded>({
      type: ActionTypes.LoadSigninSucceeded,
      payload: User.fromRaw(resp)
    })

    const newToken = User.fromRaw(resp).token
    // tokenをconfigに登録
    yield put<config.Actions>({
      type: config.ActionTypes.UpdateToken,
      payload: newToken
    })

    // ログイン時に宛名設定を初期化
    const INITIAL_FONT_ID = '905e7911-f0f9-4889-8c2a-6a42c59c432e'
    const INITIAL_LAYOUT_TYPE = 0

    const configResp: SavePostcardAddressHeidenConfResponse = yield call(
      savePostcardAddressHeidenConf,
      {
        token: newToken,
        font_id: INITIAL_FONT_ID,
        layout_type: INITIAL_LAYOUT_TYPE
      }
    )

    yield put<postcardAddresses.UpdateConfigSucceeded>({
      type: postcardAddresses.ActionTypes.UpdateConfigSucceeded,
      payload: Config.fromRaw(configResp.postcard_address_heiden_configuration)
    })

    if (action.payload.redirectPath) {
      yield put(push(action.payload.redirectPath))
    }
  } catch (e) {
    yield put<SetError>({
      type: ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e)
      }
    })
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * ログインユーザーの取得について非同期処理を行うアクションを監視する
 */
export function* sagas() {
  yield takeEvery(ActionTypes.LoadSigninRequested, loadSignin)
}
