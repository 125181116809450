import { ApiError } from '@/types/Error/ApiError'
import Photo from '@/types/Photo'

export interface State {
  items: Photo[]
  isLoading: boolean
  selectedFrame: number
  selectedPhoto: Photo | null
  error: ApiError | null
}

export const initialState: State = {
  items: [],
  isLoading: false,
  error: null,
  selectedFrame: -1,
  selectedPhoto: null
}
