import { ActionTypes, ClearError, LoadSigninRequested } from './actions'

/**
 * ログインしてユーザー情報を取得する
 */
export const signinRequested = (
  email: string,
  password: string,
  redirectPath?: string
): LoadSigninRequested => ({
  type: ActionTypes.LoadSigninRequested,
  payload: {
    email,
    password,
    redirectPath
  }
})

/**
 * エラー
 */
export const clearError = (): ClearError => ({
  type: ActionTypes.ClearError
})
