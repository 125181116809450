/**
 * 店頭端末タブレット上のスクリーンキーボード(ソフトウェアキーボード)の
 * 型定義と、それがない環境上でのダミーを提供するモジュール
 */

declare global {
  interface External {
    /**
     * 日本語入力用キーボードを表示する
     * @returns 入力結果
     */
    ShowKanaKeyboard(initialValue: string, canLineFeed: boolean): string

    /**
     * 住所入力用キーボードを表示する
     * @returns 入力結果
     */
    ShowKanaKeyboard2(initialValue: string, canLineFeed: boolean): string

    /**
     * 数字入力用キーボードを表示する
     * @returns 入力結果
     */
    ShowNumKeyboard(initialValue: string, canLineFeed: boolean): string

    /**
     * 電話番号入力用キーボードを表示する
     * @returns 入力結果
     */
    ShowPhoneKeyboard(initialValue: string): string

    /**
     * 郵便番号入力用キーボードを表示する
     * @returns 入力結果
     */
    ShowPostCodeKeyboard(initialValue: string): string

    /**
     * 英字入力用キーボードを表示する
     * @returns 入力結果
     */
    ShowAlphabetKeyboard(initialValue: string, canLineFeed: boolean): string

    /**
     * タイトルを指定できる日本語入力用キーボードを表示する
     * @returns 入力結果
     */
    ShowKanaKeyboardForFreeWord(
      initialValue: string,
      canLineFeed: boolean,
      title: string
    ): string
  }
}

const screenKeyboard = (type: string) => (initialValue: string): string => {
  const ret = window.prompt(
    `スクリーンキーボード(method=${type})`,
    initialValue
  )

  if (ret === null) {
    return initialValue
  }

  return ret
}

/**
 * 店頭端末以外の環境向けの簡易スクリーンキーボードを作成する。
 * すでに該当のメソッドがwindow.externalにぶら下がっている場合は何もしない。
 */
export const mock = () => {
  // window.externalはdeprecatedなため、すでに
  // 取り除いているブラウザでエラーが出ないようにする
  if (!window.external) {
    ;(window.external as any) = {}
  }

  /**
   * window.externalに指定された名前のメソッドがぶらさがっているかどうか
   * @param name メソッド名
   */
  const isDefined = (name: string) =>
    name in window.external && typeof window.external[name] === 'function'

  const methods = [
    'ShowKanaKeyboard',
    'ShowKanaKeyboard2',
    'ShowNumKeyboard',
    'ShowPhoneKeyboard',
    'ShowPostCodeKeyboard',
    'ShowAlphabetKeyboard',
    'ShowKanaKeyboardForFreeWord'
  ]

  try {
    methods.forEach(methodName => {
      if (!isDefined(methodName)) {
        window.external[methodName] = screenKeyboard(methodName)
      }
    })
  } catch (e) {
    // IE環境ではwindow.externalに代入ができないが、
    // 店頭端末等があるためモックは使用しない
  }
}
