import { Action } from 'redux'

import { PostcardAddressesError } from '@/types/Error/PostcardAddressesError'
import PostcardAddress from '@/types/PostcardAddresses/PostcardAddress'

import { State } from './state'

export enum ActionTypes {
  AddPostcardAddressRequested = 'postcardAddressEdit/ADD_POSTCARD_ADDRESS_REQUESTED',
  LoadPostcardAddressRequested = 'postcardAddressEdit/LOAD_POSTCARD_ADDRESS_REQUESTED',
  LoadPostcardAddressSucceeded = 'postcardAddressEdit/LOAD_POSTCARD_ADDRESS_SUCCEEDED',
  LoadEnd = 'postcardAddressEdit/LOAD_END',
  ResetAddress = 'postcardAddressEdit/RESET_ADDRESS',
  UpdatePostcardAddressRequested = 'postcardAddressEdit/UPDATE_POSTCARD_ADDRESS_REQUESTED',
  SetConstants = 'postcardAddressEdit/SET_CONSTANTS',
  SetError = 'postcardAddressEdit/SET_ERROR',
  ClearError = 'postcardAddressEdit/CLEAR_ERROR'
}

export type Actions =
  | AddPostcardAddressRequested
  | LoadEnd
  | LoadPostcardAddressRequested
  | LoadPostcardAddressSucceeded
  | ResetAddress
  | UpdatePostcardAddressRequested
  | SetConstants
  | SetError
  | ClearError

/**
 * 宛名情報を読み込む
 */
export interface LoadPostcardAddressRequested extends Action {
  type: ActionTypes.LoadPostcardAddressRequested
  payload: {
    id: string
  }
}
export interface LoadPostcardAddressSucceeded extends Action {
  type: ActionTypes.LoadPostcardAddressSucceeded
  payload: PostcardAddress
}
export interface LoadEnd extends Action {
  type: ActionTypes.LoadEnd
}

/**
 * 宛名を追加
 */
export interface AddPostcardAddressRequested extends Action {
  type: ActionTypes.AddPostcardAddressRequested
  payload: {
    address: PostcardAddress
  }
}

/**
 * 宛名を更新
 */
export interface UpdatePostcardAddressRequested extends Action {
  type: ActionTypes.UpdatePostcardAddressRequested
  payload: {
    address: PostcardAddress
    redirectPath: string
  }
}

/**
 * 宛名情報を初期化
 */
export interface ResetAddress extends Action {
  type: ActionTypes.ResetAddress
}

export interface SetConstants extends Action {
  type: ActionTypes.SetConstants
  payload: State['constants']
}

/**
 * エラー
 */
export interface SetError extends Action {
  type: ActionTypes.SetError
  payload: PostcardAddressesError
}

export interface ClearError extends Action {
  type: ActionTypes.ClearError
}
