import { PostcardAddressesError } from '@/types/Error/PostcardAddressesError'
import PostcardAddress from '@/types/PostcardAddresses/PostcardAddress'
import Suffix from '@/types/PostcardAddresses/Suffix'

/**
 * APIから取得した宛名編集を管理するステート
 */
export interface State {
  /** ローディング中かどうか */
  isLoading: boolean
  /** 編集中の宛名 */
  address: PostcardAddress
  /** 宛名関連の定数 */
  constants: {
    corporateSuffix: Suffix[]
    individualSuffix: Suffix[]
  }
  error: PostcardAddressesError | null
}

export const initialState: State = {
  isLoading: false,
  address: {
    id: '',
    customerId: '',
    printingFlag: true,
    destinationType: 'private',
    lastName: '',
    firstName: '',
    lastNameRead: '',
    firstNameRead: '',
    suffix: 1,
    company: '',
    department1: '',
    department2: '',
    officialPosition: '',
    postcode: '',
    address1: '',
    address2: '',
    address3: '',
    joints: []
  },
  constants: {
    corporateSuffix: [],
    individualSuffix: []
  },
  error: null
}
