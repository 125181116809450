import { Reducer } from 'redux'

import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

export const reducer: Reducer<State, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.SetPhotos:
      return {
        ...state,
        items: [
          ...action.payload,
          ...state.items.filter(
            stateItem => !action.payload.find(item => stateItem.id === item.id)
          )
        ]
      }
    case ActionTypes.LoadThumbnailRequested:
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.LoadThumbnailSucceeded:
      return {
        ...state,
        items: [
          ...state.items.map(item =>
            item.id === action.payload.id
              ? {
                  ...item,
                  ...action.payload
                }
              : item
          )
        ]
      }
    case ActionTypes.LoadEnd:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.RemovePhoto:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload.id),
        selectedPhoto: null,
        selectedFrame: -1
      }
    case ActionTypes.UpdateFrame:
      return {
        ...state,
        selectedFrame: action.payload.slot
      }
    case ActionTypes.SelectPhoto:
      return {
        ...state,
        selectedPhoto: action.payload
      }
    case ActionTypes.ClearSelectedPhoto:
      return {
        ...state,
        selectedPhoto: null
      }
    case ActionTypes.StartEditing:
      return {
        ...state,
        selectedPhoto:
          state.selectedPhoto ||
          state.items.find(item => item.id === action.payload.imageId) ||
          null,
        selectedFrame: action.payload.slot
      }
    case ActionTypes.SetError:
      return {
        ...state,
        error: {
          status: action.payload.status,
          errorCode: action.payload.errorCode
        }
      }
    case ActionTypes.ClearError:
      return {
        ...state,
        error: null
      }
    default:
      return state
  }
}

export default reducer
