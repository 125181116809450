import { MessageError } from '@/types/Error/MessageError'
import Message from '@/types/Message'

export interface State {
  items: Message[]
  editingSlot: number
  isLoading: boolean
  errors: MessageError[]
}

export const initialState: State = {
  items: [],
  editingSlot: -1,
  isLoading: false,
  errors: []
}
