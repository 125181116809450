import { Action } from 'redux'

import TemplateDetail from '@/types/TemplateDetail'

export enum ActionTypes {
  LoadTemplateDetailRequested = 'detail/LOAD_TEMPLATE_DETAIL_REQUESTED',
  LoadTemplateDetailSucceeded = 'detail/LOAD_TEMPLATE_DETAIL_SUCCEEDED',
  LoadTemplateDetailFailed = 'detail/LOAD_TEMPLATE_DETAIL_FAILED',
  LoadEnd = 'detail/LOAD_END'
}

export type Actions =
  | LoadTemplateDetailRequested
  | LoadTemplateDetailSucceeded
  | LoadTemplateDetailFailed
  | LoadEnd

/**
 * テンプレート詳細情報をAPIから取得
 */
export interface LoadTemplateDetailRequested extends Action {
  type: ActionTypes.LoadTemplateDetailRequested
  payload: string
}
export interface LoadTemplateDetailSucceeded extends Action {
  type: ActionTypes.LoadTemplateDetailSucceeded
  payload: {
    response: TemplateDetail
    image: string
  }
}
export interface LoadTemplateDetailFailed extends Action {
  type: ActionTypes.LoadTemplateDetailFailed
}

export interface LoadEnd extends Action {
  type: ActionTypes.LoadEnd
}
