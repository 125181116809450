import React, { SFC } from 'react'

import styled, { keyframes } from 'styled-components'

import Overlay from '@/components/Overlays/Overlay'

export interface Props {
  className?: string
  /** 表示・非表示 */
  visible: boolean
  /** 全画面にするかどうか */
  fullscreen?: boolean
}

export const Loading: SFC<Props> = ({
  className,
  visible,
  fullscreen = false
}) => (
  <Background className={className} visible={visible} fullscreen={fullscreen}>
    <LoadingSpinner>
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </LoadingSpinner>
  </Background>
)

export default Loading

interface BackgroundProps {
  fullscreen: boolean
}
const Background = styled(Overlay)`
  position: ${(p: BackgroundProps) => (p.fullscreen ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  width: 100%;
  height: 100%;
`

const animation1 = keyframes`
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
`
const animation2 = keyframes`
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(38px, 0);
  }
`
const animation3 = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
`

const Dot = styled.div`
  position: absolute;
  top: 54px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: 12px;
    animation: ${animation1} 0.9s infinite;
  }
  &:nth-child(2) {
    left: 12px;
    animation: ${animation2} 0.9s infinite;
  }
  &:nth-child(3) {
    left: 52px;
    animation: ${animation2} 0.9s infinite;
  }
  &:nth-child(4) {
    left: 90px;
    animation: ${animation3} 0.9s infinite;
  }
`

const LoadingSpinner = styled.div`
  display: inline-block;
  position: relative;
  width: 128px;
  height: 128px;
`
