import { push } from 'connected-react-router'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import * as postcardAddresses from '@/store/modules/postcardAddresses'
import * as postcardAddressImages from '@/store/modules/postcardAddressImages'

import * as error from '@/utils/error'

import {
  getPostcardAddress,
  GetPostcardAddressResponse
} from '@kn/common/lib/api/getPostcardAddress'
import {
  savePostcardAddresses,
  SavePostcardAddressesResponse
} from '@kn/common/lib/api/savePostcardAddresses'
import {
  updatePostcardAddress,
  UpdatePostcardAddressResponse
} from '@kn/common/lib/api/updatePostcardAddress'

import {
  ActionTypes,
  AddPostcardAddressRequested,
  LoadEnd,
  LoadPostcardAddressRequested,
  LoadPostcardAddressSucceeded,
  ResetAddress,
  SetError,
  UpdatePostcardAddressRequested
} from './actions'

import Image from '@/types/PostcardAddresses/Image'
import PostcardAddress from '@/types/PostcardAddresses/PostcardAddress'

import { RootState } from '@/store'

import { sendApiError } from '@/utils/sentry'

/**
 * 宛名詳細をAPIから取得
 */
function* loadPostcardAddress(action: LoadPostcardAddressRequested) {
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )

  try {
    const resp: GetPostcardAddressResponse = yield call(getPostcardAddress, {
      token,
      postcard_address_id: action.payload.id
    })

    yield put<LoadPostcardAddressSucceeded>({
      type: ActionTypes.LoadPostcardAddressSucceeded,
      payload: PostcardAddress.fromRaw(resp.postcard_addresses)
    })
  } catch (e) {
    yield put<postcardAddresses.SetError>({
      type: postcardAddresses.ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e),
        title: '編集情報の取得に失敗しました。',
        text: 'お手数ですが再度一覧より選択してください。'
      }
    })
    yield put(push('/address/list'))
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * 宛名を追加
 */
function* addPostcardAddress(action: AddPostcardAddressRequested) {
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )
  try {
    const resp: SavePostcardAddressesResponse = yield call(
      savePostcardAddresses,
      {
        token,
        ...PostcardAddress.toRaw(action.payload.address)
      }
    )

    yield put<ResetAddress>({
      type: ActionTypes.ResetAddress
    })
    yield put(push('/address/list'))
  } catch (e) {
    yield put<SetError>({
      type: ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e),
        title: '処理に失敗しました。',
        text: 'お手数ですが再操作をお願いします。'
      }
    })
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * 宛名を更新
 */
function* editPostcardAddress(action: UpdatePostcardAddressRequested) {
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )
  try {
    const resp: UpdatePostcardAddressResponse = yield call(
      updatePostcardAddress,
      {
        token,
        postcard_address_id: action.payload.address.id,
        ...PostcardAddress.toRaw(action.payload.address)
      }
    )

    // プレビュー画像があれば削除
    const images: Image[] = yield select(
      (state: RootState) => state.postcardAddressImages.images
    )
    const currentImage = images.find(
      img => img.id === action.payload.address.id
    )
    if (currentImage && currentImage.url) {
      URL.revokeObjectURL(currentImage.url)
    }
    yield put<postcardAddressImages.RemoveImages>({
      type: postcardAddressImages.ActionTypes.RemoveImages,
      payload: [{ id: action.payload.address.id }]
    })

    yield put<ResetAddress>({
      type: ActionTypes.ResetAddress
    })
    yield put(push(action.payload.redirectPath))
  } catch (e) {
    yield put<SetError>({
      type: ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e),
        title: '処理に失敗しました。',
        text: 'お手数ですが再操作をお願いします。'
      }
    })
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * 宛名編集ストア上の非同期処理を行うアクションを監視する
 */
export function* sagas() {
  yield takeLatest(
    ActionTypes.LoadPostcardAddressRequested,
    loadPostcardAddress
  )
  yield takeLatest(ActionTypes.AddPostcardAddressRequested, addPostcardAddress)
  yield takeLatest(
    ActionTypes.UpdatePostcardAddressRequested,
    editPostcardAddress
  )
}
