import Color from '@/types/Constants/Color'
import Font from '@/types/Font'

export interface State {
  isLoading: boolean
  loadError?: Error

  fontColors: Color[]
  fontTypes: Font[]
}

export const initialState: State = {
  isLoading: false,
  fontColors: [],
  fontTypes: []
}
