import axios from 'axios';
import { apiURL } from '../common/';
/**
 * デザイン面編集時にデザインに関する情報を返す。
 */

export var fetchTemplates = function fetchTemplates(params) {
  return axios.get("".concat(apiURL, "/api/v1/templates"), {
    params: params
  }).then(function (resp) {
    return resp.data;
  });
};