import { Action } from 'redux'

import { ApiError } from '@/types/Error/ApiError'

export enum ActionTypes {
  FetchQrCodeFailed = 'upload/FETCH_QR_CODE_FAILED',
  FetchQrCodeRequested = 'upload/FETCH_QR_CODE_REQUESTED',
  FetchQrCodeSucceeded = 'upload/FETCH_QR_CODE_SUCCEEDED',
  SetGroupIds = 'upload/SET_GROUP_IDS',
  SetPhotoCount = 'upload/SET_PHOTO_COUNT',
  LoadEnd = 'upload/LOAD_END'
}

export type Actions =
  | FetchQrCodeFailed
  | FetchQrCodeRequested
  | FetchQrCodeSucceeded
  | SetGroupIds
  | SetPhotoCount
  | LoadEnd

/**
 * モバイルでの写真アップロード用のQRコードを取得
 */
export interface FetchQrCodeRequested extends Action {
  type: ActionTypes.FetchQrCodeRequested
}
export interface FetchQrCodeSucceeded extends Action {
  type: ActionTypes.FetchQrCodeSucceeded
  payload: string
}
export interface FetchQrCodeFailed extends Action {
  type: ActionTypes.FetchQrCodeFailed
  payload: ApiError
}

export interface SetGroupIds extends Action {
  type: ActionTypes.SetGroupIds
  payload: string
}

export interface SetPhotoCount extends Action {
  type: ActionTypes.SetPhotoCount
  payload: number
}

export interface LoadEnd extends Action {
  type: ActionTypes.LoadEnd
}
