import Image from '@/types/PostcardAddresses/Image'

/**
 * APIから取得した宛名一覧を管理するステート
 */
export interface State {
  /**
   * 組版に登録した画像リスト
   *
   * 更新が必要な画像はimagesから削除
   * AddressPreview Componentで画像が見つからなかった際に組版apiを叩いて画像を生成する
   */
  images: Image[]
}

export const initialState: State = {
  images: []
}
