import { LayoutJsonRequest } from '@kn/common/lib/api/common/types/request/layoutJson'

import { RootState } from '@/store'

import { getSenderHeidenParams } from './getSenderHeidenParams'
import { initialSenderParams } from './initialSenderParams'

import Color from '@/types/Constants/Color'
import Photo from '@/types/Preview/Photo'
import Text from '@/types/Preview/Text'

/**
 * リクエスト用に使用する編集データ
 */
export const getLayoutJson = (state: RootState) => {
  const {
    config,
    templateDetail: { response: templateDetail },
    messages: messageOptions,
    sender: senderOption,
    preview,
    constants: { fontColors },
    photos
  } = state

  const senderColor = fontColors.find(
    (c: Color) => c.color === senderOption.style.color
  )
  // 差出人情報
  const sender = {
    // Note: 差出人画像がなかった場合、印刷の意思が無いとみなす。
    //       差出人を印刷しない場合、入力情報は全てnullで送る。
    ...(senderOption.image
      ? getSenderHeidenParams(state).data
      : initialSenderParams),
    ...Text.toRaw(preview.sender),
    text_x: preview.sender.x + preview.sender.width / 2,
    text_y: preview.sender.y + preview.sender.height / 2,
    color_code: senderColor ? senderColor.id : 0,
    font_id: senderOption.style.fontId,
    border_flag: senderOption.style.hasBorder,
    layout_type: (senderOption.layout && senderOption.layout.id) || 0,
    sender_form_version: 2
  }

  // あいさつ文・コメント情報
  const messages = preview.messages.map(m => {
    const targetMessage = messageOptions.items.find(
      mo => mo.textFormType === m.textFormType
    )
    // Note: あいさつ文・コメント画像がなかった場合、印刷の意思が無いとみなす。
    //       あいさつ文・コメントを印刷しない場合、textはnullで送る。
    if (!targetMessage || !targetMessage.image) {
      return {
        ...Text.toRaw(m),
        text: null,
        color_code: 0,
        font_id:
          (targetMessage && targetMessage.style.fontId) ||
          '0c65dcf4-e148-47e9-b84b-e78e47205b44',
        border_flag: false,
        direction: 0,
        alignment: 0
      }
    }

    const messageColor = fontColors.find(
      (c: Color) => c.color === targetMessage.style.color
    )
    const messageAlignment = targetMessage.layout.alignment
    return {
      ...Text.toRaw(m),
      text: targetMessage.text,
      text_x: m.x + m.width / 2,
      text_y: m.y + m.height / 2,
      color_code: messageColor ? messageColor.id : 0,
      font_id: targetMessage.style.fontId,
      border_flag: targetMessage.style.hasBorder,
      direction: targetMessage.layout.isVertical ? 0 : 1,
      alignment:
        messageAlignment === 'start' ? 0 : messageAlignment === 'center' ? 1 : 2
    }
  })

  const editedPhotos = preview.photos.map(item => {
    return {
      ...Photo.toRaw(item),
      image_x: item.x + item.width / 2,
      image_y: item.y + item.height / 2
    }
  })

  return {
    postcard_design_id: templateDetail.postcardDesignId,
    design_code: templateDetail.designCode,
    postcard_finishing_type: preview.finished === 'print' ? 0 : 1,
    product_type_id: templateDetail.productTypeId,
    postcard_design_category_id: templateDetail.postcardDesignCategoryId,
    frame_count: templateDetail.frameCount,
    direction: templateDetail.direction === 'vertical' ? 0 : 1,
    star_rating: templateDetail.starRating,
    postcard_editing_record_id: config.postcardEditingRecordId,
    master_order_id: templateDetail.masterOrderId,
    postcard_editing_date: config.editStartedAt,
    size: templateDetail.size,
    texts: [sender, ...messages],
    uploaded_images: photos.items.map(item => item.id),
    photos: editedPhotos
  }
}
