import { Action } from 'redux'

export enum ActionTypes {
  SetPage = 'postcardAddressConfirm/SET_PAGE',
  SetEnableSubmit = 'postcardAddressConfirm/SET_ENABLE_SUBMIT'
}

export type Actions = SetPage | SetEnableSubmit

/**
 * ページの変更
 */
export interface SetPage extends Action {
  type: ActionTypes.SetPage
  payload: {
    page: number
  }
}

/**
 * 送信可能フラグ変更
 */
export interface SetEnableSubmit extends Action {
  type: ActionTypes.SetEnableSubmit
  payload: {
    enableSubmit: boolean
  }
}
