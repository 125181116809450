import { TextResponse } from '@kn/common/lib/api/common/types/response/text'

import Color from '@/types/Constants/Color'
import { Alignment } from '../Message/Alignment'

/**
 * 文字枠情報
 */
interface Text {
  /** 入力テキスト */
  text: string
  /** 文字列フォームタイプ */
  textFormType: string
  /** 文字色コード */
  colorCode: string
  /** 文字フォントID */
  fontId: string
  /** 文字白フチフラグ */
  borderFlag: boolean
  /** 縦書き・横書きを指定 */
  direction: 'vertical' | 'horizontal'
  /** 文字アライメント */
  alignment: Alignment
  /** 文字枠X座標 */
  frameX: number
  /** 文字枠Y座標 */
  frameY: number
  /** 文字枠横幅 */
  frameWidth: number
  /** 文字枠縦幅 */
  frameHeight: number
  /** レイアウトタイプ */
  layoutType?: number
  /** 電話番号表記のデフォルトID */
  telephoneFormId?: number[]
  /** 追加電話番号表記のデフォルトID */
  additionalTelephoneFormId?: number[]
}

/**
 * APIレスポンスのテンプレート詳細の文字枠情報をフロント側で使う構造に変換する
 * @param raw APIレスポンスのテンプレート詳細の文字枠情報
 */
const Text = {
  fromRaw(raw: RawText, fontColors: Color[]): Text {
    const initColor = fontColors.find(c => c.id === raw.color_code)

    return {
      text: raw.text,
      textFormType: raw.text_form_type,
      colorCode: initColor ? initColor.color : '#000000',
      fontId: raw.font_id,
      borderFlag: raw.border_flag,
      direction: raw.direction === 0 ? 'vertical' : 'horizontal',
      alignment:
        raw.alignment === 0 ? 'start' : raw.alignment === 1 ? 'center' : 'end',
      frameX: raw.frame_x,
      frameY: raw.frame_y,
      frameWidth: raw.frame_width,
      frameHeight: raw.frame_height,
      layoutType: raw.layout_type,
      telephoneFormId: [
        raw.telephone_prefix1_1,
        raw.telephone_prefix2_1,
        raw.telephone_prefix3_1
      ],
      additionalTelephoneFormId: [
        raw.telephone_prefix1_2,
        raw.telephone_prefix2_2
      ]
    }
  }
}

export default Text

type RawText = TextResponse
