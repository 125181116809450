import { MessageTemplateResponse } from '@kn/common/lib/api/common/types/response/messageTemplate'

/** あいさつ文・コメント定型文 */
interface FixedPhrase {
  /** 定型文ID */
  id: string
  /** 定型文 */
  message: string
  /** カタログ番号 */
  flyerNumber?: string
}

export const FixedPhrase = {
  /**
   * APIレスポンスの「定型文」をフロント側で使う構造に変換する
   * @param raw APIレスポンスの「定型文」
   */
  fromRaw(raw: MessageTemplateResponse): FixedPhrase {
    return {
      id: raw.id,
      message: raw.message,
      flyerNumber: raw.flyer_number
    }
  }
}

export default FixedPhrase
