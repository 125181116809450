import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects'

import { getFilePhoto } from '@kn/common/lib/api/getFilePhoto'
import {
  getPhotoGroupsFileIds,
  GetPhotoGroupsFileIdsResponse
} from '@kn/common/lib/api/getPhotoGroupsFileIds'

import {
  ActionTypes as UploadActionTypes,
  SetPhotoCount
} from '@/store/modules/upload/actions'
import {
  ActionTypes,
  ClearError,
  FetchGroupImageIdsRequested,
  LoadEnd,
  LoadThumbnailRequested,
  LoadThumbnailSucceeded,
  RemovePhoto,
  SetError,
  SetPhotos
} from './actions'

import * as error from '@/utils/error'

import { Thumbnail } from '@/components/Buttons/PanelButton'
import PhotoTypeNumber from '@/types/PhotoTypeNumber'

import { RootState } from '@/store'

import { getSize } from '@/utils/image'
import { sendApiError } from '@/utils/sentry'

function* loadUploadedImage(action: LoadThumbnailRequested) {
  yield put<ClearError>({
    type: ActionTypes.ClearError
  })

  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )

  const { items }: RootState['photos'] = yield select(
    (state: RootState) => state.photos
  )

  const { type, id } = action.payload
  const currentItem = items.find(item => item.id === id)

  try {
    const newImage = yield call(getFilePhoto, {
      photo_id: id,
      type: `${type}`,
      token
    })
    const typeName = PhotoTypeNumber[type]
    if (currentItem && currentItem[typeName]) {
      URL.revokeObjectURL(currentItem[typeName])
    }
    const path = URL.createObjectURL(newImage)

    const photo =
      type === PhotoTypeNumber.thumbnail
        ? {
            id,
            [typeName]: path
          }
        : {
            id,
            [typeName]: path,
            compositionSize: yield currentItem && currentItem.compositionSize
              ? currentItem.compositionSize
              : yield getSize(path)
          }

    yield put<LoadThumbnailSucceeded>({
      type: ActionTypes.LoadThumbnailSucceeded,
      payload: photo
    })
  } catch (e) {
    yield put<SetError>({
      type: ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e)
      }
    })
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

function* freeThumbnail(action: RemovePhoto) {
  if (action.payload.thumbnail) {
    yield URL.revokeObjectURL(action.payload.thumbnail)
  }
}

function* fetchGroupImageIds() {
  const { token, postcardEditingRecordId }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )
  const { photoGroupId }: RootState['upload'] = yield select(
    (state: RootState) => state.upload
  )
  try {
    const { photo_groups: photo }: GetPhotoGroupsFileIdsResponse = yield call(
      getPhotoGroupsFileIds,
      {
        token,
        design_id: postcardEditingRecordId,
        code: photoGroupId
      }
    )

    yield put<SetPhotoCount>({
      type: UploadActionTypes.SetPhotoCount,
      payload: photo.length
    })

    yield put<SetPhotos>({
      type: ActionTypes.SetPhotos,
      payload: photo.map(item => {
        return { id: item.picture_id }
      })
    })

    yield photo.forEach(item =>
      fork(loadUploadedImage, {
        type: ActionTypes.LoadThumbnailRequested,
        payload: {
          id: item.picture_id,
          type: PhotoTypeNumber.thumbnail
        }
      })
    )
  } catch (e) {
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

export function* sagas() {
  yield takeEvery(ActionTypes.LoadThumbnailRequested, loadUploadedImage)
  yield takeEvery(ActionTypes.RemovePhoto, freeThumbnail)
  yield takeEvery(ActionTypes.FetchGroupImageIdsRequested, fetchGroupImageIds)
}
