import { Step, StepGroup } from '@/types/Step'

export interface State {
  /** 編集ステップ */
  edit: Array<Step | StepGroup>
  /** 注文ステップ */
  order: Array<Step | StepGroup>
}

export type StepCategory = 'edit' | 'order'

export const initialState: State = {
  edit: [
    {
      id: 'photo',
      label: '写真選択',
      visited: false,
      disabled: false,
      current: false
    },
    {
      id: 'sender',
      label: '差出人入力',
      visited: false,
      disabled: false,
      current: false,
      position: -1,
      children: [
        {
          id: 'senderInput',
          label: '差出人情報入力',
          visited: false,
          disabled: false,
          current: false
        },
        {
          id: 'senderTypesetting',
          label: '縦横方向選択',
          visited: false,
          disabled: false,
          current: false
        },
        {
          id: 'senderLayout',
          label: 'レイアウト調整',
          visited: false,
          disabled: false,
          current: false
        },
        {
          id: 'senderStyle',
          label: '文字スタイル調整',
          visited: false,
          disabled: false,
          current: false
        }
      ]
    },
    {
      id: 'message',
      label: 'あいさつ文・コメント',
      visited: false,
      disabled: false,
      current: false,
      position: -1,
      children: [
        {
          id: 'messageInput',
          label: 'あいさつ文・コメント入力',
          visited: false,
          disabled: false,
          current: false
        },
        {
          id: 'messageTypesetting',
          label: '文字組選択',
          visited: false,
          disabled: false,
          current: false
        },
        {
          id: 'messageLayout',
          label: 'テキスト配置',
          visited: false,
          disabled: false,
          current: false
        },
        {
          id: 'messageStyle',
          label: '文字スタイル編集',
          visited: false,
          disabled: false,
          current: false
        }
      ]
    },
    {
      id: 'font',
      label: '書体選択',
      visited: false,
      disabled: false,
      current: false
    },
    {
      id: 'confirm',
      label: '仕上がり確認',
      visited: false,
      disabled: false,
      current: false
    }
  ],
  order: [
    {
      id: 'detail',
      label: '注文詳細',
      visited: false,
      disabled: false,
      current: false
    },
    {
      id: 'customerInput',
      label: '注文者情報入力',
      visited: false,
      disabled: false,
      current: false
    },
    {
      id: 'confirm',
      label: '最終確認',
      visited: false,
      disabled: false,
      current: false
    }
  ]
}
