import * as React from 'react'
import styled from 'styled-components'

import classNames from 'classnames'

interface Props {
  /** クラス名 */
  className?: string
  /** インラインcss */
  style?: React.CSSProperties
  /** アイコン */
  icon?: string | number | React.ReactElement
  /** クリックイベント */
  onClick?: (ev: React.MouseEvent<HTMLElement>) => any
}

export const RoundButton: React.SFC<Props> = ({
  children,
  className,
  style,
  icon,
  onClick
}) => {
  const type = children && icon ? 'withIcon' : children ? 'text' : 'icon'

  return (
    <StyledButton
      className={className}
      style={style}
      type={type}
      onClick={onClick}
      role="button"
    >
      {icon ? <IconWrap type={type}>{icon}</IconWrap> : null}
      {type === 'withIcon' || type === 'text' ? (
        <TextWrap type={type}>{children}</TextWrap>
      ) : null}
    </StyledButton>
  )
}

export default RoundButton

interface ButtonProps {
  type: 'text' | 'icon' | 'withIcon'
}

const buttonSize = (p: ButtonProps) => (p.type === 'text' ? 'auto' : '82px')

const StyledButton = styled.a`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${buttonSize};
  height: ${buttonSize};
  padding: ${(p: ButtonProps) => (p.type === 'text' ? 24 : 0)}px;
  background: #fff;
  border-radius: ${(p: ButtonProps) => (p.type === 'text' ? '40px' : '50%')};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  transition: 0.15s ease-out box-shadow, 0.15s ease-out transform;
  user-select: none;

  &:active {
    transform: translateY(3px);
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.25);
  }
`

const IconWrap = styled.i`
  width: ${(p: ButtonProps) => (p.type === 'withIcon' ? 28 : 40)}px;
  margin-top: ${(p: ButtonProps) => (p.type === 'withIcon' ? -5 : 0)}px;
  text-align: center;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
`

const TextWrap = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  font-size: ${(p: ButtonProps) => (p.type === 'withIcon' ? 12 : 20)}px;
  font-weight: bold;
`
