import {
  ActionTypes,
  ClearSelectedPhoto,
  FetchGroupImageIdsRequested,
  LoadThumbnailRequested,
  RemovePhoto,
  SelectPhoto,
  SetPhotos,
  StartEditing,
  UpdateFrame
} from './actions'

import Photo from '@/types/Photo'
import PhotoTypeNumber from '@/types/PhotoTypeNumber'
import TransformLayout from '@/types/TransformLayout'

/**
 * 写真をセットする
 */
export const setPhotos = (photos: Photo[]): SetPhotos => ({
  type: ActionTypes.SetPhotos,
  payload: photos
})

/**
 * サムネイルを読み込む
 */
export const loadThumbnailRequested = (
  id: string,
  type: PhotoTypeNumber = PhotoTypeNumber.thumbnail
): LoadThumbnailRequested => ({
  type: ActionTypes.LoadThumbnailRequested,
  payload: { id, type }
})

/**
 * 選択中の写真枠を更新
 */
export const updateFrame = (slot: number): UpdateFrame => ({
  type: ActionTypes.UpdateFrame,
  payload: { slot }
})

/**
 * 編集する写真を選択する
 */
export const selectPhoto = (photo: Photo): SelectPhoto => ({
  type: ActionTypes.SelectPhoto,
  payload: photo
})

/**
 * 写真サムネイルを削除する
 */
export const removePhoto = (photo: Photo): RemovePhoto => ({
  type: ActionTypes.RemovePhoto,
  payload: photo
})

/**
 * 選択状態の写真をクリア
 */
export const clearSelectedPhoto = (): ClearSelectedPhoto => ({
  type: ActionTypes.ClearSelectedPhoto
})

/**
 * 画像が入っているフレームの編集を開始する
 */
export const startEditing = (slot: number, imageId: string): StartEditing => ({
  type: ActionTypes.StartEditing,
  payload: { slot, imageId }
})

/**
 * グループIDに紐づく写真IDを取得する
 */
export const fetchGroupImageIdsRequested = (): FetchGroupImageIdsRequested => ({
  type: ActionTypes.FetchGroupImageIdsRequested
})
