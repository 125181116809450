import {
  Actions,
  ActionTypes,
  CompleteOrderRequested,
  PrintReceiptRequested,
  SaveOrderRequested
} from './actions'

import Order from '@/types/Order'
import Customer from '@/types/Order/Customer'

/**
 * 注文情報を更新する
 * @param order 注文情報
 */
export const patchOrder = (order: Partial<Order>): Actions => ({
  type: ActionTypes.PatchOrder,
  payload: order
})

/**
 * 注文者情報を更新する
 * @param customer 注文者情報
 */
export const patchCustomer = (customer: Partial<Customer>): Actions => ({
  type: ActionTypes.PatchCustomer,
  payload: customer
})

export const saveOrderRequested = (): SaveOrderRequested => ({
  type: ActionTypes.SaveOrderRequested
})

export const completeOrderRequested = (): CompleteOrderRequested => ({
  type: ActionTypes.CompleteOrderRequested
})

export const printReceiptRequested = (): PrintReceiptRequested => ({
  type: ActionTypes.PrintReceiptRequested
})
