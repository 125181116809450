import {
  ActionTypes,
  AddPostcardAddressRequested,
  ClearError,
  LoadPostcardAddressRequested,
  ResetAddress,
  UpdatePostcardAddressRequested
} from './actions'

import PostcardAddress from '@/types/PostcardAddresses/PostcardAddress'

/**
 * 宛名を読み込む
 */
export const fetchPostcardAddressRequested = (
  id: string
): LoadPostcardAddressRequested => ({
  type: ActionTypes.LoadPostcardAddressRequested,
  payload: { id }
})

/**
 * 宛名を追加する
 */
export const addPostcardAddressRequested = (
  address: PostcardAddress
): AddPostcardAddressRequested => ({
  type: ActionTypes.AddPostcardAddressRequested,
  payload: { address }
})

/**
 * 宛名を更新する
 */
export const updatePostcardAddressRequested = (
  address: PostcardAddress,
  redirectPath: string
): UpdatePostcardAddressRequested => ({
  type: ActionTypes.UpdatePostcardAddressRequested,
  payload: { address, redirectPath }
})

/**
 * 宛名を初期化
 */
export const resetAddress = (): ResetAddress => ({
  type: ActionTypes.ResetAddress
})

/**
 * エラーを初期化
 */
export const clearError = (): ClearError => ({
  type: ActionTypes.ClearError
})
