import { ActionTypes, UpdateImageRequested } from './actions'

/**
 * 宛名のプレビュー画像をセットする
 */
export const updateImage = (id: string): UpdateImageRequested => ({
  type: ActionTypes.UpdateImageRequested,
  payload: {
    id
  }
})
