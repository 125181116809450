import Config from '@/types/Config'

/**
 * アプリケーションの設定、API通信用パラメータを管理するステート
 */
export type State = Config

export const initialState: State = {
  isLoading: false,
  token: '',
  productTypeId: '',
  postcardEditingRecordId: '',
  editStartedAt: '',
  shopCode: 9999
}
