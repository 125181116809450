import { call, put, select, takeEvery } from 'redux-saga/effects'

import {
  ActionTypes as AlertActionTypes,
  SetInitFailureAlert
} from '@/store/modules/alert/actions'
import {
  ActionTypes as TemplateCategoriesActionTypes,
  LoadTemplateCategoriesRequested
} from '@/store/modules/templateCategories/actions'
import {
  ActionTypes as TemplatesActionTypes,
  LoadTemplateListRequested
} from '@/store/modules/templates/actions'
import {
  ActionTypes,
  FetchEditingConfigFailed,
  FetchEditingConfigRequested,
  FetchEditingConfigSucceeded,
  LoadEnd
} from './actions'

import { RootState } from '@/store'

import {
  createDesignEditInitialization,
  CreateDesignEditInitializationResponse
} from '@kn/common/lib/api/createDesignEditInitialization'

import EditingConfig from '@/types/Config/EditingConfig'

import configJson from '@/config.json'
import { sendApiError } from '@/utils/sentry'

/**
 * デザイン編集初期データ作成APIから
 * トークン, はがき編集データID, 編集開始日を取得
 */
function* fetchEditingConfig(action: FetchEditingConfigRequested) {
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )
  if (token) {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
    return
  }

  try {
    const resp: CreateDesignEditInitializationResponse = yield call(
      createDesignEditInitialization,
      {
        product_type_id: action.payload.productTypeId
      }
    )

    yield put<FetchEditingConfigSucceeded>({
      type: ActionTypes.FetchEditingConfigSucceeded,
      payload: EditingConfig.fromRaw(resp.design)
    })

    // Note:
    // デザイン編集初期データ作成APIの通信遅延が起きており
    // 遅延解消に向け、読み込み順に要望が入ったため下記通信をここで制御する。
    // 「年賀のテンプレートカテゴリー一覧取得」「喪中のテンプレート一覧リスト取得」
    if (action.payload.productTypeId === configJson.productTypeId.mochu) {
      yield put<LoadTemplateListRequested>({
        type: TemplatesActionTypes.LoadTemplateListRequested,
        payload: { productTypeId: action.payload.productTypeId }
      })
    } else {
      yield put<LoadTemplateCategoriesRequested>({
        type: TemplateCategoriesActionTypes.LoadTemplateCategoriesRequested,
        payload: action.payload.productTypeId
      })
    }
  } catch (e) {
    yield put<FetchEditingConfigFailed>({
      type: ActionTypes.FetchEditingConfigFailed
    })
    yield put<SetInitFailureAlert>({
      type: AlertActionTypes.SetInitFailureAlert
    })

    sendApiError(e)
  }
}

/**
 * 編集設定の取得について非同期処理を行うアクションを監視する
 */
export function* sagas() {
  yield takeEvery(ActionTypes.FetchEditingConfigRequested, fetchEditingConfig)
}
