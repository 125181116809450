import { call, put, select, takeEvery } from 'redux-saga/effects'

import {
  ActionTypes,
  FetchQrCodeFailed,
  FetchQrCodeRequested,
  FetchQrCodeSucceeded,
  LoadEnd,
  SetGroupIds
} from './actions'

import {
  createPhotoGroups,
  CreatePhotoGroupsResponse
} from '@kn/common/lib/api/createPhotoGroups'
import { createQrCode } from '@kn/common/lib/api/createQrCode'

import { RootState } from '@/store'

import * as error from '@/utils/error'
import { sendApiError } from '@/utils/sentry'

/**
 * モバイルアップロード用のQRコードを取得
 */
function* fetchQrCode() {
  const { productTypeId }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )
  const { qrCode }: RootState['upload'] = yield select(
    (state: RootState) => state.upload
  )

  try {
    const resp: CreatePhotoGroupsResponse = yield call(createPhotoGroups)

    const newQrCode: string = yield call(createQrCode, {
      photo_group_id: resp.photo_groups.photo_group_id,
      product_type_id: productTypeId
    })

    if (qrCode) {
      URL.revokeObjectURL(qrCode)
    }
    const path = URL.createObjectURL(newQrCode)

    yield put<FetchQrCodeSucceeded>({
      type: ActionTypes.FetchQrCodeSucceeded,
      payload: path
    })

    yield put<SetGroupIds>({
      type: ActionTypes.SetGroupIds,
      payload: resp.photo_groups.photo_group_id
    })
  } catch (e) {
    yield put<FetchQrCodeFailed>({
      type: ActionTypes.FetchQrCodeFailed,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e)
      }
    })

    sendApiError(e)
  }
}

/**
 * 編集設定の取得について非同期処理を行うアクションを監視する
 */
export function* sagas() {
  yield takeEvery(ActionTypes.FetchQrCodeRequested, fetchQrCode)
}
