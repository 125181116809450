import axios from 'axios';
import { apiURL } from '../common/';
/**
 * 商材種別で絞り込んだはがき種別一覧を取得するAPI
 */

export var fetchPostcardTypes = function fetchPostcardTypes(params) {
  return axios.get("".concat(apiURL, "/api/v1/postcard_types"), {
    params: params
  }).then(function (resp) {
    return resp.data;
  });
};