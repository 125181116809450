import axios from 'axios';
import { apiURL } from '../common/';
/**
 * 書体一覧取得APIのレスポンス
 */

/**
 * 書体選択一覧を取得する。
 */

export var fetchFontTypes = function fetchFontTypes() {
  return axios.get("".concat(apiURL, "/api/v1/font_types")).then(function (resp) {
    return resp.data;
  });
};