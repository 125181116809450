import {
  connectRouter,
  routerMiddleware,
  RouterState
} from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import alert, {
  Actions as AlertActions,
  State as AlertState
} from './modules/alert'
import config, {
  Actions as ConfigActions,
  sagas as configSaga,
  State as ConfigState
} from './modules/config'
import constants, {
  Actions as ConstantsActions,
  sagas as constantsSaga,
  State as ConstantsState
} from './modules/constants'
import fixedPhrases, {
  Actions as FixedPhrasesActions,
  sagas as fixedPhrasesSaga,
  State as FixedPhrasesState
} from './modules/fixedPhrases'
import messages, {
  Actions as MessagesActions,
  sagas as messagesSaga,
  State as MessagesState
} from './modules/messages'
import order, {
  Actions as OrderActions,
  sagas as orderSaga,
  State as OrderState
} from './modules/order'
import photos, {
  Actions as PhotosActions,
  sagas as photosSaga,
  State as PhotosState
} from './modules/photos'
import postcardAddressConfirm, {
  Actions as PostcardAddressConfirmActions,
  State as PostcardAddressConfirmState
} from './modules/postcardAddressConfirm'
import postcardAddressEdit, {
  Actions as PostcardAddressEditActions,
  sagas as postcardAddressEditSaga,
  State as PostcardAddressEditState
} from './modules/postcardAddressEdit'
import postcardAddresses, {
  Actions as PostcardAddressesActions,
  sagas as postcardAddressesSaga,
  State as PostcardAddressesState
} from './modules/postcardAddresses'
import postcardAddressImages, {
  Actions as PostcardAddressImagesActions,
  sagas as postcardAddressImagesSaga,
  State as PostcardAddressImagesState
} from './modules/postcardAddressImages'
import postcardTypes, {
  Actions as PostcardTypesActions,
  sagas as postcardTypesSaga,
  State as PostcardTypesState
} from './modules/postcardTypes'
import preview, {
  Actions as PreviewActions,
  sagas as previewSaga,
  State as PreviewState
} from './modules/preview'
import sender, {
  Actions as SenderActions,
  sagas as senderSaga,
  State as SenderState
} from './modules/sender'
import step, {
  Actions as StepActions,
  State as StepState
} from './modules/step'
import templateCategories, {
  Actions as TemplateCategoriesActions,
  sagas as templateCategoriesSaga,
  State as TemplateCategoriesState
} from './modules/templateCategories'
import templateDetail, {
  Actions as TemplateDetailActions,
  sagas as templateDetailSaga,
  State as TemplateDetailState
} from './modules/templateDetail'
import templates, {
  Actions as TemplatesActions,
  sagas as templatesSaga,
  State as TemplatesState
} from './modules/templates'
import upload, {
  Actions as UploadActions,
  sagas as uploadSaga,
  State as UploadState
} from './modules/upload'
import user, {
  Actions as UsersActions,
  sagas as userSaga,
  State as UsersState
} from './modules/user'

export interface RootState {
  step: StepState
  templates: TemplatesState
  templateCategories: TemplateCategoriesState
  templateDetail: TemplateDetailState
  preview: PreviewState
  sender: SenderState
  config: ConfigState
  constants: ConstantsState
  postcardAddressConfirm: PostcardAddressConfirmState
  postcardAddressEdit: PostcardAddressEditState
  postcardAddresses: PostcardAddressesState
  postcardAddressImages: PostcardAddressImagesState
  postcardTypes: PostcardTypesState
  fixedPhrases: FixedPhrasesState
  messages: MessagesState
  user: UsersState
  order: OrderState
  photos: PhotosState
  router: RouterState
  alert: AlertState
  upload: UploadState
}

export type Actions =
  | StepActions
  | TemplatesActions
  | TemplateCategoriesActions
  | TemplateDetailActions
  | PreviewActions
  | SenderActions
  | ConfigActions
  | ConstantsActions
  | PostcardAddressConfirmActions
  | PostcardAddressEditActions
  | PostcardAddressesActions
  | PostcardAddressImagesActions
  | PostcardTypesActions
  | FixedPhrasesActions
  | MessagesActions
  | OrderActions
  | PhotosActions
  | UsersActions
  | AlertActions
  | UploadActions

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME || ''
})
const sagaMiddleware = createSagaMiddleware()

const store = createStore<RootState, Actions, {}, {}>(
  combineReducers({
    step,
    templates,
    templateCategories,
    templateDetail,
    preview,
    sender,
    config,
    constants,
    postcardAddressConfirm,
    postcardAddressEdit,
    postcardAddresses,
    postcardAddressImages,
    postcardTypes,
    fixedPhrases,
    messages,
    order,
    photos,
    user,
    alert,
    upload,
    router: connectRouter(history)
  }),
  applyMiddleware(routerMiddleware(history), sagaMiddleware)
)

sagaMiddleware.run(templateCategoriesSaga)
sagaMiddleware.run(templateDetailSaga)
sagaMiddleware.run(templatesSaga)
sagaMiddleware.run(postcardAddressEditSaga)
sagaMiddleware.run(postcardAddressesSaga)
sagaMiddleware.run(postcardAddressImagesSaga)
sagaMiddleware.run(postcardTypesSaga)
sagaMiddleware.run(senderSaga)
sagaMiddleware.run(constantsSaga)
sagaMiddleware.run(fixedPhrasesSaga)
sagaMiddleware.run(configSaga)
sagaMiddleware.run(messagesSaga)
sagaMiddleware.run(orderSaga)
sagaMiddleware.run(photosSaga)
sagaMiddleware.run(userSaga)
sagaMiddleware.run(previewSaga)
sagaMiddleware.run(uploadSaga)

export default store
