import { Reducer } from 'redux'

import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LoadPostcardTypesSucceeded:
      return action.payload
    default:
      return state
  }
}

export default reducer
