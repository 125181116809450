import { PostcardAddressesError } from '@/types/Error/PostcardAddressesError'
import Config from '@/types/PostcardAddresses/Config'
import Layout from '@/types/PostcardAddresses/Layout'
import PostcardAddress from '@/types/PostcardAddresses/PostcardAddress'

/**
 * APIから取得した宛名一覧を管理するステート
 */
export interface State {
  /** ローディング中かどうか */
  isLoading: boolean
  /** ソート順 */
  sortType: 'added' | 'alphabetical'
  /** 宛名リスト */
  addressList: PostcardAddress[]
  /** 宛名関連の設定 */
  config: Config
  /** 宛名関連の定数 */
  constants: {
    layouts: Layout[]
  }
  error: PostcardAddressesError | null
}

export const initialState: State = {
  isLoading: false,
  addressList: [],
  sortType: 'added',
  config: {
    layoutType: 0,
    fontId: ''
  },
  constants: {
    layouts: []
  },
  error: null
}
