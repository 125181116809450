import TemplateDetail from '@/types/TemplateDetail'

/**
 * APIから取得したテンプレートの詳細情報を管理するステート
 */
export interface State {
  /** ローディング中かどうか */
  isLoading: boolean
  /** APIから取得したテンプレート詳細情報 */
  response: TemplateDetail
  /** テンプレート画像 */
  image: string
}

export const initialState: State = {
  isLoading: false,
  response: {
    postcardDesignId: '',
    designCode: '',
    productTypeId: '',
    frameCount: 0,
    postcardDesignCategoryId: '',
    postcardFinishingType: 0,
    postcardEditingRecordId: '',
    postcardEditingDate: '',
    masterOrderId: '',
    starRating: 0,
    direction: 'vertical',
    size: {
      print: {
        vertical: 0,
        horizontal: 0
      },
      photo: {
        vertical: 0,
        horizontal: 0
      }
    },
    photos: [],
    sender: {
      text: '',
      textFormType: '',
      colorCode: '#000000',
      fontId: '',
      direction: 'vertical',
      borderFlag: false,
      alignment: 'start',
      frameX: 0,
      frameY: 0,
      frameWidth: 0,
      frameHeight: 0
    },
    messages: [],
    uploadedImages: []
  },
  image: ''
}
