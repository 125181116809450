import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'

import Routes from '@/routes'

import { useInterval } from '@/hooks/useInterval'

import theme from '@/misc/theme'

import GlobalLoading from '@/containers/Loading/GlobalLoading'

import EditStartFailureAlert from '@/containers/Alert/EditStartFailureAlert'
import InitFailureAlert from '@/containers/Alert/initFailureAlert'

import { exitApplication } from '@/misc/exitApprication'
import AlertModal from './components/Modals/AlertModal'

export const App: React.FC = () => {
  const [idleTime, setIdolTime] = useState(0)

  const SHUT_DOWN_ALERT_TIME = 300
  const SHUT_DOWN_TIME = 1500

  useInterval(() => {
    setIdolTime(idleTime + 1)
  }, 1000)

  // TODO: ネイティブ側の終了エラーが解除されるまで自動終了させない
  // useEffect(() => {
  //   return
  //   if (idleTime !== SHUT_DOWN_TIME) {
  //     return
  //   }

  //   exitApplication()
  // }, [idleTime])

  const resetIdleTime = () => {
    if (SHUT_DOWN_ALERT_TIME < idleTime) {
      return
    }

    setIdolTime(0)
  }

  const forceResetIdleTime = () => {
    setIdolTime(0)
  }

  const exit = () => exitApplication()
  return (
    <ThemeProvider theme={theme}>
      <div onMouseDown={resetIdleTime} onTouchStart={resetIdleTime}>
        <GlobalLoading fullscreen={true} />
        <Routes />
        <AlertModal
          type="inline"
          isStrong={true}
          visible={SHUT_DOWN_ALERT_TIME < idleTime}
          onClose={forceResetIdleTime}
          onCancel={forceResetIdleTime}
          onNext={exit}
          zIndex={103}
          title="ご注意ください"
          nextLabel="終了する"
          cancelLabel="操作画面に戻る"
        >
          しばらく操作されていない状態が続いています。
          <br />
          編集作業を終了しますか？
        </AlertModal>
        <InitFailureAlert
          type="inline"
          onNext={exitApplication}
          title="アプリケーションの初期化に失敗しました"
          nextLabel="終了する"
        />
        <EditStartFailureAlert
          type="inline"
          title="デザインの読み込みに失敗しました"
          nextLabel="一覧へ戻る"
        />
      </div>
    </ThemeProvider>
  )
}

export default App
