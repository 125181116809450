import { Action } from 'redux'
import { StepCategory } from './state'

export enum ActionTypes {
  Jump = 'step/JUMP',
  Visit = 'step/Visit',
  InitEditingStep = 'step/INIT_EDITING_STEP'
}

export type Actions = Jump | Visit | InitEditingStep

/**
 * 指定したステップへ移動する
 * TODO: 全てのページコンテナーでVisitアクションに変更後削除
 */
export interface Jump extends Action {
  type: ActionTypes.Jump
  payload: {
    stepCategory: StepCategory
    stepId: string
  }
}

/** 指定したステップを訪問済みにする */
export interface Visit extends Action {
  type: ActionTypes.Visit
  payload: {
    stepCategory: StepCategory
    stepId: string
  }
}

/** テンプレートの写真枠・あいさつ文・コメント枠の有無を参考に編集ステップの初期設定をする */
export interface InitEditingStep extends Action {
  type: ActionTypes.InitEditingStep
  payload: {
    hasPhoto: boolean
    hasMessage: boolean
  }
}
