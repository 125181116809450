import { Action } from 'redux'

import TemplateCategory from '@/types/TemplateCategory'

export enum ActionTypes {
  LoadTemplateCategoriesRequested = 'templateCategories/LOAD_TEMPLATE_CATEGORIES_REQUESTED',
  LoadTemplateCategoriesSucceeded = 'templateCategories/LOAD_TEMPLATE_CATEGORIES_SUCCEEDED',
  LoadTemplateCategoriesFailed = 'templateCategories/LOAD_TEMPLATE_CATEGORIES_FAILED'
}

export type Actions =
  | LoadTemplateCategoriesRequested
  | LoadTemplateCategoriesSucceeded
  | LoadTemplateCategoriesFailed

/**
 * テンプレートカテゴリー一覧をAPIから取得
 */
export interface LoadTemplateCategoriesRequested extends Action {
  type: ActionTypes.LoadTemplateCategoriesRequested
  payload: string
}
export interface LoadTemplateCategoriesSucceeded extends Action {
  type: ActionTypes.LoadTemplateCategoriesSucceeded
  payload: {
    illusts: ReadonlyArray<TemplateCategory>
    photos: ReadonlyArray<TemplateCategory>
  }
}
export interface LoadTemplateCategoriesFailed extends Action {
  type: ActionTypes.LoadTemplateCategoriesFailed
}
