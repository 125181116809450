import { AxiosError } from 'axios'

/**
 * API通信上で起こったエラーのステータスコードを返す
 * 条件はAxios公式準拠 https://github.com/axios/axios#handling-errors
 */
export const getErrorStatus = (error: AxiosError): number | string => {
  if (error.response) {
    return error.response!.status
  } else if (error.request) {
    return 'Network Error'
  } else {
    return 'unknown'
  }
}

export const getErrorCode = (error: AxiosError): string | null => {
  if (!error.response) {
    return null
  }

  return (
    (error.response.data &&
      error.response.data.errors &&
      error.response.data.errors[0] &&
      error.response.data.errors[0].code) ||
    ''
  )
}
