import TransformLayout from '@/types/TransformLayout'

/**
 * プレビューの写真枠情報
 */
interface Photo extends TransformLayout {
  /** 写真ファイルパス */
  image: string
  /** 写真ファイルID */
  imageId: string
  /** マスク画像ファイルタイプ */
  maskImageType: string
  /** 写真補正フラグ */
  enhancementFlag: boolean
}

/**
 * フロント側で編集された画像レイアウト情報をリクエストで使用する形に変換する
 * @param update フロントの画像レイアウト情報
 */
const Photo = {
  toRaw(update: Photo): RawPhoto {
    return {
      mask_image_type: update.maskImageType,
      image_id: update.imageId,
      enhancement_flag: update.enhancementFlag,
      frame_x: update.frameX,
      frame_y: update.frameY,
      frame_width: update.frameWidth,
      frame_height: update.frameHeight,
      image_x: update.x,
      image_y: update.y,
      image_scale: update.scale,
      image_rotate: update.rotate
    }
  }
}

export default Photo

interface RawPhoto {
  mask_image_type: string
  image_id: string
  enhancement_flag: boolean
  frame_x: number
  frame_y: number
  frame_width: number
  frame_height: number
  image_x: number
  image_y: number
  image_scale: number
  image_rotate: number
}
