import TemplateCategory from '@/types/TemplateCategory'

/**
 * APIから取得したカテゴリー一覧を管理するステート
 */
export interface State {
  isLoading: boolean
  illusts: ReadonlyArray<TemplateCategory>
  photos: ReadonlyArray<TemplateCategory>
}

export const initialState: State = {
  isLoading: false,
  illusts: [],
  photos: []
}
