/**
 * アップロードした写真のテンプレートファイルタイプ
 */
export enum PhotoTypeNumber {
  thumbnail = 5,
  composition = 6,
  retouchedComposition = 7
}

export default PhotoTypeNumber
