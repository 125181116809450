import { push } from 'connected-react-router'
import { put, retry, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { createPostcardAddressHeidens } from '@kn/common/lib/api/createPostcardAddressHeidens'
import { getFileEditingPostcardAddress } from '@kn/common/lib/api/getFileEditingPostcardAddress'

import {
  ActionTypes,
  UpdateImageFailed,
  UpdateImageRequested,
  UpdateImageSucceeded
} from './actions'

import Image from '@/types/PostcardAddresses/Image'

import { RootState } from '@/store'
import { sendApiError } from '@/utils/sentry'

/**
 * 宛名のプレビュー画像を取得してaddressListのimageを更新
 */
function* updateImage(action: UpdateImageRequested) {
  const {
    editStartedAt,
    token,
    postcardEditingRecordId,
    productTypeId
  }: RootState['config'] = yield select((state: RootState) => state.config)
  const MAX_TRIES = 3
  const DELAY = 3000

  try {
    const params = {
      token,
      postcard_editing_record_id: postcardEditingRecordId,
      postcard_address_id: action.payload.id,
      product_type_id: productTypeId,
      postcard_editing_date: editStartedAt
    }

    yield retry(MAX_TRIES, DELAY, createPostcardAddressHeidens, params)

    const newImage = yield retry(
      MAX_TRIES,
      DELAY,
      getFileEditingPostcardAddress,
      {
        token,
        postcard_editing_record_id: postcardEditingRecordId,
        postcard_address_id: action.payload.id
      }
    )

    const images: Image[] = yield select(
      (state: RootState) => state.postcardAddressImages.images
    )
    // 既に画像があれば削除
    const oldImage: Image | undefined = images.find(
      img => img.id === action.payload.id
    )
    if (oldImage && oldImage.url) {
      URL.revokeObjectURL(oldImage.url)
    }

    const url = yield URL.createObjectURL(newImage)
    yield put<UpdateImageSucceeded>({
      type: ActionTypes.UpdateImageSucceeded,
      payload: {
        id: action.payload.id,
        url
      }
    })
  } catch (e) {
    yield put<UpdateImageFailed>({
      type: ActionTypes.UpdateImageFailed,
      payload: {
        id: action.payload.id
      }
    })
    // エラーハンドリング
    sendApiError(e)
  }
}

/**
 * 宛名一覧ストア上の非同期処理を行うアクションを監視する
 */
export function* sagas() {
  yield takeEvery(ActionTypes.UpdateImageRequested, updateImage)
}
