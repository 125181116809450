import { ActionTypes, FetchQrCodeRequested, SetPhotoCount } from './actions'

export const fetchQrCodeRequested = (): FetchQrCodeRequested => ({
  type: ActionTypes.FetchQrCodeRequested
})

export const setPhotoCount = (count: number): SetPhotoCount => ({
  type: ActionTypes.SetPhotoCount,
  payload: count
})
