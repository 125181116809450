import { ActionTypes, SetEnableSubmit, SetPage } from './actions'

/**
 * ページの変更
 */
export const setPage = (page: number): SetPage => ({
  type: ActionTypes.SetPage,
  payload: {
    page
  }
})

/**
 * 送信可能フラグ変更
 */
export const setEnableSubmit = (enableSubmit: boolean): SetEnableSubmit => ({
  type: ActionTypes.SetEnableSubmit,
  payload: {
    enableSubmit
  }
})
