import { ActionTypes, Jump, Visit } from './actions'
import { StepCategory } from './state'

/**
 * 指定されたステップへ遷移する
 * TODO: 全てのページコンテナーでVisitアクションに変更後削除
 */
export const jump = (stepCategory: StepCategory, stepId: string): Jump => {
  return {
    type: ActionTypes.Jump,
    payload: {
      stepCategory,
      stepId
    }
  }
}

/** 指定したステップを訪問済みにする */
export const visit = (stepCategory: StepCategory, stepId: string): Visit => {
  return {
    type: ActionTypes.Visit,
    payload: {
      stepCategory,
      stepId
    }
  }
}
