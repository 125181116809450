import axios from 'axios';
import { apiURL } from '../common/';
/**
 * ファイルアップローダー 写真グループコード生成APIのレスポンス
 */

/**
 * ８桁の写真グループコードと、32桁の写真グループIDを生成する。
 */

export var createPhotoGroups = function createPhotoGroups() {
  return axios.post("".concat(apiURL, "/api/v1/files/photo_groups")).then(function (resp) {
    return resp.data;
  });
};