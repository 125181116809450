import React, { useEffect, useRef, useState } from 'react'

export type TFunction = (...args: any[]) => any

export const useInterval = (callback: TFunction, delay: number) => {
  const savedCallback = useRef<TFunction>(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return
  }, [delay])
}
