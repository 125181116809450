import { Action } from 'redux'

import { ApiError } from '@/types/Error/ApiError'

import User from '@/types/User'

export enum ActionTypes {
  LoadSigninRequested = 'user/LOAD_SIGNIN_REQUESTED',
  LoadSigninSucceeded = 'user/LOAD_SIGNIN_SUCCEEDED',
  LoadEnd = 'user/LOAD_END',
  SetError = 'user/SET_ERROR',
  ClearError = 'user/CLEAR_ERROR'
}

export type Actions =
  | LoadEnd
  | LoadSigninRequested
  | LoadSigninSucceeded
  | SetError
  | ClearError

/**
 * ログインしてユーザー情報を取得する
 */
export interface LoadSigninRequested extends Action {
  type: ActionTypes.LoadSigninRequested
  payload: {
    email: string
    password: string
    redirectPath?: string
  }
}
export interface LoadSigninSucceeded extends Action {
  type: ActionTypes.LoadSigninSucceeded
  payload: User
}

export interface LoadEnd extends Action {
  type: ActionTypes.LoadEnd
}

/**
 * エラー
 */
export interface SetError extends Action {
  type: ActionTypes.SetError
  payload: ApiError
}

export interface ClearError extends Action {
  type: ActionTypes.ClearError
}
