import { Action } from 'redux'

import { State } from './state'

import Photo from '@/types/Photo'
import PhotoTypeNumber from '@/types/PhotoTypeNumber'

export enum ActionTypes {
  SetPhotos = 'photos/SET_PHOTOS',
  LoadThumbnailRequested = 'photos/LOAD_THUMBNAIL_REQUESTED',
  LoadThumbnailSucceeded = 'postcardAddresses/LOAD_THUMBNAIL_SUCCEEDED',
  LoadEnd = 'photos/LOAD_END',
  RemovePhoto = 'photos/REMOVE_PHOTO',
  UpdateFrame = 'photos/UPDATE_FRAME',
  SelectPhoto = 'photos/SELECT_PHOTO',
  ClearSelectedPhoto = 'photos/CLEAR_SELECTED_PHOTO',
  StartEditing = 'photos/START_EDITING',
  FetchGroupImageIdsRequested = 'photos/FETCH_GROUP_IMAGE_ID_REQUESTED',
  ClearError = 'photos/CLEAR_ERROR',
  SetError = 'photos/SET_ERROR'
}

export type Actions =
  | SetPhotos
  | LoadThumbnailRequested
  | LoadThumbnailSucceeded
  | LoadEnd
  | RemovePhoto
  | UpdateFrame
  | SelectPhoto
  | ClearSelectedPhoto
  | StartEditing
  | FetchGroupImageIdsRequested
  | ClearError
  | SetError

/**
 * 写真をセットする
 */
export interface SetPhotos extends Action {
  type: ActionTypes.SetPhotos
  payload: Photo[]
}

/**
 * 写真のサムネイルをapiから読み込む
 */
export interface LoadThumbnailRequested extends Action {
  type: ActionTypes.LoadThumbnailRequested
  payload: {
    id: string
    type: PhotoTypeNumber
  }
}
export interface LoadThumbnailSucceeded extends Action {
  type: ActionTypes.LoadThumbnailSucceeded
  payload: Photo
}
export interface LoadEnd extends Action {
  type: ActionTypes.LoadEnd
}

/**
 * 写真を削除する
 */
export interface RemovePhoto extends Action {
  type: ActionTypes.RemovePhoto
  payload: Photo
}

/**
 * 編集する写真を選択する
 */
export interface SelectPhoto extends Action {
  type: ActionTypes.SelectPhoto
  payload: Photo
}

/**
 * 選択状態の写真をクリア
 */
export interface ClearSelectedPhoto extends Action {
  type: ActionTypes.ClearSelectedPhoto
}

/**
 * 選択中の写真枠を更新
 */
export interface UpdateFrame extends Action {
  type: ActionTypes.UpdateFrame
  payload: { slot: number }
}

/**
 * 選択中の写真枠を更新
 */
export interface StartEditing extends Action {
  type: ActionTypes.StartEditing
  payload: {
    slot: number
    imageId: string
  }
}

/**
 * グループIDに紐づく写真IDを取得する
 */
export interface FetchGroupImageIdsRequested extends Action {
  type: ActionTypes.FetchGroupImageIdsRequested
}

/**
 * エラーを設定
 */
export interface SetError extends Action {
  type: ActionTypes.SetError
  payload: {
    status: number | string
    errorCode: string | null
  }
}
export interface ClearError extends Action {
  type: ActionTypes.ClearError
}
