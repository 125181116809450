import { Reducer } from 'redux'

import {
  Actions as MessageActions,
  ActionTypes as MessageActionTypes
} from '@/store/modules/messages/actions'
import {
  Actions as PhotosActions,
  ActionTypes as PhotosActionTypes
} from '@/store/modules/photos/actions'
import {
  Actions as SenderActions,
  ActionTypes as SenderActionTypes
} from '@/store/modules/sender/actions'
import {
  Actions as TemplateDetailActions,
  ActionTypes as TemplateDetailActionTypes
} from '@/store/modules/templateDetail/actions'
import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

import Photo from '@/types/Preview/Photo'
import Text from '@/types/Preview/Text'
import { Rect } from '@/types/Rect'
import Size from '@/types/Size'

import {
  getContainRectLayout,
  getCoverRectLayout,
  getTangentRectangle
} from '@/utils/edit'

const reducer: Reducer<
  State,
  | Actions
  | TemplateDetailActions
  | MessageActions
  | SenderActions
  | PhotosActions
> = (state = initialState, action) => {
  const initializeTransform = (
    textLayout: Text | Photo,
    imageSize: Size,
    minSize?: number
  ) => {
    const layout = {
      ...textLayout,
      width: imageSize.width,
      height: imageSize.height
    }

    const frame: Rect = {
      position: [textLayout.frameX, textLayout.frameY],
      width: textLayout.frameWidth,
      height: textLayout.frameHeight
    }

    return getContainRectLayout(layout, frame, minSize, {
      v: 'top',
      h: 'left'
    })
  }

  const initLayout = {
    isEditing: false,
    enhancementFlag: true,
    image: '',
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    scale: 1,
    rotate: 0
  }

  const MIN_SENDER_SCALE = 0.2
  const MIN_MESSAGE_SCALE = 0.25

  switch (action.type) {
    case TemplateDetailActionTypes.LoadTemplateDetailSucceeded:
      return {
        ...state,
        photos: action.payload.response.photos.map(photo => {
          return {
            ...photo,
            ...initLayout
          }
        }),
        sender: { ...action.payload.response.sender, ...initLayout },
        messages: action.payload.response.messages.map(message => {
          return {
            ...message,
            ...initLayout
          }
        }),
        templateSize: {
          width: action.payload.response.size.print.horizontal,
          height: action.payload.response.size.print.vertical
        }
      }
    case PhotosActionTypes.RemovePhoto:
      return {
        ...state,
        photos: state.photos.map((photo: Photo) => {
          if (photo.imageId !== action.payload.id) {
            return photo
          }

          return {
            ...photo,
            ...initLayout,
            imageId: ''
          }
        })
      }
    case ActionTypes.SaveDesignSucceeded:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.SaveDesignFailed:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          saveDesign: action.payload
        }
      }
    case ActionTypes.SaveDesignRequested:
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          saveDesign: null
        }
      }
    case ActionTypes.FetchPreviewImageRequested:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchPreviewImage: null
        }
      }
    case ActionTypes.FetchPreviewImageFailed:
      return {
        ...state,
        errors: {
          ...state.errors,
          fetchPreviewImage: action.payload
        }
      }
    case ActionTypes.FetchPreviewImageSucceeded:
      return {
        ...state,
        image: action.payload.image
      }
    case ActionTypes.ClearPreviewImage:
      return {
        ...state,
        image: ''
      }
    case MessageActionTypes.SetImage:
      const messageStrictArea = {
        width:
          state.templateSize.width -
          state.blueMargin.left -
          state.blueMargin.right,
        height:
          state.templateSize.height -
          state.blueMargin.top -
          state.blueMargin.bottom,
        position: [state.blueMargin.left, state.blueMargin.top] as [
          number,
          number
        ]
      }

      return {
        ...state,
        messages: state.messages.map((message, index) => {
          if (index !== action.payload.slot) {
            return message
          }

          // 未編集状態であれば初期位置を指定
          if (message.width === 0 && message.height === 0) {
            // Note: 挨拶文は設置時、枠に収まるように表示
            return {
              ...message,
              ...action.payload.size,
              ...initializeTransform(
                message,
                action.payload.size,
                MIN_MESSAGE_SCALE
              )
            }
          }

          const nextMessage = { ...message, ...action.payload.size }
          return {
            ...nextMessage,
            ...getContainRectLayout(nextMessage, messageStrictArea)
          }
        })
      }
    case SenderActionTypes.SetImage:
      const senderStrictArea = {
        width:
          state.templateSize.width -
          state.blueMargin.left -
          state.blueMargin.right,
        height:
          state.templateSize.height -
          state.blueMargin.top -
          state.blueMargin.bottom,
        position: [state.blueMargin.left, state.blueMargin.top] as [
          number,
          number
        ]
      }

      // 未編集状態であれば初期位置を指定
      if (
        (state.sender.width === 0 && state.sender.height === 0) ||
        !state.sender.image
      ) {
        // Note: 差出人は設置時、左上起点・スケール１で表示
        const initSender = {
          ...state.sender,
          ...action.payload.size,
          ...initializeTransform(
            state.sender,
            action.payload.size,
            MIN_SENDER_SCALE
          )
        }

        return {
          ...state,
          sender: {
            ...initSender,
            ...getContainRectLayout(initSender, senderStrictArea, 0.5)
          }
        }
      }

      const nextSender = {
        ...state.sender,
        ...action.payload.size
      }

      return {
        ...state,
        sender: {
          ...nextSender,
          ...getContainRectLayout(nextSender, senderStrictArea)
        }
      }
    case ActionTypes.UpdateLayout:
      const restrictedArea: Rect = {
        width:
          state.templateSize.width -
          state.blueMargin.left -
          state.blueMargin.right,
        height:
          state.templateSize.height -
          state.blueMargin.top -
          state.blueMargin.bottom,
        position: [state.blueMargin.left, state.blueMargin.top]
      }

      const type =
        action.payload.type === 'photo'
          ? 'photos'
          : action.payload.type === 'message'
          ? 'messages'
          : 'sender'

      if (type === 'sender') {
        const nextLayout = {
          ...action.payload.layout,
          scale:
            action.payload.layout.scale > MIN_SENDER_SCALE
              ? action.payload.layout.scale
              : MIN_SENDER_SCALE
        }

        return {
          ...state,
          sender: {
            ...state.sender,
            ...nextLayout,
            ...getContainRectLayout(nextLayout, restrictedArea)
          },
          textSizeAlert:
            action.payload.layout.scale < MIN_SENDER_SCALE
              ? true
              : state.textSizeAlert
        }
      }

      if (type === 'photos') {
        return {
          ...state,
          photos: state.photos.map((photo: Photo, index) => {
            return index !== action.payload.slot
              ? photo
              : {
                  ...photo,
                  ...action.payload.layout,
                  ...getCoverRectLayout(action.payload.layout, {
                    position: [photo.frameX, photo.frameY],
                    width: photo.frameWidth,
                    height: photo.frameHeight
                  }),
                  enhancementFlag:
                    action.payload.layout.imageId === photo.imageId
                      ? photo.enhancementFlag
                      : true
                }
          })
        }
      }

      return {
        ...state,
        messages: state.messages.map((c: Text, index: number) => {
          if (index !== action.payload.slot) {
            return c
          }

          const nextLayout = {
            ...action.payload.layout,
            scale:
              action.payload.layout.scale >= MIN_MESSAGE_SCALE
                ? action.payload.layout.scale
                : MIN_MESSAGE_SCALE
          }

          return {
            ...c,
            ...nextLayout,
            ...getContainRectLayout(nextLayout, restrictedArea)
          }
        }),
        textSizeAlert:
          action.payload.layout.scale < MIN_MESSAGE_SCALE
            ? true
            : state.textSizeAlert
      }
    case ActionTypes.InitEditingFrame:
      return {
        ...state,
        photos: state.photos.map((photo: Photo, index: number) => {
          return index !== action.payload.slot
            ? photo
            : {
                ...photo,
                ...initLayout,
                imageId: ''
              }
        })
      }
    case ActionTypes.InitPhotoLayout:
      return {
        ...state,
        photos: state.photos.map((photo: Photo, index: number) => {
          if (index !== action.payload.slot) {
            return photo
          }

          return {
            ...photo,
            ...getTangentRectangle(photo, {
              width: photo.frameWidth,
              height: photo.frameHeight,
              position: [photo.frameX, photo.frameY]
            })
          }
        })
      }
    case ActionTypes.InitPreviewLayout:
      return {
        ...state,
        initPreviewLayout: action.payload.layout,
        isInitializedPreview: true
      }
    case ActionTypes.InitPlaceLayout:
      const target = action.payload.type
      if (target === 'sender') {
        const nextLayout = {
          ...state.sender,
          x: 0,
          y: 0,
          scale: 1,
          rotate: 0
        }
        return {
          ...state,
          sender: {
            ...nextLayout,
            ...initializeTransform(
              nextLayout,
              {
                width: state.sender.width,
                height: state.sender.height
              },
              MIN_SENDER_SCALE
            )
          }
        }
      }

      if (target === 'photos') {
        return {
          ...state,
          photos: state.photos.map((photo: Photo, index: number) => {
            if (index !== action.payload.slot) {
              return photo
            }

            return {
              ...photo,
              ...getTangentRectangle(
                { ...photo, x: 0, y: 0, scale: 1 },
                {
                  width: photo.frameWidth,
                  height: photo.frameHeight,
                  position: [photo.frameX, photo.frameY]
                }
              )
            }
          })
        }
      }

      return {
        ...state,
        messages: state.messages.map(message => {
          if (message.textFormType !== action.payload.type) {
            return message
          }

          const nextLayout = {
            ...message,
            x: 0,
            y: 0,
            scale: 1,
            rotate: 0
          }

          return {
            ...nextLayout,
            ...initializeTransform(
              nextLayout,
              {
                width: message.width,
                height: message.height
              },
              MIN_MESSAGE_SCALE
            )
          }
        })
      }
    case ActionTypes.InitLayout:
      const initParam = {
        x: 0,
        y: 0,
        scale: 1,
        width: 0,
        height: 0,
        rotate: 0
      }
      if (action.payload.type === 'sender') {
        return {
          ...state,
          sender: {
            ...state.sender,
            ...initParam
          }
        }
      }

      if (action.payload.type === 'photos') {
        return {
          ...state,
          photos: state.photos.map((photo: Photo, index: number) => {
            if (index !== action.payload.slot) {
              return photo
            }
            return {
              ...photo,
              ...initParam
            }
          })
        }
      }

      return {
        ...state,
        messages: state.messages.map(message => {
          if (message.textFormType !== action.payload.type) {
            return message
          }
          return {
            ...message,
            ...initParam
          }
        })
      }
    case ActionTypes.HideTextSizeAlert:
      return {
        ...state,
        textSizeAlert: false
      }
    case ActionTypes.ToggleEnhancement:
      return {
        ...state,
        photos: state.photos.map((photo, index) => {
          if (index !== action.payload.slot) {
            return photo
          }

          return {
            ...photo,
            enhancementFlag: !photo.enhancementFlag
          }
        })
      }
    default:
      return state
  }
}

export default reducer
