import { Reducer } from 'redux'

import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LoadTemplateListSucceeded:
      return {
        ...state,
        templateList: action.payload
      }
    case ActionTypes.UpdateFilter:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload }
      }
    case ActionTypes.LoadTemplateListRequested:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case ActionTypes.LoadTemplateListFailed:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case ActionTypes.LoadEnd:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.AddPickedTemplate:
      // 追加したお気に入りがすでにある場合はお気に入りから外す
      if (
        state.pickedTemplateList.some(
          pickedTemplate => pickedTemplate.id === action.payload.id
        )
      ) {
        return {
          ...state,
          pickedTemplateList: state.pickedTemplateList.filter(
            pickedTemplate => pickedTemplate.id !== action.payload.id
          )
        }
      }

      // お気に入りにテンプレートを追加する
      return {
        ...state,
        pickedTemplateList: [...state.pickedTemplateList, action.payload]
      }
    case ActionTypes.RemovePickedTemplate:
      return {
        ...state,
        pickedTemplateList: state.pickedTemplateList.filter(
          pickedTemplate => pickedTemplate.id !== action.payload.id
        )
      }
    default:
      return state
  }
}

export default reducer
