import axios from 'axios';
import { apiURL } from '../common/';
/**
 * ファイルアップローダー 写真グループ写真ID取得APIのレスポンス
 */

/**
 * 写真グループIDに紐づく写真ファイルIDを返却する。
 */

export var getPhotoGroupsFileIds = function getPhotoGroupsFileIds(_ref) {
  var token = _ref.token,
      design_id = _ref.design_id,
      code = _ref.code;
  return axios.post("".concat(apiURL, "/api/v1/designs/").concat(design_id, "/photo_group_id/").concat(code), undefined, {
    headers: {
      Authorization: token
    }
  }).then(function (resp) {
    return resp.data;
  });
};