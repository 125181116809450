import { Actions, ActionTypes } from './actions'

import { FontStyle } from '@/types/FontStyle'
import { Layout, Sender } from '@/types/Sender'

/**
 * 差出人情報を全てクリアする
 */
export const clearRequest = (): Actions => ({
  type: ActionTypes.ClearRequest
})

/**
 * 差出人を更新する
 * @param sender 更新内容
 */
export const setSender = (sender: Sender): Actions => ({
  type: ActionTypes.Set,
  payload: sender
})

/**
 * 差出人の文字スタイルを更新する
 * @param style 文字スタイル
 */
export const patchStyle = (style: Partial<FontStyle>): Actions => ({
  type: ActionTypes.PatchStyle,
  payload: style
})

/**
 * 差出人のフォントのみ更新する
 * @param fontId フォント
 */
export const setFont = (fontId: string): Actions => ({
  type: ActionTypes.SetFont,
  payload: { fontId }
})

/**
 * 差出人のレイアウトを設定する
 * @param layout
 */
export const setLayout = (layout: Layout): Actions => ({
  type: ActionTypes.SetLayout,
  payload: layout
})

/**
 * 画像を取得する
 * @param layout
 */
export const fetchImage = (): Actions => ({
  type: ActionTypes.FetchImage
})
