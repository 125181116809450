import { OrdererRequest } from '@kn/common/lib/api/common/types/request/orderer'

/**
 * 注文者情報
 */
interface Customer {
  /** 姓 */
  lastName: string
  /** 名 */
  firstName: string
  /** 電話番号 */
  phoneNumber: string
  /** 郵便番号 */
  postcode: string
  /** 住所１ */
  address1: string
  /** 住所２ */
  address2: string
  /** 住所３ */
  address3: string
  /** 住所４ */
  address4: string
  /** 住所５ */
  address5: string
}

const Customer = {
  /**
   * APIリクエストの注文者情報をAPI側で使う構造に変換する
   * @param update フロント側の注文者情報
   */

  toRaw(update: Customer): OrdererRequest {
    return {
      last_name: update.lastName,
      first_name: update.firstName,
      phone_number: update.phoneNumber,
      postcode: update.postcode,
      address_1: update.address1,
      address_2: update.address2,
      address_3: update.address3,
      address_4: update.address4,
      address_5: update.address5
    }
  }
}

export default Customer
