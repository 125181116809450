import {
  ActionTypes,
  ChangeSortType,
  ClearError,
  DeleteTargetPostcardAddressRequested,
  LoadConfigRequested,
  LoadPostcardAddressesRequested,
  LoadSigninRequested,
  SetInitialConfig,
  UpdateConfigRequested,
  UpdateInitialConfig,
  UpdateOnlyTargetPostcardAddressRequested,
  UpdateTargetPostcardAddressRequested
} from './actions'

/**
 * ログインして宛名一覧に遷移
 */
export const signinRequested = (
  email: string,
  password: string
): LoadSigninRequested => ({
  type: ActionTypes.LoadSigninRequested,
  payload: {
    email,
    password
  }
})

/**
 * 宛名一覧を読み込む
 */
export const fetchPostcardAddressesRequested = (): LoadPostcardAddressesRequested => ({
  type: ActionTypes.LoadPostcardAddressesRequested
})

/**
 * 宛名の設定を読み込み、設定がなければ初期設定を登録する
 */
export const updateInitialConfig = (): UpdateInitialConfig => ({
  type: ActionTypes.UpdateInitialConfig
})

/**
 * 宛名の初期設定を登録する
 */
export const setInitialConfig = (): SetInitialConfig => ({
  type: ActionTypes.SetInitialConfig
})

/**
 * 宛名の設定を読み込む
 */
export const fetchConfigRequested = (): LoadConfigRequested => ({
  type: ActionTypes.LoadConfigRequested
})

/**
 * 宛名の設定を更新する
 */
export const updateConfigRequested = (
  fontId: string,
  layoutType: number
): UpdateConfigRequested => ({
  type: ActionTypes.UpdateConfigRequested,
  payload: {
    fontId,
    layoutType
  }
})

/**
 * 宛名印刷するかの一括変更し、新しい宛名リストを取得
 */
export const updateTargetPostcardAddressRequested = (
  postcardAddressIds: string[],
  printingFlag: boolean
): UpdateTargetPostcardAddressRequested => ({
  type: ActionTypes.UpdateTargetPostcardAddressRequested,
  payload: {
    postcardAddressIds,
    printingFlag
  }
})

/**
 * 宛名印刷するかの一括変更のみ
 */
export const updateOnlyTargetPostcardAddressRequested = (
  postcardAddressIds: string[],
  printingFlag: boolean
): UpdateOnlyTargetPostcardAddressRequested => ({
  type: ActionTypes.UpdateOnlyTargetPostcardAddressRequested,
  payload: {
    postcardAddressIds,
    printingFlag
  }
})

/**
 * 宛名削除
 */
export const deleteTargetPostcardAddressRequested = (
  postcardAddressIds: string[]
): DeleteTargetPostcardAddressRequested => ({
  type: ActionTypes.DeleteTargetPostcardAddressRequested,
  payload: {
    postcardAddressIds
  }
})

/**
 * ソートの変更
 */
export const changeSortType = (
  sortType: 'added' | 'alphabetical'
): ChangeSortType => ({
  type: ActionTypes.ChangeSortType,
  payload: {
    sortType
  }
})

export const clearError = (): ClearError => ({
  type: ActionTypes.ClearError
})
