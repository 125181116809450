import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { createSenderHeidens } from '@kn/common/lib/api/createSenderHeidens'
import { getFileEditingPostcard } from '@kn/common/lib/api/getFileEditingPostcard'

import { RootState } from '@/store'
import { getSenderHeidenParams } from '@/store/selectors/getSenderHeidenParams'

import {
  ActionTypes as PreviewActionTypes,
  InitLayout
} from '@/store/modules/preview/actions'
import { Actions, ActionTypes } from './actions'

import * as error from '@/utils/error'

import { getSize } from '@/utils/image'
import { sendApiError } from '@/utils/sentry'

function* updateSenderImage(action: Actions) {
  if (
    action.type === ActionTypes.SetLayout ||
    action.type === ActionTypes.Set
  ) {
    yield put<InitLayout>({
      type: PreviewActionTypes.InitLayout,
      payload: { type: 'sender' }
    })
  }

  yield put<Actions>({
    type: ActionTypes.ClearError
  })
  yield put<Actions>({
    type: ActionTypes.LoadStart
  })

  const { token, postcardEditingRecordId }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )
  const params: ReturnType<typeof getSenderHeidenParams> = yield select(
    getSenderHeidenParams
  )

  try {
    yield call(createSenderHeidens, params)

    const currentImage: string | undefined = yield select(
      (state: RootState) => state.sender.image
    )

    if (currentImage) {
      URL.revokeObjectURL(currentImage)
    }

    // TODO: 共通型アップデート後にanyキャストを外す
    const newImage = yield call(getFileEditingPostcard, {
      token,
      postcard_editing_record_id: postcardEditingRecordId,
      type: 1
    } as any)

    const path = URL.createObjectURL(newImage)
    const size = yield getSize(path)

    yield put<Actions>({
      type: ActionTypes.SetImage,
      payload: { uri: path, size }
    })
  } catch (e) {
    yield put<Actions>({
      type: ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e)
      }
    })
    yield put<Actions>({
      type: ActionTypes.LoadEnd
    })
    sendApiError(e)
  }
}

/**
 * 定数取得時の処理を再実行する
 */
function* reconfigureConstants() {
  const { sender }: RootState = yield select()

  yield put<Actions>({
    type: ActionTypes.SetConstants,
    payload: sender.constants
  })
}

/**
 * 差出人情報を初期化
 * Note: 取得しているテンプレート詳細の差出人情報をセットし初期化とする。
 */
function* clear() {
  const { templateDetail }: RootState = yield select()

  yield put<Actions>({
    type: ActionTypes.Clear,
    payload: templateDetail.response.sender
  })
}

export function* sagas() {
  yield takeLatest(ActionTypes.Set, updateSenderImage)
  yield takeLatest(ActionTypes.SetLayout, updateSenderImage)
  yield takeLatest(ActionTypes.PatchStyle, updateSenderImage)
  yield takeLatest(ActionTypes.FetchImage, updateSenderImage)

  yield takeEvery(ActionTypes.Clear, reconfigureConstants)
  yield takeEvery(ActionTypes.ClearRequest, clear)
}
