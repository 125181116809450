interface Config {
  /** レイアウトタイプ */
  layoutType: number
  /** 書体ID */
  fontId: string
}

export const Config = {
  /**
   * APIレスポンスの「メタデータ: レイアウトタイプ」をフロント側で使う構造に変換する
   * @param raw APIレスポンスの「メタデータ: レイアウトタイプ」
   */
  fromRaw(raw: RawConfig): Config {
    return {
      layoutType: raw.layout_type,
      fontId: raw.font_id
    }
  }
}

export default Config

export interface RawConfig {
  id: string
  customer_id: string
  layout_type: number
  text_color_type: number
  border_flag: boolean
  border_color_type: number
  font_id: string
}
