import { connect, MapStateToProps, Omit } from 'react-redux'

import { RootState } from '@/store'

import AlertModal, { Props } from '@/components/Modals/AlertModal'

type StateProps = Pick<Props, 'visible'>
type DispatchProps = Pick<Props, 'onNext'>
type OwnProps = Omit<Props, keyof StateProps>

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = ({
  alert
}) => {
  return {
    visible: alert.initFailureAlert.isVisible
  }
}

export default connect(mapStateToProps)(AlertModal)
