import { Point2D } from '@/types/Position'
import { Rect } from '@/types/Rect'
import TransformLayout from '@/types/TransformLayout'
import TransformRect from '@/types/TransformRect'
import DragEventCoordinates from '../types/DragEventCoordinates'
import * as image from './image'

export const getImageScale = (
  layout: TransformRect,
  coordinates: DragEventCoordinates,
  previewScale: number
): number => {
  const center = [
    layout.x * previewScale + (layout.width / 2) * previewScale,
    layout.y * previewScale + (layout.height / 2) * previewScale
  ]

  const diagonal: number = image.getDiagonalLength(
    coordinates.last[0] - center[0],
    coordinates.last[1] - center[1]
  )
  const beforeDiagonal: number = image.getDiagonalLength(
    coordinates.last[0] - center[0] - coordinates.delta[0],
    coordinates.last[1] - center[1] - coordinates.delta[1]
  )

  return diagonal / beforeDiagonal
}

export const getImageRotate = (
  layout: TransformRect,
  coordinates: DragEventCoordinates,
  previewScale: number
): number => {
  const origin = [
    layout.x * previewScale + (layout.width / 2) * previewScale,
    layout.y * previewScale + (layout.height / 2) * previewScale
  ]

  return image.getVectorsDegree(
    [origin[0], origin[1]],
    [coordinates.last[0], coordinates.last[1]],
    [
      coordinates.last[0] - coordinates.delta[0],
      coordinates.last[1] - coordinates.delta[1]
    ]
  )
}

/** 調整用のレイアウト情報 */
interface AdjustiveLayout {
  x: number
  y: number
  scale: number
}
/**
 * 自由変形（移動・回転・拡大）した矩形Aが矩形Bに収まるように調整
 * @param rectA 矩形A
 * @param rectB 矩形B
 * @param minScale 縮小下限
 */
export const getContainRectLayout = (
  rectA: TransformLayout,
  rectB: Rect,
  minScale?: number,
  align?: {
    v: 'top' | 'bottom' | 'none'
    h: 'left' | 'right' | 'none'
  }
): AdjustiveLayout => {
  // 画像の中心
  const imageCenter = [rectA.x + rectA.width / 2, rectA.y + rectA.height / 2]

  // 画像の対角線角度
  const leftTopDegree = image.getDiagonalDegree(rectA.width, rectA.height)
  // 画像の対角線
  const templateDiagonal = image.getDiagonalLength(rectA.width, rectA.height)

  // 編集画像が回転した際の頂点座標を取得
  const getCoordinal = (degree: number, vector: 1 | -1): Point2D => {
    const cordinal: Point2D = image.getCoordinate(
      (templateDiagonal / 2) * rectA.scale,
      degree + leftTopDegree * vector + rectA.rotate
    )
    return [cordinal[0] + imageCenter[0], cordinal[1] + imageCenter[1]]
  }

  const quadrantCoordinate = [
    getCoordinal(0, 1),
    getCoordinal(180, -1),
    getCoordinal(180, 1),
    getCoordinal(360, -1)
  ]

  const arrayX = [
    quadrantCoordinate[0][0],
    quadrantCoordinate[1][0],
    quadrantCoordinate[2][0],
    quadrantCoordinate[3][0]
  ]
  const arrayY = [
    quadrantCoordinate[0][1],
    quadrantCoordinate[1][1],
    quadrantCoordinate[2][1],
    quadrantCoordinate[3][1]
  ]

  const editingImage = {
    left: Math.min(...arrayX),
    top: Math.min(...arrayY),
    right: Math.max(...arrayX),
    bottom: Math.max(...arrayY)
  }

  // 画像と画像枠の比率
  const differRatio = {
    width: (editingImage.right - editingImage.left) / rectB.width,
    height: (editingImage.bottom - editingImage.top) / rectB.height
  }

  // 枠に合わせるための縮小率
  const baseScale =
    differRatio.width > differRatio.height && differRatio.width > 1
      ? 1 / differRatio.width
      : differRatio.height > 1
      ? 1 / differRatio.height
      : 1

  // 縮小下限を適用
  const adjustScale = minScale ? Math.max(baseScale, minScale) : baseScale

  // 画像の外接矩形
  const tempImageSize = {
    height: editingImage.bottom - editingImage.top,
    width: editingImage.right - editingImage.left
  }

  // 画像の外接矩形の中心
  const tempImageCenter = [
    editingImage.left + tempImageSize.width / 2,
    editingImage.top + tempImageSize.height / 2
  ]

  // 縮小処理を施した画像の外接矩形
  const tempImageCoordinal = {
    left: tempImageCenter[0] - tempImageSize.width / 2 / (1 / adjustScale),
    top: tempImageCenter[1] - tempImageSize.height / 2 / (1 / adjustScale),
    right: tempImageCenter[0] + tempImageSize.width / 2 / (1 / adjustScale),
    bottom: tempImageCenter[1] + tempImageSize.height / 2 / (1 / adjustScale)
  }

  let moveX = 0
  let moveY = 0

  // Frameの中の矩形のMaxMiniと照らし合わせ、位置調整したImage座標をだす。
  if (tempImageCoordinal.left < rectB.position[0]) {
    moveX = rectB.position[0] - tempImageCoordinal.left
  }
  if (tempImageCoordinal.top < rectB.position[1]) {
    moveY = rectB.position[1] - tempImageCoordinal.top
  }
  if (tempImageCoordinal.right > rectB.position[0] + rectB.width) {
    moveX = rectB.position[0] + rectB.width - tempImageCoordinal.right
  }
  if (tempImageCoordinal.bottom > rectB.position[1] + rectB.height) {
    moveY = rectB.position[1] + rectB.height - tempImageCoordinal.bottom
  }

  /** 行揃え指定があるとき */
  if (align && align.h === 'left') {
    moveX = rectB.position[0] - tempImageCoordinal.left
  }
  if (align && align.v === 'top') {
    moveY = rectB.position[1] - tempImageCoordinal.top
  }
  if (align && align.h === 'right') {
    moveX = rectB.position[0] + rectB.width - tempImageCoordinal.right
  }
  if (align && align.v === 'bottom') {
    moveY = rectB.position[1] + rectB.height - tempImageCoordinal.bottom
  }

  return {
    x: rectA.x + moveX,
    y: rectA.y + moveY,
    scale: rectA.scale * adjustScale
  }
}

/**
 * 自由変形（移動・回転・拡大）した矩形Aが矩形Bを収めるように調整
 * 対象の四角形が内接四角形を収めるように自動調整
 * @param rectA 四角形
 * @param rectB 内接四角形
 */
export const getCoverRectLayout = (
  rectA: TransformLayout,
  rectB: Rect
): AdjustiveLayout => {
  const frameCenter = [
    rectB.position[0] + rectB.width / 2,
    rectB.position[1] + rectB.height / 2
  ]
  const imageCenter = [rectA.x + rectA.width / 2, rectA.y + rectA.height / 2]
  const leftTopDegree = image.getDiagonalDegree(rectB.width, rectB.height)
  const frameDiagonal = image.getDiagonalLength(rectB.width, rectB.height)

  // 座標取得
  const getCoordinal = (degree: number, vector: 1 | -1): Point2D => {
    const cordinal: Point2D = image.getCoordinate(
      frameDiagonal / 2,
      degree + leftTopDegree * vector + rectA.rotate
    )
    return [cordinal[0] + frameCenter[0], cordinal[1] + frameCenter[1]]
  }

  const quadrantCoordinate = [
    getCoordinal(0, 1),
    getCoordinal(180, -1),
    getCoordinal(180, 1),
    getCoordinal(360, -1)
  ]

  const arrayX = [
    quadrantCoordinate[0][0],
    quadrantCoordinate[1][0],
    quadrantCoordinate[2][0],
    quadrantCoordinate[3][0]
  ]
  const arrayY = [
    quadrantCoordinate[0][1],
    quadrantCoordinate[1][1],
    quadrantCoordinate[2][1],
    quadrantCoordinate[3][1]
  ]

  const frame = {
    left: Math.min(...arrayX),
    top: Math.min(...arrayY),
    right: Math.max(...arrayX),
    bottom: Math.max(...arrayY)
  }

  // Auto Image Adjustment

  const differRatio = {
    width: (frame.right - frame.left) / (rectA.width * rectA.scale),
    height: (frame.bottom - frame.top) / (rectA.height * rectA.scale)
  }

  // Imageの大きさと比較
  const adjustScale =
    differRatio.width > differRatio.height && differRatio.width > 1
      ? differRatio.width
      : differRatio.height > 1
      ? differRatio.height
      : 1

  // imageの中心までの距離を導き出す。
  const diff = [
    frameCenter[0] - imageCenter[0],
    frameCenter[1] - imageCenter[1]
  ]
  const differDiagonal = image.getDiagonalLength(diff[0], diff[1])
  const differDiagonalDegree = image.getDiagonalDegree(diff[0], diff[1])

  // 回転リセットする際にFrameの中心に回転した時のImageの移動座標
  const translate = [
    image.getCoordinate(differDiagonal, differDiagonalDegree - rectA.rotate)[0],
    image.getCoordinate(differDiagonal, differDiagonalDegree - rectA.rotate)[1]
  ]

  // 回転リセットする際にFrameの中心に回転した時のImageの中心座標
  const tempImageCenter = [
    frameCenter[0] - translate[0],
    frameCenter[1] - translate[1]
  ]

  // Frame基準の新座標がでたのでtop,right,bottom,left を求める。
  const tempImageCoordinal = {
    top: tempImageCenter[1] - (rectA.height / 2) * rectA.scale * adjustScale,
    right: tempImageCenter[0] + (rectA.width / 2) * rectA.scale * adjustScale,
    bottom: tempImageCenter[1] + (rectA.height / 2) * rectA.scale * adjustScale,
    left: tempImageCenter[0] - (rectA.width / 2) * rectA.scale * adjustScale
  }

  // 処理中Imageのstate上でのleft topをだしておく
  let moveX = tempImageCenter[0] - rectA.width / 2
  let moveY = tempImageCenter[1] - rectA.height / 2

  // Frameを囲う矩形のMaxMiniと照らし合わせ、位置調整したImage座標をだす。
  if (frame.left < tempImageCoordinal.left) {
    moveX = moveX + (frame.left - tempImageCoordinal.left)
  }
  if (frame.top < tempImageCoordinal.top) {
    moveY = moveY + (frame.top - tempImageCoordinal.top)
  }
  if (frame.right > tempImageCoordinal.right) {
    moveX = moveX + (frame.right - tempImageCoordinal.right)
  }
  if (frame.bottom > tempImageCoordinal.bottom) {
    moveY = moveY + (frame.bottom - tempImageCoordinal.bottom)
  }

  const newDiffer = [
    moveX + rectA.width / 2 - frameCenter[0],
    moveY + rectA.height / 2 - frameCenter[1]
  ]

  // 位置調整したImage座標とFrameCenterを比較する
  const newDifferDiagonal = image.getDiagonalLength(newDiffer[0], newDiffer[1])
  const newDifferDiagonalDegree = image.getDiagonalDegree(
    newDiffer[0],
    newDiffer[1]
  )

  const adjustPosition = {
    x:
      frameCenter[0] +
      image.getCoordinate(
        newDifferDiagonal,
        newDifferDiagonalDegree + rectA.rotate
      )[0] -
      rectA.width / 2,
    y:
      frameCenter[1] +
      image.getCoordinate(
        newDifferDiagonal,
        newDifferDiagonalDegree + rectA.rotate
      )[1] -
      rectA.height / 2
  }
  return {
    x: adjustPosition.x,
    y: adjustPosition.y,
    scale: rectA.scale * adjustScale
  }
}

/**
 * rectB に rectAが正接するように値を返す
 * @param rectA TransformLayout
 * @param rectB Rect
 */
export const getTangentRectangle = (
  rectA: TransformLayout,
  rectB: Rect
): TransformLayout => {
  const vRatio = rectB.width / rectA.width
  const hRatio = rectB.height / rectA.height
  const scale = vRatio > hRatio ? vRatio : hRatio

  const center = [
    rectB.position[1] + rectB.height / 2,
    rectB.position[0] + rectB.width / 2
  ]

  return {
    ...rectA,
    y: center[0] - rectA.height / 2,
    x: center[1] - rectA.width / 2,
    scale,
    rotate: 0,
    width: rectA.width,
    height: rectA.height
  }
}
