import { Reducer } from 'redux'

import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LoadPostcardAddressesSucceeded:
      return {
        ...state,
        addressList: action.payload
      }
    case ActionTypes.LoadPostcardAddressesRequested:
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.UpdateTargetPostcardAddressSucceeded:
      return {
        ...state,
        addressList: action.payload
      }
    case ActionTypes.UpdateTargetPostcardAddressRequested:
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.UpdateOnlyTargetPostcardAddressSucceeded:
      return {
        ...state,
        addressList: state.addressList.map(address => {
          if (action.payload.postcardAddressIds.includes(address.id)) {
            address.printingFlag = action.payload.printingFlag
          }
          return address
        })
      }
    case ActionTypes.DeleteTargetPostcardAddressSucceeded:
      return {
        ...state,
        addressList: action.payload
      }
    case ActionTypes.DeleteTargetPostcardAddressRequested:
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.SetInitialConfig:
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.LoadEnd:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.ChangeSortType:
      return {
        ...state,
        sortType: action.payload.sortType
      }
    case ActionTypes.LoadConfigSucceeded:
      return {
        ...state,
        config: action.payload
      }
    case ActionTypes.UpdateConfigSucceeded:
      return {
        ...state,
        config: action.payload
      }
    case ActionTypes.SetConstants:
      return {
        ...state,
        constants: action.payload
      }
    case ActionTypes.SetError:
      return {
        ...state,
        error: action.payload
      }
    case ActionTypes.ClearError:
      return {
        ...state,
        error: null
      }
    default:
      return state
  }
}

export default reducer
