import { Actions, ActionTypes } from './actions'

import { FontStyle } from '@/types/FontStyle'
import { Alignment } from '@/types/Message/Alignment'

/**
 * あいさつ文・コメントをクリアする
 */
export const clearRequest = (slot: number): Actions => ({
  type: ActionTypes.ClearRequest,
  payload: { slot }
})

/**
 * あいさつ文・コメント内容を更新する
 * @param text あいさつ文・コメント文
 */
export const setMessage = (text: string, slot: number): Actions => ({
  type: ActionTypes.Set,
  payload: { text, slot }
})

/**
 * あいさつ文・コメントの文字スタイルを更新する
 * @param style 文字スタイル
 */
export const patchStyle = (
  style: Partial<FontStyle>,
  slot: number
): Actions => ({
  type: ActionTypes.PatchStyle,
  payload: { style, slot }
})

/**
 * あいさつ文・コメントのフォントを更新する
 * @param fontId フォントID
 */
export const setFont = (fontId: string): Actions => ({
  type: ActionTypes.SetFont,
  payload: { fontId }
})

/**
 * あいさつ文・コメントの向きを設定する
 * @param direction あいさつ文・コメントの向き
 */
export const setDirection = (direction: string, slot: number): Actions => ({
  type: ActionTypes.SetDirection,
  payload: { direction, slot }
})

/**
 * あいさつ文・コメントの行揃えを設定する
 * @param alignment あいさつ文・コメントの行揃え
 */
export const setAlignment = (alignment: Alignment, slot: number): Actions => ({
  type: ActionTypes.SetAlignment,
  payload: { alignment, slot }
})

/**
 * あいさつ文・コメントを編集状態にする
 * @param index 編集開始するあいさつ文・コメント番号
 */
export const setEditingMode = (index: number): Actions => ({
  type: ActionTypes.SetEditingMode,
  payload: index
})

/**
 * 画像を取得する
 * @param layout
 */
export const fetchImage = (slot: number): Actions => ({
  type: ActionTypes.FetchImage,
  payload: { slot }
})
