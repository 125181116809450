import { Reducer } from 'redux'

import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UpdateToken:
      return {
        ...state,
        token: action.payload
      }
    case ActionTypes.UpdateProductTypeId:
      return {
        ...state,
        productTypeId: action.payload
      }
    case ActionTypes.SetShopCode:
      return {
        ...state,
        shopCode: action.payload.shopCode
      }
    case ActionTypes.FetchEditingConfigRequested:
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.FetchEditingConfigFailed:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.LoadEnd:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.FetchEditingConfigSucceeded:
      return {
        ...state,
        isLoading: false,
        token: action.payload.token,
        postcardEditingRecordId: action.payload.postcardEditingRecordId,
        editStartedAt: action.payload.editStartDate
      }
    default:
      return state
  }
}

export default reducer
