import { Reducer } from 'redux'

import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LoadTemplateCategoriesRequested:
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.LoadTemplateCategoriesSucceeded:
      return {
        isLoading: false,
        ...action.payload
      }
    case ActionTypes.LoadTemplateCategoriesFailed:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}

export default reducer
