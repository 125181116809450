import axios from 'axios';
import { apiURL } from '../common/';
/**
 * テンプレート一覧 絞り込みモーダル カテゴリ一覧取得API 「写真」「イラスト」階層あり
 */

export var fetchTemplateCategoryLists = function fetchTemplateCategoryLists(params) {
  return axios.get("".concat(apiURL, "/api/v1/template_category_lists"), {
    params: params
  }).then(function (resp) {
    return resp.data;
  });
};