import axios from 'axios';
import { apiURL } from '../common/';
/**
 * 写真ファイル登録APIのレスポンス
 */

/**
 * 写真ファイルを登録する。
 */

export var savePhoto = function savePhoto(params) {
  var formData = new FormData();
  formData.append('file', params.file);
  formData.append('product_type_id', params.product_type_id);
  formData.append('edit_started_at', params.edit_started_at);

  if (params.photo_group_code) {
    formData.append('photo_group_code', params.photo_group_code);
  }

  return axios.post("".concat(apiURL, "/api/v1/files/photo"), formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(function (resp) {
    return resp.data;
  });
};