import * as React from 'react'
import styled from 'styled-components'

import { Theme } from '@/misc/theme'

interface Props {
  /** クラス名 */
  className?: string
  /** 無効化状態かどうか */
  disabled?: boolean
  /** 色のテーマ */
  colorTheme?: keyof Theme['colors']
  /** クリックイベント */
  onClick?: (ev: React.MouseEvent<HTMLElement>) => any
}

export const Button: React.SFC<Props> = ({
  className,
  disabled,
  colorTheme = 'default',
  onClick,
  children
}) => {
  return (
    <StyledButton
      className={className}
      disabled={!!disabled}
      colorTheme={colorTheme}
      onClick={onClick}
      role="button"
    >
      {children}
    </StyledButton>
  )
}

export default Button

interface ButtonProps {
  colorTheme: keyof Theme['colors']
  disabled: boolean
  theme: Theme
}

const StyledButton = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: relative;
  padding: 19px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  color: ${(p: ButtonProps) => (p.colorTheme === 'default' ? '#000' : '#FFF')};
  background: ${(p: ButtonProps) =>
    p.disabled ? 'gray' : p.theme.colors[p.colorTheme]};
  text-align: center;
  user-select: none;
  pointer-events: ${(p: ButtonProps) => (p.disabled ? 'none' : '')};
  font-weight: normal;
`
