import { Reducer } from 'redux'

import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SetPage:
      return {
        ...state,
        page: action.payload.page
      }
    case ActionTypes.SetEnableSubmit:
      return {
        ...state,
        enableSubmit: action.payload.enableSubmit
      }
    default:
      return state
  }
}

export default reducer
