import { Action } from 'redux'

import AssetType from '@/types/AssetTypes'
import { ApiError } from '@/types/Error/ApiError'
import Layout from '@/types/Layout'
import TransformLayout from '@/types/TransformLayout'

export enum ActionTypes {
  UpdateLayout = 'preview/UPDATE_LAYOUT',
  SaveDesignRequested = 'preview/SAVE_DESIGN_REQUESTED',
  SaveDesignSucceeded = 'preview/SAVE_DESIGN_SUCCEEDED',
  SaveDesignFailed = 'preview/SAVE_DESIGN_FAILED',
  FetchPreviewImageSucceeded = 'preview/FETCH_PREVIEW_IMAGE_SUCCEEDED',
  FetchPreviewImageFailed = 'preview/FETCH_PREVIEW_IMAGE_FAILED',
  FetchPreviewImageRequested = 'preview/FETCH_PREVIEW_IMAGE_REQUESTED',
  ClearPreviewImage = 'preview/CLEAR_PREVIEW_IMAGE',
  InitEditingFrame = 'preview/INIT_EDITING_FRAME',
  InitPhotoLayout = 'preview/INIT_PHOTO_LAYOUT',
  InitPreviewLayout = 'preview/INIT_PREVIEW_LAYOUT',
  InitPlaceLayout = 'preview/INIT_PLACE_LAYOUT',
  InitLayout = 'preview/INIT_LAYOUT',
  HideTextSizeAlert = 'preview/HIDE_TEXT_SIZE_ALERT',
  ToggleEnhancement = 'preview/TOGGLE_ENHANCEMENT'
}

export type Actions =
  | UpdateLayout
  | SaveDesignRequested
  | SaveDesignSucceeded
  | SaveDesignFailed
  | FetchPreviewImageSucceeded
  | FetchPreviewImageFailed
  | FetchPreviewImageRequested
  | ClearPreviewImage
  | InitEditingFrame
  | InitPhotoLayout
  | InitPreviewLayout
  | InitPlaceLayout
  | InitLayout
  | HideTextSizeAlert
  | ToggleEnhancement

/** 編集した画像の位置・回転・拡大値を更新する */
export interface UpdateLayout extends Action {
  type: ActionTypes.UpdateLayout
  payload: {
    layout: TransformLayout
    type: 'photo' | 'message' | 'sender'
    slot?: number
  }
}

/** 編集状態を保存 */
export interface SaveDesignRequested extends Action {
  type: ActionTypes.SaveDesignRequested
}
export interface SaveDesignSucceeded extends Action {
  type: ActionTypes.SaveDesignSucceeded
}
export interface SaveDesignFailed extends Action {
  type: ActionTypes.SaveDesignFailed
  payload: ApiError
}

/** プレビュー画像の取得 */
export interface FetchPreviewImageSucceeded extends Action {
  type: ActionTypes.FetchPreviewImageSucceeded
  payload: { image: string }
}
export interface FetchPreviewImageFailed extends Action {
  type: ActionTypes.FetchPreviewImageFailed
  payload: ApiError
}
export interface FetchPreviewImageRequested extends Action {
  type: ActionTypes.FetchPreviewImageRequested
}

/** プレビュー画像をクリア */
export interface ClearPreviewImage extends Action {
  type: ActionTypes.ClearPreviewImage
}

/** 編集中のフレームを初期化 */
export interface InitEditingFrame extends Action {
  type: ActionTypes.InitEditingFrame
  payload: { slot: number }
}

/** 編集中のフレームの画像レイアウトを初期化 */
export interface InitPhotoLayout extends Action {
  type: ActionTypes.InitPhotoLayout
  payload: { slot: number }
}

/** プレビューレイアウトを初期化 */
export interface InitPreviewLayout extends Action {
  type: ActionTypes.InitPreviewLayout
  payload: { layout: Layout }
}
/** 編集中画像の位置・大きさ・回転情報を初期化 */
export interface InitPlaceLayout extends Action {
  type: ActionTypes.InitPlaceLayout
  payload: {
    type: AssetType
    slot?: number
  }
}

/** 編集中画像を初期化 */
export interface InitLayout extends Action {
  type: ActionTypes.InitLayout
  payload: {
    type: AssetType
    slot?: number
  }
}

/** テキストサイズアラートを非表示 */
export interface HideTextSizeAlert extends Action {
  type: ActionTypes.HideTextSizeAlert
}

/** 補正設定の有無の切替 */
export interface ToggleEnhancement extends Action {
  type: ActionTypes.ToggleEnhancement
  payload: {
    slot: number
  }
}
