import axios from 'axios';
import { apiURL } from '../common/';
/**
 * 編集中データ参照APIのパラメータ
 */

/**
 * 編集中データファイルを参照する。
 */

export var getFileEditingPostcard = function getFileEditingPostcard(_ref) {
  var token = _ref.token,
      postcard_editing_record_id = _ref.postcard_editing_record_id,
      type = _ref.type;
  return axios.get("".concat(apiURL, "/api/v1/files/design/").concat(postcard_editing_record_id, "/type/").concat(type), {
    headers: {
      Authorization: token
    },
    responseType: 'blob'
  }).then(function (resp) {
    return resp.data;
  });
};