import { push } from 'connected-react-router'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  ActionTypes as AlertActionTypes,
  ToggleEditStartFailureAlert
} from '@/store/modules/alert/actions'
import {
  ActionTypes as MessageActionTypes,
  FetchImage
} from '@/store/modules/messages/actions'
import {
  ActionTypes as StepActionTypes,
  InitEditingStep
} from '@/store/modules/step/actions'
import {
  ActionTypes,
  LoadEnd,
  LoadTemplateDetailFailed,
  LoadTemplateDetailRequested,
  LoadTemplateDetailSucceeded
} from './actions'

import { RootState } from '@/store'

import {
  getTemplateDetail,
  GetTemplateDetailResponse
} from '@kn/common/lib/api/getTemplateDetail'
import { updateDesign } from '@kn/common/lib/api/updateDesign'

import { getFileTemplate } from '@kn/common/lib/api/getFileTemplate'

import TemplateDetail from '@/types/TemplateDetail'

import { sendApiError } from '@/utils/sentry'

/**
 * テンプレート詳細情報をAPIから取得
 */
function* loadTemplateDetail(action: LoadTemplateDetailRequested) {
  const {
    token,
    postcardEditingRecordId,
    editStartedAt
  }: RootState['config'] = yield select((state: RootState) => state.config)

  try {
    const resp: GetTemplateDetailResponse = yield call(getTemplateDetail, {
      id: action.payload
    })

    const rawLayout = {
      ...resp.template.layout_json_print,
      postcard_editing_record_id: postcardEditingRecordId,
      postcard_finishing_type: 0,
      postcard_editing_date: editStartedAt
    }

    // Note: DB側の編集データ初期化のため
    //       取得したテンプレート詳細情報に「はがき編集データID」を格納してAPIに送信
    yield call(updateDesign, {
      ...rawLayout,
      token,
      design_id: postcardEditingRecordId
    })

    const { fontColors }: RootState['constants'] = yield select(
      (state: RootState) => state.constants
    )
    const updateResp = TemplateDetail.fromRaw(rawLayout, fontColors)

    yield put<InitEditingStep>({
      type: StepActionTypes.InitEditingStep,
      payload: {
        hasPhoto: !!updateResp.photos.length,
        hasMessage: !!updateResp.messages.length
      }
    })

    // テンプレート画像を保存
    const { image }: RootState['templateDetail'] = yield select(
      (state: RootState) => state.templateDetail
    )
    if (image) {
      URL.revokeObjectURL(image)
    }

    const newImage = yield call(getFileTemplate, {
      template_id: updateResp.postcardDesignId,
      type: 7
    })

    yield put<LoadTemplateDetailSucceeded>({
      type: ActionTypes.LoadTemplateDetailSucceeded,
      payload: {
        response: updateResp,
        image: URL.createObjectURL(newImage)
      }
    })

    if (updateResp.photos.length > 0) {
      yield put(push('/edit/photo/'))
    } else {
      yield put(push('/edit/sender/'))
    }

    yield put<FetchImage>({
      type: MessageActionTypes.FetchImage,
      payload: { slot: 0 }
    })
  } catch (e) {
    yield put<LoadTemplateDetailFailed>({
      type: ActionTypes.LoadTemplateDetailFailed
    })
    yield put<ToggleEditStartFailureAlert>({
      type: AlertActionTypes.ToggleEditStartFailureAlert
    })
    sendApiError(e)
  }
}

/**
 * テンプレート詳細ストア上の非同期処理を行うアクションを監視する
 */
export function* sagas() {
  yield takeLatest(ActionTypes.LoadTemplateDetailRequested, loadTemplateDetail)
}
