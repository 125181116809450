import { FetchTemplatesResponse } from '@kn/common/lib/api/fetchTemplates'

/**
 * テンプレート情報
 */
interface Template {
  /** テンプレートID */
  id: string
  /** テンプレートコード */
  designCode: string
  /** ソート番号 */
  sortNumber: number
  /** 商材種別ID */
  productTypeId: string
  /** デザインカテゴリID */
  postcardDesignCategoryId: string
  /** テンプレート方向 */
  direction: 'vertical' | 'horizontal'
  /** 星評価 */
  starRating: number
}

/**
 * APIレスポンスのテンプレート情報をフロント側で使う構造に変換する
 * @param raw APIレスポンスのテンプレート情報
 */

const Template = {
  fromRaw(raw: RawTemplate): Template {
    return {
      id: raw.id,
      designCode: raw.design_code,
      sortNumber: raw.sort_number,
      productTypeId: raw.product_type_id,
      postcardDesignCategoryId: raw.postcard_design_category_id,
      direction: raw.direction === 0 ? 'vertical' : 'horizontal',
      starRating: raw.star_rating
    }
  }
}

type RawTemplate = FetchTemplatesResponse['templates'][0]

export default Template
