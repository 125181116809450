import axios from 'axios';
import { apiURL } from '../common/';
/**
 * メッセージ定型文一覧を取得する。
 */

export var fetchMessageTemplates = function fetchMessageTemplates(params) {
  return axios.get("".concat(apiURL, "/api/v1/message_templates"), {
    params: params
  }).then(function (resp) {
    return resp.data;
  });
};