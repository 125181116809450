import { Reducer } from 'redux'

import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PatchOrder:
      return {
        ...state,
        order: {
          ...state.order,
          ...action.payload
        }
      }
    case ActionTypes.PatchCustomer:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload
        }
      }
    case ActionTypes.SaveOrderSucceeded:
      return {
        ...state,
        estimate: action.payload.estimate,
        orderId: action.payload.orderId,
        isLoading: false
      }
    case ActionTypes.SaveOrderFailed:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          saveOrder: action.payload
        }
      }
    case ActionTypes.SaveOrderRequested:
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          saveOrder: null
        }
      }
    case ActionTypes.CompleteOrderSucceeded:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.CompleteOrderFailed:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          completeOrder: action.payload
        }
      }
    case ActionTypes.CompleteOrderRequested:
      return {
        ...state,
        isLoading: true,
        errors: {
          ...state.errors,
          completeOrder: null
        }
      }
    default:
      return state
  }
}

export default reducer
