import axios from 'axios';
import { apiURL } from '../common/';
/**
 * 登録された宛名を取得する。
 */

export var getPostcardAddress = function getPostcardAddress(_ref) {
  var token = _ref.token,
      postcard_address_id = _ref.postcard_address_id;
  return axios.get("".concat(apiURL, "/api/v1/postcard_addresses/").concat(postcard_address_id), {
    headers: {
      Authorization: token
    }
  }).then(function (resp) {
    return resp.data;
  });
};