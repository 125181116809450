import TransformLayout from '@/types/TransformLayout'

/**
 * プレビューのテキスト情報
 */
interface Text extends TransformLayout {
  /** テキストフォームタイプ */
  textFormType: string
}

/**
 * フロント側で編集された画像レイアウト情報をリクエストで使用する形に変換する
 * @param update フロントの画像レイアウト情報
 */
const Text = {
  toRaw(update: Text): RawText {
    return {
      text_form_type: update.textFormType,
      frame_x: update.frameX,
      frame_y: update.frameY,
      frame_width: update.frameWidth,
      frame_height: update.frameHeight,
      text_x: update.x,
      text_y: update.y,
      text_scale: update.scale,
      text_rotate: update.rotate
    }
  }
}

export default Text

interface RawText {
  text_form_type: string
  frame_x: number
  frame_y: number
  frame_width: number
  frame_height: number
  text_x: number
  text_y: number
  text_scale: number
  text_rotate: number
}
