import { Action } from 'redux'

import PostcardType from '@/types/PostcardType'

export enum ActionTypes {
  LoadPostcardTypesRequested = 'postcardTypes/LOAD_POSTCARD_TYPES_REQUESTED',
  LoadPostcardTypesSucceeded = 'postcardTypes/LOAD_POSTCARD_TYPES_SUCCEEDED',
  LoadPostcardTypesFailed = 'postcardTypes/LOAD_POSTCARD_TYPES_FAILED'
}

export type Actions =
  | LoadPostcardTypesRequested
  | LoadPostcardTypesSucceeded
  | LoadPostcardTypesFailed

/**
 * ハガキ種別一覧をAPIから取得
 */
export interface LoadPostcardTypesRequested extends Action {
  type: ActionTypes.LoadPostcardTypesRequested
  payload: string
}
export interface LoadPostcardTypesSucceeded extends Action {
  type: ActionTypes.LoadPostcardTypesSucceeded
  payload: Readonly<PostcardType[]>
}
export interface LoadPostcardTypesFailed extends Action {
  type: ActionTypes.LoadPostcardTypesFailed
}
