import { Action } from 'redux'

import { State } from './state'

export enum ActionTypes {
  Load = 'constants/LOAD',
  LoadError = 'constants/LOAD_ERROR',
  Set = 'constants/SET'
}

export type Actions = Load | LoadError | Set

/**
 * 定数を外部から読み込む
 */
export interface Load extends Action {
  type: ActionTypes.Load
}

/**
 * 定数の読み込みに失敗する
 */
export interface LoadError extends Action {
  type: ActionTypes.LoadError
  payload: {
    error: Error
  }
}

/**
 * 定数を更新する
 */
export interface Set extends Action {
  type: ActionTypes.Set
  payload: Partial<Omit<State, 'isLoading' | 'loadError'>>
}
