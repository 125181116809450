import axios from 'axios';
import { apiURL } from '../common/';
/**
 * 写真ファイル参照APIのパラメータ
 */

/**
 * 写真ファイルを参照する。
 */

export var getFilePhoto = function getFilePhoto(_ref) {
  var token = _ref.token,
      photo_id = _ref.photo_id,
      type = _ref.type;
  return axios.get("".concat(apiURL, "/api/v1/files/photo/").concat(photo_id, "/type/").concat(type), {
    responseType: 'blob',
    headers: {
      Authorization: token
    }
  }).then(function (resp) {
    return resp.data;
  });
};