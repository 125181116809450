import { LayoutJsonResponse } from '@kn/common/lib/api/common/types/response/layoutJson'
import Photo from './Photo'
import Size from './Size'
import Text from './Text'

import Color from '@/types/Constants/Color'

/**
 * テンプレート詳細情報
 */
interface TemplateDetail {
  /** はがきデザインID */
  postcardDesignId: string
  /** はがきデザインコード */
  designCode: string
  /** はがきデザイはがき仕上げ種別コード */
  postcardFinishingType: number
  /** 商材種別 */
  productTypeId: string
  /** デザインカテゴリID */
  postcardDesignCategoryId: string
  /** 写真枠数 */
  frameCount: number
  /** テンプレートの向き */
  direction: 'vertical' | 'horizontal'
  /** 星評価 */
  starRating: number
  /** はがき編集データID */
  postcardEditingRecordId: string
  /** 親注文受注ID */
  masterOrderId: string
  /** はがき編集開始日 */
  postcardEditingDate: string
  /** 合成サイズ */
  size: Size
  /** 写真枠情報 */
  photos: ReadonlyArray<Photo>
  /** 差出人情報 */
  sender: Text
  /** あいさつ文・コメント情報 */
  messages: ReadonlyArray<Text>
  /** アップロード写真 */
  uploadedImages: ReadonlyArray<string>
}

const TemplateDetail = {
  /**
   * APIレスポンスのテンプレート詳細情報をフロント側で使う構造に変換する
   * @param raw APIレスポンスのテンプレート詳細情報
   */
  fromRaw(raw: RawTemplateDetail, fontColors: Color[]): TemplateDetail {
    return {
      postcardDesignId: raw.postcard_design_id,
      designCode: raw.design_code,
      postcardFinishingType: raw.postcard_finishing_type,
      productTypeId: raw.product_type_id,
      frameCount: raw.frame_count,
      postcardDesignCategoryId: raw.postcard_design_category_id,
      starRating: raw.star_rating,
      postcardEditingDate: raw.postcard_editing_date,
      postcardEditingRecordId: raw.postcard_editing_record_id,
      masterOrderId: raw.master_order_id,
      direction: raw.direction === 0 ? 'vertical' : 'horizontal',
      size: raw.size,
      photos: raw.photos ? raw.photos.map(Photo.fromRaw) : [],
      sender: Text.fromRaw(
        raw.texts.find(text => text.text_form_type === 'sender_form')!,
        fontColors
      ),
      messages: raw.texts
        .filter(text => text.text_form_type !== 'sender_form')
        .map(fText => Text.fromRaw(fText, fontColors)),
      uploadedImages: raw.uploaded_images ? raw.uploaded_images : []
    }
  }
}

export default TemplateDetail

/**
 * APIレスポンスのテンプレート詳細情報
 */
export type RawTemplateDetail = LayoutJsonResponse
