import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'reset-css'

import * as Sentry from '@sentry/browser'

import store, { history } from '@/store'
import App from './App'

import * as addressCsv from '@/misc/addressCsv'
import * as ejectMemoryCard from '@/misc/ejectMemoryCard'
import * as exitApprication from '@/misc/exitApprication'
import * as i18n from '@/misc/i18n'
import * as readImage from '@/misc/readImage'
import * as receiptPrinting from '@/misc/receiptPrinting'
import * as scanner from '@/misc/scanner'
import * as screenKeyboard from '@/misc/screenKeyboard'

import getQueryParameters, { QueryParameters } from '@/utils/queryParameter'

import { setShopCode } from '@/store/modules/config'

if (process.env.SENTRY_DSN) {
  Sentry.init({ dsn: process.env.SENTRY_DSN })
}

i18n.init()
screenKeyboard.mock()
scanner.mock()
exitApprication.mock()
readImage.mock()
ejectMemoryCard.mock()
// TODO: 実機でエラーがでるため解消する。一旦コメントアウト。
// addressCsv.mock()

const { shop_code: shopCode }: QueryParameters = getQueryParameters()
if (shopCode) {
  store.dispatch(setShopCode(shopCode))
}
// TODO: 店舗コードが無い場合の処理が決まったら処理追加

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)
