import * as React from 'react'
import styled from 'styled-components'

import CloseButton from '@/components/Buttons/CloseButton'
import Overlay from '@/components/Overlays/Overlay'

interface Props {
  /** 表示・非表示 */
  visible: boolean
  /** クラス名 */
  className?: string
  /** モーダルの表示形式
   * @default block
   */
  type?: 'block' | 'inline'
  /** ヘッダーコンポーネント */
  header?: React.ReactElement
  /** フッターコンポーネント */
  footer?: React.ReactElement
  /** 閉じるイベント */
  onClose?: (ev: React.MouseEvent<HTMLElement>) => any
  zIndex?: number
}

export const Modal: React.SFC<Props> = ({
  visible,
  children,
  onClose,
  className,
  type = 'block',
  header,
  footer,
  zIndex = 101
}) => {
  return (
    <StyledContainer visible={visible} zIndex={zIndex}>
      <StyledModal className={className} type={type}>
        {header && <HeaderContainer>{header}</HeaderContainer>}
        <ContentsContainer header={header} footer={footer}>
          {children}
        </ContentsContainer>
        {footer && <FooterContainer>{footer}</FooterContainer>}
        {onClose && <Close onClick={onClose} />}
      </StyledModal>
      <Overlay visible={visible} onClick={onClose} />
    </StyledContainer>
  )
}

export default Modal

interface ContainerProps {
  visible: boolean
  zIndex: number
}

interface ContentsProps {
  header?: React.ReactElement
  footer?: React.ReactElement
}

const StyledContainer = styled.div`
  position: fixed;
  z-index: ${(p: ContainerProps) => p.zIndex};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(p: ContainerProps) => (p.visible ? 1 : 0)};
  pointer-events: ${(p: ContainerProps) => (p.visible ? 'auto' : 'none')};
  transition: 0.2s linear opacity;
`

interface ModalProps {
  type: 'block' | 'inline'
}
const StyledModal = styled.div`
  position: relative;
  width: ${(p: ModalProps) =>
    p.type === 'block' ? 'calc(100vw - 180px)' : 'auto'};
  height: ${(p: ModalProps) =>
    p.type === 'block' ? 'calc(100vh - 180px)' : 'auto'};
  min-width: 480px;
  padding: ${(p: ModalProps) => (p.type === 'block' ? 0 : '50px 100px 100px')};
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 1;
`

const StyledFrame = styled.div`
  position: absolute;
  left: 0px;
  width: 100%;
  background-color: #999;
`

const headerHeight = 120
const HeaderContainer = styled(StyledFrame)`
  height: ${headerHeight}px;
  top: 0px;
`

const footerHeight = 110
const FooterContainer = styled(StyledFrame)`
  height: ${footerHeight}px;
  bottom: 0px;
`

const ContentsContainer = styled.div`
  height: ${(p: ContentsProps) =>
    p.header && p.footer
      ? `calc(100% - ${headerHeight + footerHeight}px)`
      : p.header
      ? `calc(100% - ${headerHeight}px)`
      : p.footer
      ? `calc(100% - ${footerHeight}px)`
      : '100%'};

  margin-top: ${(p: ContentsProps) => (p.header ? headerHeight : 0)}px;
  margin-bottom: ${(p: ContentsProps) => (p.footer ? footerHeight : 0)}px;
`

const Close = styled(CloseButton)`
  position: absolute;
  top: -40px;
  right: -40px;
`
