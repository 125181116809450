import { Reducer } from 'redux'

import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LoadSigninSucceeded:
      return {
        ...state,
        data: action.payload
      }
    case ActionTypes.LoadSigninRequested:
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.LoadEnd:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.SetError:
      return {
        ...state,
        error: action.payload
      }
    case ActionTypes.ClearError:
      return {
        ...state,
        error: null
      }
    default:
      return state
  }
}

export default reducer
