import {
  ActionTypes,
  SetShopCode,
  UpdateProductTypeId,
  UpdateToken
} from './actions'
import { FetchEditingConfigRequested } from './actions'

/**
 * トークンの更新
 */
export const updateToken = (token: string): UpdateToken => ({
  type: ActionTypes.UpdateToken,
  payload: token
})

/**
 * 商品種別の更新
 */
export const updateProductTypeId = (
  productTypeId: string
): UpdateProductTypeId => ({
  type: ActionTypes.UpdateProductTypeId,
  payload: productTypeId
})

export const fetchEditingConfigRequested = (
  productTypeId: string
): FetchEditingConfigRequested => ({
  type: ActionTypes.FetchEditingConfigRequested,
  payload: { productTypeId }
})

export const setShopCode = (shopCode: number): SetShopCode => ({
  type: ActionTypes.SetShopCode,
  payload: { shopCode }
})
