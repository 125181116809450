import { Reducer } from 'redux'

import {
  Actions as TemplateDetailActions,
  ActionTypes as TemplateDetailActionTypes
} from '@/store/modules/templateDetail/actions'
import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

import { getSenderLayoutType } from '@/utils/getSenderLayoutType'

import { layoutTypeSenderIds } from '@/misc/layoutTypeSenderIds'

const reducer: Reducer<State, Actions | TemplateDetailActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TemplateDetailActionTypes.LoadTemplateDetailSucceeded:
      const initialSender = action.payload.response.sender

      const isVertical = layoutTypeSenderIds.vertical.some(
        id => id === initialSender.layoutType
      )

      const { locationalIdentities } = state.constants

      const senderData = {
        ...state.data,
        address: {
          ...state.data.address,
          contacts:
            locationalIdentities.length > 0
              ? state.data.address.contacts.map((c, i) => ({
                  ...c,
                  label:
                    locationalIdentities[
                      (initialSender.telephoneFormId &&
                        initialSender.telephoneFormId[i]) ||
                        0
                    ].label
                }))
              : state.data.address.contacts
        }
      }

      return {
        ...state,
        style: {
          ...state.style,
          fontId: initialSender.fontId,
          color: initialSender.colorCode,
          hasBorder: initialSender.borderFlag
        },
        layout: {
          ...state.layout,
          id: initialSender.layoutType || (isVertical ? 4 : 0),
          isVertical
        },
        image: '',
        data: senderData
      }
    case ActionTypes.LoadStart:
      return {
        ...state,
        isLoading: true
      }
    case ActionTypes.LoadEnd:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.Clear:
      const defaultSender = action.payload
      const defaultVertical = layoutTypeSenderIds.vertical.some(
        id => id === defaultSender.layoutType
      )

      return {
        ...initialState,
        style: {
          ...state.style,
          color: defaultSender.colorCode,
          hasBorder: defaultSender.borderFlag,
          fontId: state.style.fontId
        },
        layout: {
          ...state.layout,
          id: defaultSender.layoutType || (defaultVertical ? 4 : 0),
          isVertical: defaultVertical
        },
        image: '',
        constants: state.constants
      }
    case ActionTypes.Set:
      return {
        ...state,
        data: action.payload,
        layout: {
          ...state.layout,
          id: getSenderLayoutType(
            state.constants.layouts[action.payload.type],
            state.layout
          )
        }
      }
    case ActionTypes.SetLayout:
      return {
        ...state,
        layout: action.payload
      }
    case ActionTypes.PatchStyle:
      return {
        ...state,
        style: {
          ...state.style,
          ...action.payload
        }
      }
    case ActionTypes.SetImage:
      return {
        ...state,
        image: action.payload.uri,
        isLoading: false
      }
    case ActionTypes.SetFont:
      return {
        ...state,
        style: {
          ...state.style,
          ...action.payload
        }
      }
    case ActionTypes.SetError:
      return {
        ...state,
        error: {
          status: action.payload.status,
          errorCode: action.payload.errorCode
        }
      }
    case ActionTypes.ClearError:
      return {
        ...state,
        error: null
      }
    case ActionTypes.SetConstants: {
      const {
        personalIdentities: personal,
        locationalIdentities: locational
      } = action.payload

      return {
        ...state,
        // ラベルが空だった場合は取得したラベルの先頭のものをあてがう
        data: {
          ...state.data,
          address: {
            ...state.data.address,
            contacts:
              locational.length > 0
                ? state.data.address.contacts.map(c => ({
                    ...c,
                    label: c.label || locational[0].label
                  }))
                : state.data.address.contacts
          },
          contacts:
            personal.length > 0
              ? state.data.contacts.map(c => ({
                  ...c,
                  label: c.label || personal[0].label
                }))
              : state.data.contacts
        },
        layout: state.layout || action.payload.layouts[0],
        constants: action.payload
      }
    }
    default:
      return state
  }
}

export default reducer
