declare global {
  interface External {
    ExitAction1(): any
    ExitAction2(): any
  }
}

/**
 * アプリケーションを終了する
 */
export const exitApplication = () => {
  window.external.ExitAction1()
  window.external.ExitAction2()
}

/**
 * アプリケーションを終了の簡易モック
 */
export const mock = () => {
  if (!window.external) {
    ;(window.external as any) = {}
  }

  if (window.external.ExitAction1 && window.external.ExitAction2) {
    return
  }

  try {
    window.external.ExitAction1 = () => {
      console.info('終了ボタン１が押されました')
    }
    window.external.ExitAction2 = () => {
      console.info('終了ボタン２が押されました。アプリケーションを終了します。')
    }
  } catch (e) {
    // IE環境ではwindow.externalに代入ができないが、
    // 店頭端末等があるためモックは使用しない
  }
}
