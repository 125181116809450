import React, { lazy, Suspense } from 'react'

import { Route, Switch } from 'react-router-dom'

import Loading from '@/components/Loading'

const CategoryList = lazy(() => import('@/containers/CategoryList'))
const SeasonCategoryList = lazy(() => import('@/containers/SeasonCategoryList'))
const UploadedPhotoList = lazy(() => import('@/containers/UploadedPhotoList'))
const Sender = lazy(() => import('@/containers/Sender'))
const Message = lazy(() => import('@/containers/Message'))

const TemplateList = lazy(() => import('@/containers/TemplateList'))
const SeasonTemplateList = lazy(() => import('@/containers/SeasonTemplateList'))
const MourningTemplateList = lazy(() =>
  import('@/containers/MourningTemplateList')
)

const FontFamily = lazy(() => import('@/containers/FontFamily'))
const Confirm = lazy(() => import('@/containers/DesignConfirm'))
const DesignFlowTemplate = lazy(() =>
  import('@/components/Templates/DesignFlowTemplate')
)
const Login = lazy(() => import('@/containers/Login'))
const AddressConfirm = lazy(() => import('@/containers/AddressConfirm'))
const AddressImportMethods = lazy(() =>
  import('@/containers/AddressImportMethods')
)
const AddressEdit = lazy(() => import('@/containers/AddressEdit'))
const AddressList = lazy(() => import('@/containers/AddressList'))
const AddressOrientation = lazy(() => import('@/containers/AddressOrientation'))

const OrderDetail = lazy(() => import('@/containers/Order/OrderDetail'))
const OrderInput = lazy(() => import('@/containers/Order/OrderInput'))
const OrderConfirm = lazy(() => import('@/containers/Order/OrderConfirm'))
const OrderReceipt = lazy(() => import('@/containers/Order/OrderReceipt'))

const OrderComplete = lazy(() => import('@/components/Pages/OrderComplete'))

const PageNotFound = lazy(() => import('@/components/Pages/PageNotFound'))

export default (childProps: any) => {
  return (
    <Suspense fallback={<Loading visible={true} fullscreen={true} />}>
      <Switch>
        <Route
          path="/"
          exact={true}
          component={CategoryList}
          props={childProps}
        />
        <Route
          path="/season/"
          exact={true}
          component={SeasonCategoryList}
          props={childProps}
        />
        <Route path="/template/" exact={true} component={TemplateList} />
        <Route
          path="/template/season/"
          exact={true}
          component={SeasonTemplateList}
        />
        <Route
          path="/template/mourning/"
          exact={true}
          component={MourningTemplateList}
        />
        <Route path="/edit/photo/" exact={true} component={UploadedPhotoList} />
        <Route path="/edit/sender/" exact={true} component={Sender} />
        <Route path="/edit/message/" exact={true} component={Message} />
        <Route path="/edit/font/" exact={true} component={FontFamily} />
        <Route path="/edit/confirm/" exact={true} component={Confirm} />
        <Route path="/address/login/" exact={true} component={Login} />
        <Route
          path="/address/confirm"
          exact={true}
          component={AddressConfirm}
        />
        <Route path="/address/list" exact={true} component={AddressList} />
        <Route
          path="/address/load/"
          exact={true}
          component={AddressImportMethods}
        />
        <Route
          path="/address/style"
          exact={true}
          component={AddressOrientation}
        />
        <Route path="/address/input/:id" exact={true} component={AddressEdit} />
        <Route path="/order/detail/" exact={true} component={OrderDetail} />
        <Route path="/order/input/" exact={true} component={OrderInput} />
        <Route path="/order/confirm/" exact={true} component={OrderConfirm} />

        <Route path="/order/receipt/" exact={true} component={OrderReceipt} />
        <Route path="/order/complete/" exact={true} component={OrderComplete} />
        <Route component={PageNotFound} />
      </Switch>
    </Suspense>
  )
}
