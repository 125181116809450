import { Reducer } from 'redux'

import { Actions, ActionTypes } from './actions'
import { initialState, State } from './state'

const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FetchQrCodeRequested:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case ActionTypes.FetchQrCodeFailed:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case ActionTypes.LoadEnd:
      return {
        ...state,
        isLoading: false
      }
    case ActionTypes.FetchQrCodeSucceeded:
      return {
        ...state,
        isLoading: false,
        qrCode: action.payload
      }
    case ActionTypes.SetGroupIds:
      return {
        ...state,
        photoGroupId: action.payload
      }
    case ActionTypes.SetPhotoCount:
      return {
        ...state,
        uploadPhotoCount: action.payload
      }
    default:
      return state
  }
}

export default reducer
