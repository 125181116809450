import {
  ActionTypes,
  SetInitFailureAlert,
  ToggleEditStartFailureAlert
} from './actions'

export const setInitFailureAlert = (): SetInitFailureAlert => {
  return {
    type: ActionTypes.SetInitFailureAlert
  }
}
export const toggleEditStartFailureAlert = (): ToggleEditStartFailureAlert => {
  return {
    type: ActionTypes.ToggleEditStartFailureAlert
  }
}
