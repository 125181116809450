import {
  ActionTypes,
  LoadTemplateCategoriesFailed,
  LoadTemplateCategoriesRequested,
  LoadTemplateCategoriesSucceeded
} from '@/store/modules/templateCategories/actions'
import {
  fetchTemplateCategoryLists,
  FetchTemplateCategoryListsResponse
} from '@kn/common/lib/api/fetchTemplateCategoryLists'
import { call, put, takeLatest } from 'redux-saga/effects'

import { sendApiError } from '@/utils/sentry'

/**
 * テンプレートカテゴリー一覧をAPIから取得
 */
function* loadTemplateCategories(action: LoadTemplateCategoriesRequested) {
  try {
    const resp: FetchTemplateCategoryListsResponse = yield call(
      fetchTemplateCategoryLists,
      {
        product_type_id: action.payload
      }
    )
    yield put<LoadTemplateCategoriesSucceeded>({
      type: ActionTypes.LoadTemplateCategoriesSucceeded,
      payload: resp.template_categories
    })
  } catch (e) {
    yield put<LoadTemplateCategoriesFailed>({
      type: ActionTypes.LoadTemplateCategoriesFailed
    })
    sendApiError(e)
  }
}

/**
 * テンプレートカテゴリーストア上の非同期処理を行うアクションを監視する
 */
export function* sagas() {
  yield takeLatest(
    ActionTypes.LoadTemplateCategoriesRequested,
    loadTemplateCategories
  )
}
