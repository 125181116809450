import axios from 'axios';
import { apiURL } from '../common/';
/**
 * 宛名組版設定を取得する。
 */

export var getPostcardAddressHeidenConf = function getPostcardAddressHeidenConf(_ref) {
  var token = _ref.token;
  return axios.get("".concat(apiURL, "/api/v1/postcard_address_heiden_conf"), {
    headers: {
      Authorization: token
    }
  }).then(function (resp) {
    return resp.data;
  });
};