import axios from 'axios';
import { apiURL } from '../common/';
/**
 * 会員照会APIのレスポンス
 */

/**
 * メタデータ一式を取得する
 */

export var getEnums = function getEnums() {
  return axios.get("".concat(apiURL, "/api/v1/enums")).then(function (resp) {
    return resp.data;
  });
};