import { ApiError } from '@/types/Error/ApiError'
import { FontStyle } from '@/types/FontStyle'
import Sender, { Layout } from '@/types/Sender'

interface LabeledValue<T = string> {
  label: string
  id: T
}

export interface State {
  isLoading: boolean
  data: Sender
  layout: Layout
  style: FontStyle
  image?: string
  constants: {
    /**
     * 場所に関する情報(ex. 電話番号, FAX番号)
     */
    locationalIdentities: Array<LabeledValue<number>>

    /**
     * 個人や組織に関する情報(ex. Email, SNSアカウント)
     */
    personalIdentities: Array<LabeledValue<number>>

    /**
     * 選択可能なレイアウト一覧
     */
    layouts: {
      private: Layout[]
      company: Layout[]
    }
  }
  error: ApiError | null
}

export const initialState: State = {
  isLoading: false,
  data: {
    type: 'private',
    name: {
      familyName: '',
      givenName: '',
      attachment: '',
      attachmentType: 'suffixAge'
    },
    jointNames: [
      {
        attachment: '',
        attachmentType: 'suffixAge',
        familyName: '',
        givenName: ''
      },
      {
        attachment: '',
        attachmentType: 'suffixAge',
        familyName: '',
        givenName: ''
      },
      {
        attachment: '',
        attachmentType: 'suffixAge',
        familyName: '',
        givenName: ''
      },
      {
        attachment: '',
        attachmentType: 'suffixAge',
        familyName: '',
        givenName: ''
      },
      {
        attachment: '',
        attachmentType: 'suffixAge',
        familyName: '',
        givenName: ''
      }
    ],
    address: {
      zipCode: '',
      line1: '',
      line2: '',
      line3: '',
      contacts: [{ label: '', value: '' }, { label: '', value: '' }]
    },
    contacts: [{ label: '', value: '' }, { label: '', value: '' }]
  },
  style: {
    color: '#000000',
    hasBorder: false,
    fontId: ''
  },
  layout: {
    id: 0,
    label: 'パターン1',
    isVertical: false,
    sortNumber: 1
  },
  constants: {
    locationalIdentities: [],
    personalIdentities: [],
    layouts: {
      private: [],
      company: []
    }
  },
  error: null
}
