import {
  ActionTypes,
  FetchPreviewImageRequested,
  HideTextSizeAlert,
  InitEditingFrame,
  InitLayout,
  InitPhotoLayout,
  InitPlaceLayout,
  InitPreviewLayout,
  SaveDesignRequested,
  ToggleEnhancement,
  UpdateLayout
} from './actions'

import AssetType from '@/types/AssetTypes'
import Layout from '@/types/Layout'
import Rect from '@/types/Rect'
import TransformLayout from '@/types/TransformLayout'

/**
 *  画像の位置・回転・拡大情報を更新する
 *  @param layout 編集後の画像の位置・回転・拡大情報
 *  @param type 編集画像の種類 写真かテキスト画像か
 *  @param slot 編集画像の番号
 */
export const updateLayout = (
  layout: TransformLayout,
  type: 'photo' | 'message' | 'sender',
  slot?: number
): UpdateLayout => {
  return {
    type: ActionTypes.UpdateLayout,
    payload: {
      layout,
      type,
      slot
    }
  }
}

/** 編集状態を保存 */
export const saveDesignRequested = (): SaveDesignRequested => ({
  type: ActionTypes.SaveDesignRequested
})

/** 編集画像の取得 */
export const fetchPreviewImageRequested = (): FetchPreviewImageRequested => ({
  type: ActionTypes.FetchPreviewImageRequested
})

/** 編集中のフレームを初期化 */
export const initEditingFrame = (slot: number): InitEditingFrame => ({
  type: ActionTypes.InitEditingFrame,
  payload: { slot }
})

/** 編集中のフレームの画像レイアウトを初期化 */
export const initPhotoLayout = (slot: number): InitPhotoLayout => ({
  type: ActionTypes.InitPhotoLayout,
  payload: { slot }
})

/** プレビューレイアウトを初期化 */
export const initPreviewLayout = (layout: Layout): InitPreviewLayout => ({
  type: ActionTypes.InitPreviewLayout,
  payload: { layout }
})

/** 編集中画像の位置・大きさ・回転情報を初期化 */
export const initPlaceLayout = (
  type: AssetType,
  slot?: number
): InitPlaceLayout => ({
  type: ActionTypes.InitPlaceLayout,
  payload: { type, slot }
})

/** 編集中画像の位置・大きさ・回転情報を初期化 */
export const initLayout = (type: AssetType, slot?: number): InitLayout => ({
  type: ActionTypes.InitLayout,
  payload: { type, slot }
})

/** テキストサイズアラートを非表示 */
export const hideTextSizeAlert = (): HideTextSizeAlert => ({
  type: ActionTypes.HideTextSizeAlert
})

/** 補正設定の有無の切替 */
export const toggleEnhancement = (slot: number): ToggleEnhancement => ({
  type: ActionTypes.ToggleEnhancement,
  payload: { slot }
})
