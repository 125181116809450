import { push } from 'connected-react-router'
import { call, put, select, take, takeLatest } from 'redux-saga/effects'

import * as config from '@/store/modules/config'
import * as postcardAddressImages from '@/store/modules/postcardAddressImages'
import * as user from '@/store/modules/user'

import * as error from '@/utils/error'

import { CustomerResponse } from '@kn/common/lib/api/common/types/response/customer'
import {
  deleteTargetPostcardAddress,
  DeleteTargetPostcardAddressParams
} from '@kn/common/lib/api/deleteTargetPostcardAddress'
import {
  fetchPostcardAddresses,
  FetchPostcardAddressesResponse
} from '@kn/common/lib/api/fetchPostcardAddresses'
import {
  getPostcardAddressHeidenConf,
  GetPostcardAddressHeidenConfResponse
} from '@kn/common/lib/api/getPostcardAddressHeidenConf'
import {
  savePostcardAddressHeidenConf,
  SavePostcardAddressHeidenConfResponse
} from '@kn/common/lib/api/savePostcardAddressHeidenConf'
import { signin } from '@kn/common/lib/api/signin'
import {
  updateTargetPostcardAddress,
  UpdateTargetPostcardAddressParams
} from '@kn/common/lib/api/updateTargetPostcardAddress'

import {
  ActionTypes,
  DeleteTargetPostcardAddressRequested,
  DeleteTargetPostcardAddressSucceeded,
  LoadConfigRequested,
  LoadConfigSucceeded,
  LoadEnd,
  LoadPostcardAddressesRequested,
  LoadPostcardAddressesSucceeded,
  LoadSigninFailed,
  LoadSigninRequested,
  SetError,
  SetInitialConfig,
  UpdateConfigRequested,
  UpdateConfigSucceeded,
  UpdateInitialConfig,
  UpdateOnlyTargetPostcardAddressRequested,
  UpdateOnlyTargetPostcardAddressSucceeded,
  UpdateTargetPostcardAddressRequested,
  UpdateTargetPostcardAddressSucceeded
} from './actions'

import Config from '@/types/PostcardAddresses/Config'
import Image from '@/types/PostcardAddresses/Image'
import PostcardAddress from '@/types/PostcardAddresses/PostcardAddress'
import User from '@/types/User'

import { RootState } from '@/store'

import { sendApiError } from '@/utils/sentry'

/**
 * ログインして宛名一覧に遷移
 */
function* login(action: LoadSigninRequested) {
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )
  try {
    const resp: CustomerResponse = yield call(signin, {
      email: action.payload.email,
      password: action.payload.password,
      token
    })

    // ログインしたユーザー情報をuserに登録
    yield put<user.LoadSigninSucceeded>({
      type: user.ActionTypes.LoadSigninSucceeded,
      payload: User.fromRaw(resp)
    })

    // tokenをconfigに登録
    yield put<config.Actions>({
      type: config.ActionTypes.UpdateToken,
      payload: User.fromRaw(resp).token
    })

    // アドレス一覧へ遷移
    yield put(push('/address/list'))
  } catch (e) {
    yield put<LoadSigninFailed>({
      type: ActionTypes.LoadSigninFailed
    })
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * 宛名一覧をAPIから取得
 */
function* loadPostcardAddresses(action: LoadPostcardAddressesRequested) {
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )

  try {
    const resp: FetchPostcardAddressesResponse = yield call(
      fetchPostcardAddresses,
      {
        token
      }
    )

    yield put<LoadPostcardAddressesSucceeded>({
      type: ActionTypes.LoadPostcardAddressesSucceeded,
      payload: resp.postcard_addresses.map(address =>
        PostcardAddress.fromRaw(address)
      )
    })
  } catch (e) {
    // TODO: エラーハンドリング
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * 宛名印刷フラグを更新、リスト取得
 */
function* changeTargetPostcardAddress(
  action: UpdateTargetPostcardAddressRequested
) {
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )

  try {
    const resp: UpdateTargetPostcardAddressParams = yield call(
      updateTargetPostcardAddress,
      {
        postcard_address_ids: action.payload.postcardAddressIds,
        printing_flag: action.payload.printingFlag,
        token
      }
    )

    // TODO: updateTargetPostcardAddressのresがaddressesになったらそちらに変更
    // yield put<UpdateTargetPostcardAddressSucceeded>({
    //   type: ActionTypes.UpdateTargetPostcardAddressSucceeded,
    //   payload: resp.postcard_addresses.map(address =>
    //     PostcardAddress.fromRaw(address)
    //   )
    // })

    const addressesResp: FetchPostcardAddressesResponse = yield call(
      fetchPostcardAddresses,
      {
        token
      }
    )

    yield put<UpdateTargetPostcardAddressSucceeded>({
      type: ActionTypes.UpdateTargetPostcardAddressSucceeded,
      payload: addressesResp.postcard_addresses.map(address =>
        PostcardAddress.fromRaw(address)
      )
    })
  } catch (e) {
    yield put<SetError>({
      type: ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e),
        title: '処理に失敗しました',
        text: 'お手数ですが再操作をお願いします。'
      }
    })
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * 宛名印刷フラグを更新のみ
 */
function* updateOnlyTargetPostcardAddress(
  action: UpdateOnlyTargetPostcardAddressRequested
) {
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )

  try {
    yield call(updateTargetPostcardAddress, {
      postcard_address_ids: action.payload.postcardAddressIds,
      printing_flag: action.payload.printingFlag,
      token
    })

    yield put<UpdateOnlyTargetPostcardAddressSucceeded>({
      type: ActionTypes.UpdateOnlyTargetPostcardAddressSucceeded,
      payload: {
        postcardAddressIds: action.payload.postcardAddressIds,
        printingFlag: action.payload.printingFlag
      }
    })
  } catch (e) {
    yield put<SetError>({
      type: ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e),
        title: '処理に失敗しました',
        text: 'お手数ですが再操作をお願いします。'
      }
    })
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * 宛名削除
 */
function* removeTargetPostcardAddress(
  action: DeleteTargetPostcardAddressRequested
) {
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )
  const { postcardAddressIds } = action.payload

  try {
    const resp: DeleteTargetPostcardAddressParams = yield call(
      deleteTargetPostcardAddress,
      {
        postcard_address_ids: postcardAddressIds,
        token
      }
    )

    // TODO: deleteTargetPostcardAddressのresがaddressesになったらそちらに変更
    // yield put<DeleteTargetPostcardAddressSucceeded>({
    //   type: ActionTypes.DeleteTargetPostcardAddressSucceeded,
    //   payload: resp.postcard_addresses.map(address =>
    //     PostcardAddress.fromRaw(address)
    //   )
    // })

    const addressesResp: FetchPostcardAddressesResponse = yield call(
      fetchPostcardAddresses,
      {
        token
      }
    )

    yield put<DeleteTargetPostcardAddressSucceeded>({
      type: ActionTypes.DeleteTargetPostcardAddressSucceeded,
      payload: addressesResp.postcard_addresses.map(address =>
        PostcardAddress.fromRaw(address)
      )
    })

    // プレビュー画像があれば削除
    const images: Image[] = yield select(
      (state: RootState) => state.postcardAddressImages.images
    )
    yield put<postcardAddressImages.Actions>({
      type: postcardAddressImages.ActionTypes.RemoveImages,
      payload: postcardAddressIds.map(id => {
        const currentImage = images.find(img => img.id === id)
        if (currentImage && currentImage.url) {
          URL.revokeObjectURL(currentImage.url)
        }
        return { id }
      })
    })
  } catch (e) {
    yield put<SetError>({
      type: ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e),
        title: '処理に失敗しました',
        text: 'お手数ですが再操作をお願いします。'
      }
    })
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * 宛名の設定を読み込み、設定がなければ初期設定を登録する
 */
function* updateInitialConfig(action: UpdateInitialConfig) {
  const INITIAL_FONT_ID = '905e7911-f0f9-4889-8c2a-6a42c59c432e'
  const INITIAL_LAYOUT_TYPE = 0
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )

  try {
    const currentConfig: GetPostcardAddressHeidenConfResponse = yield call(
      getPostcardAddressHeidenConf,
      {
        token
      }
    )
    if (!currentConfig.postcard_address_heiden_configuration) {
      const resp: SavePostcardAddressHeidenConfResponse = yield call(
        savePostcardAddressHeidenConf,
        {
          token,
          font_id: INITIAL_FONT_ID,
          layout_type: INITIAL_LAYOUT_TYPE
        }
      )

      yield put<UpdateConfigSucceeded>({
        type: ActionTypes.UpdateConfigSucceeded,
        payload: Config.fromRaw(resp.postcard_address_heiden_configuration)
      })
    }
  } catch (e) {
    // エラーなら初期化
    try {
      const resp: SavePostcardAddressHeidenConfResponse = yield call(
        savePostcardAddressHeidenConf,
        {
          token,
          font_id: INITIAL_FONT_ID,
          layout_type: INITIAL_LAYOUT_TYPE
        }
      )

      yield put<UpdateConfigSucceeded>({
        type: ActionTypes.UpdateConfigSucceeded,
        payload: Config.fromRaw(resp.postcard_address_heiden_configuration)
      })
    } catch (error) {
      // 必要なら更にエラーハンドリング
      sendApiError(e)
    }
    yield put<SetError>({
      type: ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e),
        title: '設定の取得に失敗しました。初期の値を設定します',
        text: ''
      }
    })
    sendApiError(e)
  }
}

/**
 * 宛名の設定をAPIから取得
 */
function* loadConfig(action: LoadConfigRequested) {
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )

  try {
    const resp: GetPostcardAddressHeidenConfResponse = yield call(
      getPostcardAddressHeidenConf,
      {
        token
      }
    )
    yield put<LoadConfigSucceeded>({
      type: ActionTypes.LoadConfigSucceeded,
      payload: Config.fromRaw(resp.postcard_address_heiden_configuration)
    })
  } catch (e) {
    // エラーハンドリング
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * 宛名の設定を更新して宛名一覧に遷移
 */
function* updateConfig(action: UpdateConfigRequested) {
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )

  try {
    const resp: SavePostcardAddressHeidenConfResponse = yield call(
      savePostcardAddressHeidenConf,
      {
        token,
        font_id: action.payload.fontId,
        layout_type: action.payload.layoutType
      }
    )

    yield put<UpdateConfigSucceeded>({
      type: ActionTypes.UpdateConfigSucceeded,
      payload: Config.fromRaw(resp.postcard_address_heiden_configuration)
    })

    // プレビュー画像を初期化
    const images: Image[] = yield select(
      (state: RootState) => state.postcardAddressImages.images
    )
    yield put<postcardAddressImages.Actions>({
      type: postcardAddressImages.ActionTypes.RemoveImages,
      payload: images.map(image => {
        if (image.url) {
          URL.revokeObjectURL(image.url)
        }
        return { id: image.id }
      })
    })
    // アドレス一覧へ遷移
    yield put(push('/address/list'))
  } catch (e) {
    yield put<SetError>({
      type: ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e),
        title: '処理に失敗しました',
        text: 'お手数ですが再操作をお願いします。'
      }
    })
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * 宛名の初期設定を登録する
 */
function* setInitialConfig(action: SetInitialConfig) {
  const INITIAL_FONT_ID = '905e7911-f0f9-4889-8c2a-6a42c59c432e'
  const INITIAL_LAYOUT_TYPE = 0
  const { token }: RootState['config'] = yield select(
    (state: RootState) => state.config
  )

  try {
    const resp: SavePostcardAddressHeidenConfResponse = yield call(
      savePostcardAddressHeidenConf,
      {
        token,
        font_id: INITIAL_FONT_ID,
        layout_type: INITIAL_LAYOUT_TYPE
      }
    )

    yield put<UpdateConfigSucceeded>({
      type: ActionTypes.UpdateConfigSucceeded,
      payload: Config.fromRaw(resp.postcard_address_heiden_configuration)
    })
  } catch (e) {
    yield put<SetError>({
      type: ActionTypes.SetError,
      payload: {
        status: error.getErrorStatus(e),
        errorCode: error.getErrorCode(e),
        title: '宛名設定を初期化できませんでした。再試行します。',
        text: ''
      }
    })
    sendApiError(e)
  } finally {
    yield put<LoadEnd>({
      type: ActionTypes.LoadEnd
    })
  }
}

/**
 * 宛名一覧ストア上の非同期処理を行うアクションを監視する
 */
export function* sagas() {
  yield takeLatest(ActionTypes.LoadSigninRequested, login)
  yield takeLatest(
    ActionTypes.LoadPostcardAddressesRequested,
    loadPostcardAddresses
  )
  yield takeLatest(
    ActionTypes.UpdateTargetPostcardAddressRequested,
    changeTargetPostcardAddress
  )
  yield takeLatest(
    ActionTypes.UpdateOnlyTargetPostcardAddressRequested,
    updateOnlyTargetPostcardAddress
  )
  yield takeLatest(
    ActionTypes.DeleteTargetPostcardAddressRequested,
    removeTargetPostcardAddress
  )
  yield takeLatest(ActionTypes.LoadConfigRequested, loadConfig)
  yield takeLatest(ActionTypes.UpdateConfigRequested, updateConfig)
  yield takeLatest(ActionTypes.UpdateInitialConfig, updateInitialConfig)
  yield takeLatest(ActionTypes.SetInitialConfig, setInitialConfig)
}
