import * as React from 'react'

import styled from 'styled-components'

interface Props {
  /** クラス名 */
  className?: string
  /** 左ボタン */
  leftButton?: React.ReactElement
  /** 真ん中ボタン */
  centerButton?: React.ReactElement
  /** 右ボタン */
  rightButton?: React.ReactElement
  /** ボタンの配置型 */
  arrangementType?: 'center' | 'between'
}

export const ModalFooter: React.SFC<Props> = ({
  className,
  leftButton,
  centerButton,
  rightButton,
  arrangementType = 'between'
}) => {
  return (
    <Container className={className}>
      <LeftButtonWrap type={arrangementType}>{leftButton}</LeftButtonWrap>
      <div>{centerButton}</div>
      <RightButtonWrap type={arrangementType}>{rightButton}</RightButtonWrap>
    </Container>
  )
}

export default ModalFooter

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 35px;
  height: 150px;
  background-color: #999999;
`

interface ButtonProps {
  type: 'between' | 'center'
}
const StyledBase = styled.div`
  position: ${(p: ButtonProps) =>
    p.type === 'between' ? 'absolute' : 'static'};
  margin: ${(p: ButtonProps) => (p.type === 'between' ? 0 : '0 20px')};
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LeftButtonWrap = styled(StyledBase)`
  left: 35px;
`

const RightButtonWrap = styled(StyledBase)`
  right: 35px;
`
