import { Action } from 'redux'

import { ApiError } from '@/types/Error/ApiError'
import Filters from '@/types/Templates/Filters'
import Template from '@/types/Templates/Template'

export enum ActionTypes {
  UpdateFilter = 'templates/UPDATE_FILTER',
  LoadTemplateListRequested = 'templates/LOAD_TEMPLATE_LIST_REQUESTED',
  LoadTemplateListSucceeded = 'templates/LOAD_TEMPLATE_LIST_SUCCEEDED',
  LoadTemplateListFailed = 'templates/LOAD_TEMPLATE_LIST_FAILED',
  LoadEnd = 'templates/LOAD_END',
  AddPickedTemplate = 'templates/ADD_PICKED_TEMPLATE',
  RemovePickedTemplate = 'templates/REMOVE_PICKED_TEMPLATE'
}

export type Actions =
  | UpdateFilter
  | LoadEnd
  | LoadTemplateListRequested
  | LoadTemplateListSucceeded
  | LoadTemplateListFailed
  | AddPickedTemplate
  | RemovePickedTemplate

/**
 * テンプレートフィルターの更新
 */
export interface UpdateFilter extends Action {
  type: ActionTypes.UpdateFilter
  payload: Filters
}

/**
 * テンプレート一覧を読み込む
 */
export interface LoadTemplateListRequested extends Action {
  type: ActionTypes.LoadTemplateListRequested
  payload: Filters
}
export interface LoadTemplateListSucceeded extends Action {
  type: ActionTypes.LoadTemplateListSucceeded
  payload: Template[]
}
export interface LoadTemplateListFailed extends Action {
  type: ActionTypes.LoadTemplateListFailed
  payload: ApiError
}

export interface LoadEnd extends Action {
  type: ActionTypes.LoadEnd
}

/**
 * お気に入りリストにテンプレートを追加する
 */
export interface AddPickedTemplate extends Action {
  type: ActionTypes.AddPickedTemplate
  payload: Template
}

/**
 * お気に入りリストからテンプレートを削除する
 */
export interface RemovePickedTemplate extends Action {
  type: ActionTypes.RemovePickedTemplate
  payload: Template
}
