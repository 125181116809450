import { CreateDesignEditInitializationResponse } from '@kn/common/lib/api/createDesignEditInitialization'

/**
 * アプリケーションがAPIに通信する際に使用する編集設定
 */
export interface EditingConfig {
  /** 会員トークン */
  token: string
  /** はがき編集データID */
  postcardEditingRecordId: string
  /** 編集開始日 */
  editStartDate: string
}

/**
 * APIレスポンスの初期編集データ作成APIの情報をフロント側で使う構造に変換する
 * @param raw APIレスポンスの初期編集データ
 */
const EditingConfig = {
  fromRaw(raw: RawEditingConfig): EditingConfig {
    return {
      token: raw.token,
      postcardEditingRecordId: raw.postcard_editing_record_id,
      editStartDate: raw.edit_start_date
    }
  }
}
export default EditingConfig

type RawEditingConfig = CreateDesignEditInitializationResponse['design']
