import React, { SFC } from 'react'
import styled from 'styled-components'

import RoundButton from '@/components/Buttons/RoundButton'
import CloseIcon from '@/components/Icons/CloseIcon'

interface Props {
  /** クラス名 */
  className?: string
  /** クリックイベント */
  onClick?(ev: React.MouseEvent<HTMLElement>): any
}

export const CloseButton: SFC<Props> = ({ className, onClick = () => 0 }) => (
  <RoundButton
    className={className}
    icon={<StyledCloseIcon />}
    onClick={onClick}
  />
)

export default CloseButton

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 24px;
`
