import { connect, MapStateToProps, Omit } from 'react-redux'

import { RootState } from '@/store'

import Loading, { Props } from '@/components/Loading'

type StateProps = Pick<Props, 'visible'>
type OwnProps = Omit<Props, keyof StateProps>

/**
 * ユーザーの動きを制限するための全画面ローディングを走らせる
 */
const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = ({
  sender,
  messages,
  templateDetail,
  order,
  user,
  postcardAddressEdit,
  templateCategories,
  templates,
  config
}) => {
  return {
    visible:
      templateDetail.isLoading ||
      messages.isLoading ||
      sender.isLoading ||
      order.isLoading ||
      user.isLoading ||
      postcardAddressEdit.isLoading ||
      templateCategories.isLoading ||
      templates.isLoading ||
      config.isLoading
  }
}

export default connect(mapStateToProps)(Loading)
