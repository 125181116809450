import { OrderDetailResponse } from '@kn/common/lib/api/common/types/response/orderDetail'
import { EstimateSortNumber } from './EstimateSortNumber'

interface Estimate {
  /** はがき仕上げ種別 */
  postcardFinishingType: string
  /** はがき種別 */
  postcardType: string
  /** 宛名枚数 */
  addressPrintingCount: string
  /** 印刷枚数 */
  printCount: string
  /** 印刷代 */
  printPrice: string
  /** 持ち込み枚数 */
  carryInCount: string
  /** 持ち込みはがき代 */
  carryInPostcardPrice: string
  /** 注文合計金額 */
  totalPrice: string
  /** 金額：宛名印刷 */
  addressPrintingPrice: string
  /** 金額：年賀はがき */
  postcardPrice: string
  /** 追加印刷代 */
  addPrintingPrice: string
}

const Estimate = {
  fromRaw(raw: RawEstimateParam): Estimate {
    const fetchParam = (targetId: number): string => {
      const target = raw.find(t => t.order_price_item === targetId)

      return target ? target.display_value || '' : ''
    }
    return {
      postcardFinishingType: fetchParam(
        EstimateSortNumber.postcardFinishingType
      ),
      postcardType: fetchParam(EstimateSortNumber.postcardType),
      addressPrintingCount: fetchParam(EstimateSortNumber.addressPrintingCount),
      printCount: fetchParam(EstimateSortNumber.printCount),
      carryInCount: fetchParam(EstimateSortNumber.carryInCount),
      carryInPostcardPrice: fetchParam(EstimateSortNumber.carryInPostcardPrice),
      totalPrice: fetchParam(EstimateSortNumber.totalPrice),
      printPrice: fetchParam(EstimateSortNumber.printPrice),
      addressPrintingPrice: fetchParam(EstimateSortNumber.addressPrintingPrice),
      postcardPrice: fetchParam(EstimateSortNumber.postcardPrice),
      addPrintingPrice: fetchParam(EstimateSortNumber.addPrintingPrice)
    }
  }
}

type RawEstimateParam = ReadonlyArray<OrderDetailResponse>

export default Estimate
