import React, { FC } from 'react'

interface Props {
  className?: string
}

export const AttentionIcon: FC<Props> = ({ className }) => (
  <svg
    className={className}
    height="1em"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="36"
      height="36"
      rx="18"
      stroke="currentColor"
      fill="transparent"
      strokeWidth="2"
    />
    <path
      d="M19.1765 33.7599C20.8644 33.7599 22.2328 32.3747 22.2328 30.6659C22.2328 28.957 20.8644 27.5718 19.1765 27.5718C17.4885 27.5718 16.1201 28.957 16.1201 30.6659C16.1201 32.3747 17.4885 33.7599 19.1765 33.7599Z"
      fill="currentColor"
    />
    <path
      d="M21.7564 22.5635C21.7564 24.003 20.6057 25.1679 19.1838 25.1679C17.7619 25.1679 16.6112 24.003 16.6112 22.5635L16.1201 7.69417C16.1201 5.98761 17.4907 4.6001 19.1765 4.6001C20.8622 4.6001 22.2328 5.98761 22.2328 7.69417L21.7564 22.5635Z"
      fill="currentColor"
    />
  </svg>
)

export default AttentionIcon
