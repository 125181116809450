import {
  ActionTypes,
  AddPickedTemplate,
  LoadTemplateListRequested,
  RemovePickedTemplate,
  UpdateFilter
} from './actions'

import Filters from '@/types/Templates/Filters'
import Template from '@/types/Templates/Template'

/**
 * テンプレート一覧を読み込む
 */
export const fetchTemplateListRequested = (
  filters: Filters
): LoadTemplateListRequested => ({
  type: ActionTypes.LoadTemplateListRequested,
  payload: filters
})

/**
 * フィルタを更新する
 */
export const updateFilter = (
  filters: UpdateFilter['payload']
): UpdateFilter => ({
  type: ActionTypes.UpdateFilter,
  payload: filters
})

/**
 * お気に入りリストにテンプレートを追加する
 */
export const addPickedTemplate = (template: Template): AddPickedTemplate => ({
  type: ActionTypes.AddPickedTemplate,
  payload: template
})

/**
 * お気に入りリストからテンプレートを削除する
 */
export const removePickedTemplate = (
  template: Template
): RemovePickedTemplate => ({
  type: ActionTypes.RemovePickedTemplate,
  payload: template
})
