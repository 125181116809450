import { ActionTypes, LoadPostcardTypesRequested } from './actions'

/**
 * ハガキ種別ー一覧を読み込む
 */
export const loadPostcardTypesRequested = (
  productType: string
): LoadPostcardTypesRequested => ({
  type: ActionTypes.LoadPostcardTypesRequested,
  payload: productType
})
