import { ApiError } from '@/types/Error/ApiError'

import User from '@/types/User'

/**
 * ログインユーザー情報を管理するステート
 */
export interface State {
  data: User
  isLoading: boolean
  error: ApiError | null
}

export const initialState: State = {
  isLoading: false,
  data: {
    id: '',
    memberId: '',
    isMemberFlag: false,
    email: '',
    lastName: '',
    firstName: '',
    lastNameRead: '',
    firstNameRead: '',
    postcode: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    address5: '',
    phoneNumber: '',
    token: '',
    tokenExpirationAt: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: ''
  },
  error: null
}
