import { PhotoResponse } from '@kn/common/lib/api/common/types/response/photo'
/**
 * 写真枠情報
 */
interface Photo {
  /** 写真マスク画像タイプ */
  maskImageType: string
  /** 写真枠X座標 */
  frameX: number
  /** 写真枠Y座標 */
  frameY: number
  /** 写真枠横幅 */
  frameWidth: number
  /** 写真枠縦幅 */
  frameHeight: number
  /** 写真ファイルID */
  imageId: string
  /** 写真補正フラグ */
  enhancementFlag: boolean
}

/**
 * APIレスポンスのテンプレート詳細の写真枠情報をフロント側で使う構造に変換する
 * @param raw APIレスポンスのテンプレート詳細の写真枠情報
 */
const Photo = {
  fromRaw(raw: RawPhoto): Photo {
    return {
      imageId: '',
      enhancementFlag: true,
      maskImageType: raw.mask_image_type,
      frameX: raw.frame_x,
      frameY: raw.frame_y,
      frameWidth: raw.frame_width,
      frameHeight: raw.frame_height
    }
  }
}
export default Photo

type RawPhoto = PhotoResponse
