import { ApiError } from '@/types/Error/ApiError'
import Filters from '@/types/Templates/Filters'
import Template from '@/types/Templates/Template'

/**
 * テンプレートの検索条件・検索結果を管理するステート
 */
export interface State {
  /** 検索フィルター */
  filters: Filters
  /** ローディング中かどうか */
  isLoading: boolean
  /** テンプレートリスト */
  templateList: Template[]
  /** お気に入りテンプレートリスト */
  pickedTemplateList: Template[]
  /** エラー */
  error: ApiError | null
}

export const initialState: State = {
  filters: {},
  isLoading: false,
  templateList: [],
  pickedTemplateList: [],
  error: null
}
