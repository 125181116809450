import { Action } from 'redux'

import { FontStyle } from '@/types/FontStyle'
import { Alignment } from '@/types/Message/Alignment'

import TemplateDetail from '@/types/TemplateDetail'
import Text from '@/types/TemplateDetail/Text'
import { State } from './state'

export enum ActionTypes {
  Clear = 'message/CLEAR',
  ClearRequest = 'message/CLEAR_REQUEST',
  ClearError = 'message/CLEAR_ERROR',
  Set = 'message/SET',
  SetDirection = 'message/SET_DIRECTION',
  SetAlignment = 'message/SET_ALIGNMENT',
  PatchStyle = 'message/PATCH_STYLE',
  SetImage = 'message/SET_IMAGE',
  FetchImageSucceeded = 'message/FETCH_IMAGE_SUCCEEDED',
  FetchImageFailed = 'message/FETCH_IMAGE_FAILED',
  SetEditingMode = 'message/SET_EDITING_MODE',
  LoadStart = 'message/LOAD_START',
  LoadEnd = 'message/LOAD_END',
  SetError = 'message/SET_ERROR',
  SetFont = 'message/SET_FONT',
  FetchImage = 'message/FETCH_IMAGE'
}

export type Actions =
  | Clear
  | ClearRequest
  | ClearError
  | Set
  | SetDirection
  | SetAlignment
  | PatchStyle
  | SetImage
  | SetEditingMode
  | FetchImageFailed
  | FetchImage
  | SetError
  | SetFont
  | LoadStart
  | LoadEnd

export interface Clear extends Action {
  type: ActionTypes.Clear
  payload: { message: Text }
}

export interface ClearRequest extends Action {
  type: ActionTypes.ClearRequest
  payload: { slot: number }
}

export interface ClearError extends Action {
  type: ActionTypes.ClearError
  payload: { slot: number }
}

export interface Set extends Action {
  type: ActionTypes.Set
  payload: { text: string; slot: number }
}

export interface SetDirection extends Action {
  type: ActionTypes.SetDirection
  payload: { direction: string; slot: number }
}

export interface SetAlignment extends Action {
  type: ActionTypes.SetAlignment
  payload: { alignment: Alignment; slot: number }
}

export interface PatchStyle extends Action {
  type: ActionTypes.PatchStyle
  payload: { style: Partial<FontStyle>; slot: number }
}

export interface SetFont extends Action {
  type: ActionTypes.SetFont
  payload: { fontId: string }
}

export interface SetImage extends Action {
  type: ActionTypes.SetImage
  payload: {
    path: string
    size: {
      width: number
      height: number
    }
    slot: number
  }
}

export interface SetEditingMode extends Action {
  type: ActionTypes.SetEditingMode
  payload: number
}

export interface LoadStart extends Action {
  type: ActionTypes.LoadStart
}

export interface LoadEnd extends Action {
  type: ActionTypes.LoadEnd
}

export interface FetchImageFailed extends Action {
  type: ActionTypes.FetchImageFailed
}

export interface FetchImage extends Action {
  type: ActionTypes.FetchImage
  payload: { slot: number }
}

export interface SetError extends Action {
  type: ActionTypes.SetError
  payload: {
    status: number | string
    errorCode: string | null
    slot: number
  }
}
