import { savePhoto } from '@kn/common/lib/api/savePhoto'

import Photo from '@/types/Photo'
import { KNengsaNative } from './types'

declare global {
  interface Window {
    callBackFunctionForMedia(): any
  }
  interface External {
    /**
     * 光ディスク＆メモリカード画像読み取り機能を呼び出す
     * 読み込まれた画像はアップロードされた後SEND_MEDIA_IMAGE_CALLBACK_FUNCTIONに
     * 指定しているcallBackFunctionForMediaが実行される
     * @param productTypeId 商材種別ID
     * @param editStartedAt 編集開始日
     */
    ReadImage(productTypeId: string, editStartedAt: string): any
    /**
     * 光ディスク＆メモリカード読み込み(GetImageIdsString)の結果を取得する
     * @return 読み込み結果
     */
    GetImageIdsString(): any
  }
}

/**
 * 光ディスク＆メモリカード画像読み取り後のcallbackをセットする
 */
export const setCallback = (callback: () => any) => {
  window.callBackFunctionForMedia = callback
}

/**
 * 光ディスク＆メモリカード画像読み取り機能を呼び出す
 */
export const readImage = (
  productTypeId: string,
  editStartedAt: string
): void => {
  window.external.ReadImage(productTypeId, editStartedAt)
}

/**
 * 光ディスク＆メモリカードから読み取んだ画像を取得する
 */
export const getPhotos = () => {
  const rawPhotos = JSON.parse(window.external.GetImageIdsString()).photos
  return rawPhotos.map((photo: any) => ({
    ...photo,
    id: photo.Id
  }))
}

/**
 * モック用file読み込み
 */
const readFile = () => {
  return new Promise<File>(resolve => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'image/*'
    input.onchange = e => {
      if (input.files && input.files.length) {
        resolve(input.files[0])
      }
    }
    input.click()
  })
}

/**
 * モック用fileをapiにpostしてcallbackを実行
 */
const readImageMock = async (productTypeId: string, editStartedAt: string) => {
  await alert(`
  　光ディスク＆メモリカード画像読み込み機能のモック\n
    productTypeId: ${productTypeId}\n
    editStartedAt: ${editStartedAt}
  `)
  const file: File = await readFile()
  const blob = URL.createObjectURL(file)
  if (file) {
    const resp = await savePhoto({
      file,
      product_type_id: productTypeId,
      edit_started_at: editStartedAt
    })
    try {
      window.external.GetImageIdsString = () => ({
        photos: resp.photo ? [resp.photo] : []
      })
    } catch (e) {
      // IEではモックを使用しない
    }
    window.k_nenga_native.callBackFunctionForMedia()
  }
}

/**
 * 光ディスク＆メモリカード画像読み取り機能の簡易モックを表示する
 */
export const mock = () => {
  if (!window.external) {
    ;(window.external as any) = {}
  }
  try {
    if (!window.external.ReadImage) {
      window.external.ReadImage = readImageMock
    }
    if (!window.external.GetImageIdsString) {
      window.external.GetImageIdsString = () => ({
        photos: []
      })
    }
  } catch (e) {
    // IE環境ではwindow.externalに代入ができないが、
    // 店頭端末等があるためモックは使用しない
  }
}
