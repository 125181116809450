import { CreateSenderHeidensParams } from '@kn/common/lib/api/createSenderHeidens'

import { RootState } from '@/store'

import { Contact, Layout, Name, Sender } from '@/types/Sender'

import { getSenderLayoutType } from '@/utils/getSenderLayoutType'
import { initialSenderParams } from './initialSenderParams'

export const getSenderHeidenParams = ({
  sender,
  config,
  constants
}: RootState): CreateSenderHeidensParams => {
  const mapContactsToParams = (
    contacts: Contact[],
    type: 'telephone' | 'mail_url',
    index: number
  ): Partial<CreateSenderHeidensParams['data']> => {
    return contacts.reduce<any>(
      (ret, contact, i) => ({
        ...ret,
        [`${type}_prefix${i + 1}_${index}`]:
          sender.constants[
            type === 'telephone' ? 'locationalIdentities' : 'personalIdentities'
          ]
            .filter(c => c.label === contact.label)
            .map(c => c.id)[0] || null,
        [`${type}${i + 1}_${index}`]: contact.value
      }),
      {}
    )
  }

  const mapNameToParams = (
    name: Name,
    type: Sender['type'],
    index: number
  ): Partial<CreateSenderHeidensParams> => ({
    [`position_${index}`]: type === 'company' ? name.jobPosition || null : null,
    [`last_name_${index}`]: name.familyName,
    [`first_name_${index}`]: name.givenName,
    [`last_name_read_${index}`]:
      type === 'private' ? name.familyNameKana || null : null,
    [`first_name_read_${index}`]:
      type === 'private' ? name.givenNameKana || null : null,
    [`name_parentheses_${index}`]: name.attachment
      ? name.attachmentType === 'suffixAge'
        ? name.attachment
        : `旧姓 ${name.attachment}`
      : null
  })

  const mapNamesToParams = (
    names: Name[],
    type: Sender['type'],
    indexStartAt: number
  ): Partial<CreateSenderHeidensParams> => {
    return names.reduce<any>(
      (ret, name, i) => ({
        ...ret,
        ...mapNameToParams(name, type, i + indexStartAt)
      }),
      {}
    )
  }

  return {
    token: config.token,
    edit_started_at: config.editStartedAt,
    postcard_editing_record_id: config.postcardEditingRecordId,
    product_type_id: config.productTypeId,
    data: {
      // 共通部分
      ...initialSenderParams,
      postcode_1: sender.data.address.zipCode,
      ...mapContactsToParams(sender.data.address.contacts, 'telephone', 1),
      ...mapContactsToParams(sender.data.contacts, 'mail_url', 1),
      ...mapNameToParams(sender.data.name, sender.data.type, 1),
      ...mapNamesToParams(sender.data.jointNames, sender.data.type, 2),
      // 個人のみ部分
      ...(sender.data.type === 'private'
        ? {
            address1_1: sender.data.address.prefecture || null,
            address2_1: sender.data.address.line1 || null,
            address3_1: sender.data.address.line2 || null,
            address4_1: sender.data.address.line3 || null
          }
        : {}),
      // 法人のみ部分
      ...(sender.data.type === 'company'
        ? {
            address1_1: sender.data.address.prefecture || null,
            address2_1: sender.data.address.line1 || null,
            address3_1: sender.data.address.line2 || null,
            address4_1: sender.data.address.line3 || null,
            company: sender.data.companyName || null,
            company_type: '',
            department: sender.data.dept || null,
            position_1: sender.data.name.jobPosition || null
          }
        : {})
    },
    layout_type: getSenderLayoutType(
      sender.constants.layouts[sender.data.type],
      sender.layout
    ),
    color_code:
      constants.fontColors
        .filter(c => c.color === sender.style.color)
        .map(c => c.id)[0] || 0,
    border_flag: sender.style.hasBorder,
    font_id: sender.style.fontId
  }
}
