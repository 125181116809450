import { Action } from 'redux'

export enum ActionTypes {
  UpdateImageRequested = 'postcardAddressImages/UPDATE_IMAGE_REQUESTED',
  UpdateImageSucceeded = 'postcardAddressImages/UPDATE_IMAGE_SUCCEEDED',
  UpdateImageFailed = 'postcardAddressImages/UPDATE_IMAGE_FAILED',
  RemoveImages = 'postcardAddressImages/REMOVE_IMAGES'
}

export type Actions =
  | UpdateImageRequested
  | UpdateImageSucceeded
  | RemoveImages
  | UpdateImageFailed

/**
 * 宛名のプレビュー画像をセットする
 */
export interface UpdateImageRequested extends Action {
  type: ActionTypes.UpdateImageRequested
  payload: {
    id: string
  }
}
export interface UpdateImageSucceeded extends Action {
  type: ActionTypes.UpdateImageSucceeded
  payload: {
    id: string
    url: string
  }
}
export interface UpdateImageFailed extends Action {
  type: ActionTypes.UpdateImageFailed
  payload: {
    id: string
  }
}

export interface RemoveImages extends Action {
  type: ActionTypes.RemoveImages
  payload: Array<{
    id: string
  }>
}
